const calcAge = (birthday) => {

let age =  '';
if ((birthday === undefined) || (birthday === '')){
    return age;
};

let day = birthday.slice(0, 2);
let month = birthday.substr(3, 2);
const year = birthday.substr(6, 4);

const checkBirthday = month + '/'+ day + '/' + year;

const millis = Date.now() - Date.parse(checkBirthday);
age =  new Date(millis).getFullYear() - 1970;

    return age;
}

export default calcAge;
