function dailyStats (ID, BlockName, CompanyName, status, TotDeclarations, TempAbove, Contact,
    Positive, Fever, Chills, Cough, Throat, 
    Breathing, Tiredness, Taste, Eyes, Diarrhoea, BodyAche, Nausea, Dizziness, Headache){
    this.ID = ID;
    this.BlockName = BlockName;
    this.CompanyName = CompanyName;
    this.status = status;
    this.TotDeclarations = TotDeclarations;
    this.TempAbove = TempAbove;
    this.Contact = Contact;
    this.Positive = Positive;
    this.Fever = Fever;
    this.Chills = Chills;
    this.Cough = Cough;
    this.Throat = Throat;
    this.Breathing = Breathing;
    this.Tiredness = Tiredness;
    this.Taste = Taste;
    this.Eyes = Eyes;
    this.Diarrhoea = Diarrhoea;
    this.BodyAche = BodyAche;
    this.Nausea = Nausea;
    this.Dizziness = Dizziness;
    this.Headache = Headache;
  }

  export default dailyStats;