export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const CHANGED_PASSWORD = 'CHANGED_PASSWORD';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const ACCOUNT_INFO = 'ACCOUNT_INFO';


let storeEmail = '';
let storePassword = '';
let logoutStatus = false;

export const resetAuthLoading = () => {
    return async (dispatch) => {
        dispatch({ type: 'LOADING_USER_RESET' });
        dispatch({ type: 'LOADING_SURVEY_RESET' });
        dispatch({ type: 'LOADING_AUTH_RESET' });
    };
};

export const authLoading = () => {
    return {
        type: AUTH_LOADING
    };
};

export const authSuccess = (token, isSignup, userId, displayName) => {
    return {
        type: AUTH_SUCCESS,
        idToken: token,
        loggedIn: !isSignup,
        userId: userId,
        displayName: displayName
    };
};

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error: error
    };
};

export const logoutUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('displayName');
    logoutStatus = true;
    return {
        type: AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            if (logoutStatus) {
                dispatch(logoutUser());
            }else{
                dispatch(auth(storeEmail, storePassword, false));}
        }, expirationTime * 1000);
    };
};

export const auth = (email, password, isSignup) => {
    return async (dispatch) => {
        dispatch(authLoading());
        const apiKey = "AIzaSyBlgRfRP7L_jG6p1omFWlZqVOhK-UOhHDk";
        let url = "https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=" + apiKey;
        if (!isSignup) {
            url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=' + apiKey;
        }
        try {
            let response = await fetch(
                url, {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                    password: password,
                    returnSecureToken: true
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                const errorResData = await response.json();
                const errorId = errorResData.error.message;
                let message = errorId;
                if (errorId === 'EMAIL_NOT_FOUND') {
                    message = 'This email could not be found!';
                } else if (errorId === 'INVALID_PASSWORD') {
                    message = 'This password is not valid!';
                }
                throw new Error(message);
            }

            const resData = await response.json();
            const expirationDate = new Date(new Date().getTime() + resData.expiresIn * 1000);
            localStorage.setItem('token', resData.idToken);
            localStorage.setItem('refreshToken', resData.refreshToken);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('userId', resData.localId);
            localStorage.setItem('displayName', resData.displayName);
            storeEmail = email;
            storePassword = password;
            logoutStatus = false;
            dispatch(authSuccess(resData.idToken, isSignup, resData.localId, resData.displayName));
            dispatch(checkAuthTimeout(resData.expiresIn));

        } catch (err) {
            dispatch(authFail(err + "\n Authentication failed!"));
        }
    };
};

export const forgotPassword = (email) => {
    return async (dispatch) => {
        dispatch(authLoading());
        const apiKey = "AIzaSyBlgRfRP7L_jG6p1omFWlZqVOhK-UOhHDk";
        let url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=` + apiKey;

        try {
            let response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    requestType: "PASSWORD_RESET",
                    email: email
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            response.json();

        } catch (err) {
            dispatch(authFail(err + "\n failed to send email!"));
        }
    };
};

export const verifyEmail = (idToken) => {

    console.log('in verifyEmail- idToken = ', idToken)
    if (idToken === null) {
        return;
    };
    return async (dispatch) => {
        dispatch(authLoading());
        const apiKey = "AIzaSyBlgRfRP7L_jG6p1omFWlZqVOhK-UOhHDk";
        let url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=` + apiKey;
        
        try {
            let response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    requestType: "VERIFY_EMAIL",
                    idToken: idToken
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            let resData = await response.json();
            if (storeEmail !== resData.email) {
                console.log('resData.email = ',resData.email)
                console.log('resData.error = ',resData.error.errors[0].message)
            }

        } catch (err) {
            dispatch(authFail(err + "\n failed to send email to verify email address!"));
        }
    };
};

export const getNewToken = (refreshToken) => {
    return async (dispatch) => {
        dispatch(authLoading());
        const apiKey = "AIzaSyBlgRfRP7L_jG6p1omFWlZqVOhK-UOhHDk";
        // let url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/token?key=` + apiKey;
        let url = `https://cors-anywhere.herokuapp.com/https://www.googleapis.com/identitytoolkit/v3/relyingparty/token?key=` + apiKey;

        try {
            let response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    grant_type: "refresh_token",
                    refresh_token: refreshToken,
                    email: storeEmail,
                    password: storePassword,
                    returnSecureToken: true
                }),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                    // "Content-Type": "application/json"
                }
            })

            const resData = await response.json();
            const expirationDate = new Date(new Date().getTime() + resData.expiresIn * 1000);
            localStorage.setItem('token', resData.idToken);
            localStorage.setItem('refreshToken', resData.refreshToken);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('userId', resData.localId);
            localStorage.setItem('displayName', resData.displayName);
            dispatch(authSuccess(resData.idToken, resData.refreshToken, resData.localId, resData.displayName));
            dispatch((resData.expiresIn, resData.refreshToken));

        } catch (err) {
            dispatch(authFail(err + "\n failed to send email!"));
        }
    };
};

export const setDisplayName = (token, name) => {
    return async (dispatch) => {
        dispatch(authLoading());
        const apiKey = "AIzaSyBlgRfRP7L_jG6p1omFWlZqVOhK-UOhHDk";
        let url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=` + apiKey;

        try {
            let response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    idToken: token,
                    displayName: name
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const resData = await response.json();
            let verified = false;
            const data = {...resData}
            if (data.emailVerified) {
                verified = data.emailVerified;
            } else {
                const data = {...resData.users}
                verified = data[0].emailVerified;
            };

            dispatch({
                type: ACCOUNT_INFO,
                emailVerified: verified
            });

        } catch (err) {
            dispatch(authFail(err + "\n failed to update display name!"));
        }
    };
};

export const getAccountInfo = (token) => {
    return async (dispatch) => {
        dispatch(authLoading());
        const apiKey = "AIzaSyBlgRfRP7L_jG6p1omFWlZqVOhK-UOhHDk";
        let url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=` + apiKey;

        try {
            let response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    idToken: token,
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const resData = await response.json();
            const data = {...resData.users};

            dispatch({
                type: ACCOUNT_INFO,
                emailVerified: data[0].emailVerified
            });

        } catch (err) {
            dispatch(authFail(err + "\n failed to get account info!!"));
        }
    };
};

export const changePassword = (token, password) => {
    return async (dispatch) => {
        dispatch(authLoading());
        //************************************************************************** */
        //***********************       not working      *************************** */
        //************************************************************************** */
        const apiKey = "AIzaSyBlgRfRP7L_jG6p1omFWlZqVOhK-UOhHDk";
        let url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=` + apiKey;

        try {
            let response = await fetch(
                url, {
                method: "POST",
                body: JSON.stringify({
                    email: token,
                    password: password,
                    returnSecureToken: true
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const resData = await response.json();
            localStorage.setItem('token', resData.idToken);
            localStorage.setItem('refreshToken', resData.refreshToken);
            localStorage.setItem('userId', resData.localId);
            dispatch(authSuccess(resData.idToken, resData.refreshToken, resData.localId, resData.displayName));
            dispatch({ type: CHANGED_PASSWORD });

        } catch (err) {
            dispatch(authFail(err + "\n failed to update password!"));
        }
    };
};

export const setAuthRedirectPath = (path) => {

    console.log('***** in auth redirect path *************')
    return {
        type: SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {

        console.log('***** in auth check state *************')
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        if (!token) {
            dispatch(logoutUser());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logoutUser());
            } else {
                const userId = localStorage.getItem('userId');
                const displayName = localStorage.getItem('displayName');
                dispatch(authSuccess(token, refreshToken, userId, displayName));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000), refreshToken);
            }
        }
    };
};

