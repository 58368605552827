import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import Spinner from '../../components/UI/Spinner';
import '../Form.css';
import Input from '../../components/UI/Input';
import {createUser } from '../../dataStore/UserActions';
import {clearCurrentUser}  from '../../dataStore/UserActions';
import BodyTextBackground from '../UI/BodyTextBackground';
class CreateUser extends Component {
    state = {
        coNum: 0,
        signUpForm: {
            firstName: {
                label: 'First Name/s: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'First name/s'
                },
                value: '',
                validation: {
                    required: true,
                    firstChar: true
                },
                valid: false,
                touched: false
            },
            surname: {
                label: 'Surname: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Last name (surname)'
                },
                value: '',
                validation: {
                    required: true,
                    firstChar: true
                },
                valid: false,
                touched: false
            },
            //sadly radio button not working with map!!
            // gender: {
            //     elementType: 'radio',
            //     elementConfig: {
            //         name: 'gender',
            //         options: [
            //             {value: 'M', displayValue: 'Male'},
            //             {value: 'F', displayValue: 'Female'},
            //         ]
            //     },
            //     value: '',
            //     validation: {
            //         required: true
            //     },
            //     valid: false,
            //     touched: false
            // }, 
            email: {
                label: 'email address:',
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'email address'
                },
                value: '',
                validation: {
                    isEmail: true,
                    lowerCase: true,
                },
                valid: true,
                touched: false
            },
            cellNo: {
                label: 'Mobile number:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Mobile number - 10 digits'
                },
                value: '',
                validation: {
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            DOB: {
                label: 'Date of Birth - DD/MM/CCYY: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Date of Birth - DD/MM/CCYY'
                },
                value: '',
                validation: {
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            idNo: {
                label: 'ID number/Passport: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'ID number - 13 digits'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 13,
                    maxLength: 15,
                },
                valid: false,
                touched: false
            },
            address: {
                label: 'Street address:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Street address'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
            suburb: {
                label: 'Suburb:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Suburb'
                },
                value: '',
                validation: {
                    firstChar: true
                },
                valid: true,
                touched: false
            },
            postalCode: {
                label: 'Postal Code:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Postal Code'
                },
                value: '',
                validation: {
                    minLength: 4,
                    maxLength: 4,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            companyNo: {
                label: 'Clinsys Check Point Unique Company Number: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Clinsys Check Point Unique company Number'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 7,
                    maxLength: 9,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            jobCategory: {
                label: 'NIOH Job Category:',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'UNKNOWN', displayValue: 'NIOH job category' },
                        { value: 'Managers', displayValue: 'Managers' },
                        { value: 'Professionals', displayValue: 'Professionals' },
                        { value: 'Technicians and Associate Professionals', displayValue: 'Technicians and Associate Professionals' },
                        { value: 'Clerical Support Workers', displayValue: 'Clerical Support Workers' },
                        { value: 'Services and Sales Workers', displayValue: 'Services and Sales Workers' },
                        { value: 'Skilled Agricultural, Forestry, Fishery, Craft and Related Trades Workers', displayValue: 'Skilled Agricultural, Forestry, Fishery, Craft and Related Trades Workers' },
                        { value: 'Plant and Machine Operators and Assemblers', displayValue: 'Plant and Machine Operators and Assemblers' },
                        { value: 'Elementary Occupations', displayValue: 'Elementary Occupations' }
                    ]
                },
                value: 'NIOH job category',
                validation: {},
                valid: true
            },
            employeeNo: {
                label: 'Employee Number:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Employee Number'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
        },
        gender: 'male',
        userType: 0,
        formIsValid: false,
        submitForm: false
    }

    componentDidMount() {
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');
        };
        this.setState({ coNum: this.props.loggedInUser.companyNo });
        this.setState(state => {
            state.signUpForm.companyNo.value = this.props.loggedInUser.companyNo
            return state}); 
    };

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (this.state.submitForm && !this.props.authError && !this.props.authLoading) {
            const currentUser = {};
            for (let formElementIdentifier in this.state.signUpForm) {
                currentUser[formElementIdentifier] = this.state.signUpForm[formElementIdentifier].value;
            };
            currentUser.gender = this.state.gender;
            this.props.createUser(currentUser);
            this.props.history.replace('/users');
        }
    };

    signInHandler = (event) => {
        event.preventDefault();
        this.setState({ submitForm: true })
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        };

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        };

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        };

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        };

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        };

        if (rules.isNumeric) {
            const pattern = /^[/\d-+]+$/;
            isValid = pattern.test(value) && isValid
        };

        return isValid;
    };

    inputChangedHandler = (event, inputIdentifier) => {
        this.setState({ submitForm: false })
        const updatedsignUpForm = {
            ...this.state.signUpForm
        };
        const updatedFormElement = {
            ...updatedsignUpForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;

        if (updatedFormElement.validation.firstChar) {
            updatedFormElement.value.charAt(0).toUpperCase();
        };
        if (updatedFormElement.validation.lowerCase) {
            updatedFormElement.value.toLowerCase();
        };

        updatedsignUpForm[inputIdentifier] = updatedFormElement;
        
        let formIsValid = true;
        for (let inputIdentifier in updatedsignUpForm) {
            formIsValid = updatedsignUpForm[inputIdentifier].valid && formIsValid;
        };
        if (event.target.value === '') {
            updatedsignUpForm[inputIdentifier].touched = false;
            formIsValid = updatedsignUpForm[inputIdentifier].valid;
        };
        this.setState({ signUpForm: updatedsignUpForm, formIsValid: formIsValid });
    }

    onChangeRadio = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.signUpForm) {
            formElementsArray.push({
                id: key,
                config: this.state.signUpForm[key]
            });
        }
        let gender = (
            <Fragment>
                <div className="radioTop" onChange={this.onChangeRadio}>
                    <input type="radio" value="Male" name="gender"
                            onChange={this.onChangeRadio} className="radioSpace" /> Male
                    <input type="radio" value="Female" name="gender"
                            onChange={this.onChangeRadio} className="radioSpace" /> Female
                    <input type="radio" value="Other" name="gender" 
                            onChange={this.onChangeRadio} className="radioSpace" /> Other *
                </div>
            </Fragment>
        )

        let userType = (
            <Fragment>
                <div className="radioBottom" onChange={this.onChangeRadio}>
                    <input type="radio" value="1" name="userType"
                            onChange={this.onChangeRadio} defaultChecked className="radioSpace" /> Employee
                    <input type="radio" value="2" name="userType"
                            onChange={this.onChangeRadio} className="radioSpace" /> Visitor
                </div>
            </Fragment>
        )
        
        if (this.props.loggedInUser.mainUser === true) {
            userType = (
                <Fragment>
                    <div className="radioBottom" onChange={this.onChangeRadio}>
                        <input type="radio" value="1" name="userType"
                                onChange={this.onChangeRadio} defaultChecked className="radioSpace" /> Employee
                        <input type="radio" value="2" name="userType"
                                onChange={this.onChangeRadio} className="radioSpace" /> Visitor
                        <input type="radio" value="3" name="userType"
                                onChange={this.onChangeRadio} className="radioSpace" /> Survey administrator
                        <input type="radio" value="4" name="userType"
                                onChange={this.onChangeRadio} className="radioSpace" /> Main User
                    </div>
                </Fragment>
            )
        };

        let form = (
            <form onSubmit={this.signInHandler}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        label={formElement.config.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        cbLabel={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        let errorMessage = this.props.authError;

        if (this.props.authLoading) {
            form = <Spinner />;
        }
        return (
            <div className="wrapper">
                <div className="containerFlex  main-wrapper">
                    <div className="filler">
                    </div>
                    <BodyTextBackground>
                        <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>   
                        <h2>New User</h2>
                        <h3>Please enter the persons details</h3>
                        <div className="label-left-black">
                            <p>*Required field</p>
                        </div>
                        <div className="error-text">
                            {this.props.authError ? <p>{errorMessage}</p> : null}
                        </div>
                        {gender}
                        {form}
                        {userType}
                        <div className="btn-containerFlex">
                            <button className="btnPrimary" onClick={this.signInHandler} disabled={!this.state.formIsValid}>Submit</button>
                        </div>
                    </BodyTextBackground>
                    <div className="filler">
                    </div>
                </div>
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        loggedInUser: state.userData.loggedInUser,
        loadingUser: state.userData.loading,
        companyDetails : state.companyData.companyDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createUser: (currentUser) => dispatch(createUser(currentUser)),
        clearCurrentUser: () => dispatch(clearCurrentUser()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
