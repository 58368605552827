import React, { Component } from 'react'
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import {getNIOHpositive} from '../dataStore/NIOHActions';
import {updateNIOHpositive} from '../dataStore/NIOHActions';
import {getCompany} from '../dataStore/CompanyActions';
import {clearCurrentUser, getSelectedUser}  from '../dataStore/UserActions';
import Spinner from '../components/UI/Spinner';
import convertDate from '../components/ConvertDate';
import './Form.css';
import Input from '../components/UI/Input';
import '../components/users/SurveyResults.css'

class NIOHPositive extends Component {
    state = {
        startDate: new Date(),
        companyName: '',
        province: '',
        fileName: '',
        updateForm: {
            firstName: {
                label: 'First name',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'First name/s'
                },
                value: '',
                validation: {
                    required: true,
                    firstChar: true
                },
                valid: false,
                touched: false
            },
            surname: {
                label: 'Last name (surname)',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Last name (surname)'
                },
                value: '',
                validation: {
                    required: true,
                    firstChar: true
                },
                valid: false,
                touched: false
            },
            age: {
                label: 'Age',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Age'
                },
                value: '',
                validation: {
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            idNo: {
                label: 'ID number / Passport Number',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'ID number - 13 digits'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 13,
                    maxLength: 15,
                },
                valid: false,
                touched: false
            },
            jobCategory: {
                label: 'NIOH Job Category',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'UNKNOWN', displayValue: 'NIOH job category' },
                        { value: 'Managers', displayValue: 'Managers' },
                        { value: 'Professionals', displayValue: 'Professionals' },
                        { value: 'Technicians and Associate Professionals', displayValue: 'Technicians and Associate Professionals' },
                        { value: 'Clerical Support Workers', displayValue: 'Clerical Support Workers' },
                        { value: 'Services and Sales Workers', displayValue: 'Services and Sales Workers' },
                        { value: 'Skilled Agricultural, Forestry, Fishery, Craft and Related Trades Workers', displayValue: 'Skilled Agricultural, Forestry, Fishery, Craft and Related Trades Workers' },
                        { value: 'Plant and Machine Operators and Assemblers', displayValue: 'Plant and Machine Operators and Assemblers' },
                        { value: 'Elementary Occupations', displayValue: 'Elementary Occupations' }
                    ]
                },
                value: 'NIOH job category',
                validation: {},
                valid: true
            },
            employeeNo: {
                label: 'Employee Number',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Employee Number'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
            district: {
                label: 'District',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Alfred Nzo', displayValue: 'Alfred Nzo' },
                        { value: 'Amajuba', displayValue: 'Amajuba' },
                        { value: 'Amathole', displayValue: 'Amathole' },
                        { value: 'Bojanala', displayValue: 'Bojanala' },
                        { value: 'Buffalo City', displayValue: 'Buffalo City' },
                        { value: 'Cape Winelands', displayValue: 'Cape Winelands' },
                        { value: 'Capricon', displayValue: 'Capricon' },
                        { value: 'Central Karoo', displayValue: 'Central Karoo' },
                        { value: 'Chris Hani', displayValue: 'Chris Hani' },
                        { value: 'City of Cape Town', displayValue: 'City of Cape Town' },
                        { value: 'City of Johannesburg', displayValue: 'City of Johannesburg' },
                        { value: 'City of Tshwane', displayValue: 'City of Tshwane' },
                        { value: 'Dr Kenneth Kaunda', displayValue: 'Dr Kenneth Kaunda' },
                        { value: 'Dr Ruth Segomotsi Mompati', displayValue: 'Dr Ruth Segomotsi Mompati' },
                        { value: 'Ehlazeni', displayValue: 'Ehlazeni' },
                        { value: 'Ekurhuleni', displayValue: 'Ekurhuleni' },
                        { value: 'eThekwini', displayValue: 'eThekwini' },
                        { value: 'Fezile Dabi', displayValue: 'Fezile Dabi' },
                        { value: 'Frances Baard', displayValue: 'Frances Baard' },
                        { value: 'Garden Route', displayValue: 'Garden Route' },
                        { value: 'Gert Sibande', displayValue: 'Gert Sibande' },
                        { value: 'Harry Gwala', displayValue: 'Harry Gwala' },
                        { value: 'iLembe', displayValue: 'iLembe' },
                        { value: 'Joe Gqabi', displayValue: 'Joe Gqabi' },
                        { value: 'John Taolo Gaetsewe', displayValue: 'John Taolo Gaetsewe' },
                        { value: 'King Centshwayo', displayValue: 'King Centshwayo' },
                        { value: 'Lejweleputswa', displayValue: 'Lejweleputswa' },
                        { value: 'Mangaung', displayValue: 'Mangaung' },
                        { value: 'Mopani', displayValue: 'Mopani' },
                        { value: 'Namakwa', displayValue: 'Namakwa' },
                        { value: 'Nelson Mandela Bay', displayValue: 'Nelson Mandela Bay' },
                        { value: 'Ngaka Modiri Molema', displayValue: 'Ngaka Modiri Molema' },
                        { value: 'Nkangala', displayValue: 'Nkangala' },
                        { value: 'OR Tambo', displayValue: 'OR Tambo' },
                        { value: 'Overberg', displayValue: 'Overberg' },
                        { value: 'Pixley ka Seme', displayValue: 'Pixley ka Seme' },
                        { value: 'Sarah Baartman', displayValue: 'Sarah Baartman' },
                        { value: 'Sedibeng', displayValue: 'Sedibeng' },
                        { value: 'Sekhukhune', displayValue: 'Sekhukhune' },
                        { value: 'Thabo Mofutsanyane', displayValue: 'Thabo Mofutsanyane' },
                        { value: 'Ugu', displayValue: 'Ugu' },
                        { value: 'Umgungundlovu', displayValue: 'Umgungundlovu' },
                        { value: 'Umkhanyakude', displayValue: 'Umkhanyakude' },
                        { value: 'Umzinyathi', displayValue: 'Umzinyathi' },
                        { value: 'Uthukela', displayValue: 'Uthukela' },
                        { value: 'Vhembe', displayValue: 'Vhembe' },
                        { value: 'Waterberg', displayValue: 'Waterberg' },
                        { value: 'West Coast', displayValue: 'West Coast' },
                        { value: 'West rand', displayValue: 'West rand' },
                        { value: 'Xhariep', displayValue: 'Xhariep' },
                        { value: 'ZF Mgcawu', displayValue: 'ZF Mgcawu' },
                        { value: 'Zululand', displayValue: 'Zululand' }
                    ]
                },
                value: 'District',
                validation: {},
                valid: true
            },
            province: {
                label: 'Province',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'XX-00', displayValue: 'Province' },
                        { value: 'EC-05', displayValue: 'Eastern Cape' },
                        { value: 'FS-04', displayValue: 'Free State' },
                        { value: 'GP-01', displayValue: 'Gauteng' },
                        { value: 'KN-03', displayValue: 'KwaZulu-Natal' },
                        { value: 'LI-08', displayValue: 'Limpopo' },
                        { value: 'MP-07', displayValue: 'Mpumalanga' },
                        { value: 'NC-09', displayValue: 'Northern Cape' },
                        { value: 'NW-06', displayValue: 'North West' },
                        { value: 'WC-02', displayValue: 'Western Cape' }
                    ]
                },
                value: 'Province',
                validation: {},
                valid: true
            },
            testDate: {
                label: 'Date of Test - DD/MM/CCYY',
                elementType: 'date',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Date of Test - DD/MM/CCYY'
                },
                value: '',
                validation: {},
                valid: true
            },
            testType: {
                label: 'Type of Test',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'PCR', displayValue: 'PCR' },
                        { value: 'Antigen', displayValue: 'Antigen' },
                        { value: 'Antiboby', displayValue: 'Antiboby' },
                    ]
                },
                value: 'PCR',
                validation: {},
                valid: true
            },
            symptoms: {
                label: 'Positive symptoms',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'YES', displayValue: 'YES' },
                        { value: 'NO', displayValue: 'NO' },
                        { value: 'Not Applicable', displayValue: 'Not Applicable' },
                    ]
                },
                value: 'YES',
                validation: {},
                valid: true
            },
            hospital: {
                label: 'Type of Hospital',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Public', displayValue: 'Public' },
                        { value: 'Private', displayValue: 'Private' },
                        { value: 'Not Applicable', displayValue: 'Not Applicable' },
                    ]
                },
                value: 'Public',
                validation: {},
                valid: true
            },
            result: {
                label: 'Result of Test',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Positive', displayValue: 'Positive' },
                        { value: 'Negative', displayValue: 'Negative' },
                        { value: 'Unknown', displayValue: 'Unknown' },
                    ]
                },
                value: 'Positive',
                validation: {},
                valid: true
            },
            postTest: {
                label: 'Post Test',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Sick Leave', displayValue: 'Sick Leave' },
                        { value: 'Self-Isolation at home', displayValue: 'Self-Isolation at home' },
                        { value: 'Self-Isolation at quarantine site', displayValue: 'Self-Isolation at quarantine site' },
                        { value: 'Hospitalization', displayValue: 'Hospitalization' },
                    ]
                },
                value: 'Sick Leave',
                validation: {},
                valid: true
            },
            exposure: {
                label: 'Exposure',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Work contacts', displayValue: 'Work contacts' },
                        { value: 'Community contacts', displayValue: 'Community contacts' },
                        { value: 'Family contacts', displayValue: 'Family contacts' },
                        { value: 'Large events contacts', displayValue: 'Large events contacts' },
                        { value: 'Travel history from a designated hotspot', displayValue: 'Travel history from a designated hotspot' },
                        { value: 'Public transport', displayValue: 'Public transport' },
                        { value: 'Unknown', displayValue: 'Unknown' },
                    ]
                },
                value: 'Work contacts',
                validation: {},
                valid: true
            },
            reporting: {
                label: 'Reporting',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Reported to Department of Employment and Labour', displayValue: 'Reported to Department of Employment and Labour' },
                        { value: 'Reported to COIDA', displayValue: 'Reported to COIDA' },
                        { value: 'Reported to DMRE', displayValue: 'Reported to DMRE' },
                    ]
                },
                value: 'Reported to Department of Employment and Labour',
                validation: {},
                valid: true
            },
            returnToWork: {
                label: 'Return to work fitness',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Fit for Job Description', displayValue: 'Fit for Job Description' },
                        { value: 'Fit with Accommodation', displayValue: 'Fit with Accommodation' },
                        { value: 'Fit with restrictions', displayValue: 'Fit with restrictions' },
                        { value: 'Temporarily unfit for Job Description', displayValue: 'Temporarily unfit for Job Description' },
                        { value: 'Unfit for review', displayValue: 'Unfit for review' },
                        { value: 'Permanently Unfit', displayValue: 'Permanently Unfit' },
                    ]
                },
                value: 'Fit for Job Description',
                validation: {},
                valid: true
            },
        },
        gender: 'male',
        PCR: false,
        antigen: false,
        antiboby: false,
        formIsValid: false,
        submitForm: false
    };

    componentDidMount () {
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');   
        };
        if (this.props.loggedInUser.companyNo ) {
            this.props.getCompany(this.props.loggedInUser.companyNo);
            const first  =  this.state.startDate.getDate() - this.state.startDate.getDay();
            const curWeek = new Date(this.state.startDate.setDate(first));
            const week = convertDate(curWeek);
            const fileName  = this.props.companyDetails.companyName + '-' + week;
            this.setState({fileName: fileName});
            console.log('currentUser', this.props.currentUser)
            this.props.getNIOHpositive(this.props.currentUser);
        };
    };
    
    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (this.props.loadingNIOH) {
            return
        }
        if (!this.props.coLoading && this.state.companyName === '' ) {
            const companyKey = {...this.props.companyDetails};
            for (let key in companyKey) {
                const { ...companyDetails } = companyKey[key];
                this.setState({companyName: companyDetails.companyName});
            };
        };
        if (!this.props.loadingUser && (this.state.updateForm.surname.value === '') && (this.props.currentUser.surname !== '')) {
            const userDetails = this.props.currentUser;
            const formDetails = {
                ...this.state.updateForm
            };
            console.log ('formDetails = ', formDetails)
            if (userDetails.age === undefined) { userDetails.age = '21' };
            if (userDetails.jobCategory === undefined) { userDetails.jobCategory = 'unknown' };
            if (userDetails.suburb === undefined) { userDetails.suburb = '' };
            if (userDetails.address === undefined) { userDetails.address = '' };
            if (userDetails.postalCode === undefined) { userDetails.postalCode = '' };
            if (userDetails.district === undefined) { userDetails.district = this.props.companyDetails.district };
            if (userDetails.province === undefined) { userDetails.province = this.props.companyDetails.province };
            if (userDetails.country === undefined) { userDetails.country = this.props.companyDetails.province };
            if (userDetails.employeeNo === undefined) { userDetails.employeeNo = '' };
            // const currentDate = convertDate(this.state.startDate);
            // if (userDetails.testDate === undefined) { userDetails.testDate = currentDate };
            if (userDetails.testDate === undefined) { userDetails.testDate = '12/01/2021' };
            for (let formElementIdentifier in userDetails) {
                const stringArray = ["firstName", "surname", "age", "idNo", "district", "province", "jobCategory", "employeeNo"];
                const process = (stringArray.indexOf(formElementIdentifier) > -1)
                if (process) {
                    formDetails[formElementIdentifier] =
                        userDetails[formElementIdentifier]
                };
            };
            this.setState({ gender: userDetails.gender });

            for (const elementIdentifier in formDetails) {
                this.setState(state => {
                    state.updateForm[elementIdentifier].value =
                        formDetails[elementIdentifier]
                    return state
                });
            };
        };
    };
  
    handleChangeDate = date => {
        this.setState({
            startDate: date,
        });

        const first  =  date.getDate() - date.getDay();
        const curWeek = new Date(date.setDate(first));
        const week = convertDate(curWeek);
        const fileName  = this.props.companyDetails.companyName + '-' + week;
        this.setState({fileName: fileName})
        this.props.getWeekSurveys(this.props.loggedInUser.companyNo,  date)
    };

    handleDetailsOK = (event) => {
        event.preventDefault();
        const currentUser = {};
        for (let formElementIdentifier in this.state.updateForm) {
            currentUser[formElementIdentifier] = this.state.updateForm[formElementIdentifier].value;
        };
        currentUser.gender = this.state.gender;
        if (this.props.currentUser.firebaseID === undefined){
            currentUser.firebaseID = this.props.currentUser.id;
        } else{
            currentUser.firebaseID = this.props.currentUser.firebaseID;};
        
        this.setState({ submitForm: true });
        this.props.updateNIOHpositive(currentUser);
        this.props.getSelectedUser(this.props.loggedInUser.companyNo, this.props.currentUser.id);
        this.props.history.push('/users');
    }

    onChangeCheckbox = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    
    render() {
        let testType = (
            <div>
                <input type="checkbox" value='false' name="PCR"
                        onChange={this.onChangeCheckbox}
                        className="checkbox" /> Positive Case (PCR)
                <input type="checkbox" value="false" name="antigen"
                        onChange={this.onChangeCheckbox}
                        className="checkbox" /> Antigen Test
                <input type="checkbox" value="false" name="antibody"
                        onChange={this.onChangeCheckbox}
                        className="checkbox" /> Antibody Test
            </div>
        );

        const formElementsArray = [];
        for (let key in this.state.updateForm) {
            formElementsArray.push({
                id: key,
                config: this.state.updateForm[key]
            });
        }

        let formInfo = (
            <div >
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        label={formElement.config.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        cbLabel={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </div>
        );
        if (this.props.loadingUser) {
            formInfo = <Spinner />;
        }

        return (
            <div className="wrapper">
            <div className="containerFlex  main-wrapper">
                <div className="filler">
                </div>
                <div className="main formData">
                    <div className="input-field">
                        <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>  
                    </div> 
                    <p className="h3">Positive Test Results for {this.state.firstName} {this.state.surname}</p>
                    {testType}
                    {formInfo}
                    <div className="input-field">
                        <button className="btnPrimary" onClick={this.handleDetailsOK}>Update Details</button>
                    </div>
                </div>
                <div className="filler">
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        loadingSurveys: state.surveyData.loading,
        weekSurveys: state.surveyData.weekSurveys,
        questions: state.surveyData.questions,
        maxDays: state.surveyData.maxDays,
        loggedInUser : state.userData.loggedInUser,
        companyDetails : state.companyData.companyDetails,
        coLoading: state.companyData.loading,
        currentUser: state.userData.currentUser,
        positiveUser: state.NIOHData.positiveUser,
        loadingNIOH: state.NIOHData.loading,
        lastSurvey: state.surveyData.userDetails,
    };
};
 
const mapDispatchToProps = (dispatch) => ({
    updateNIOHpositive: (currentUser) => dispatch(updateNIOHpositive(currentUser)),
    getCompany: (companyNo) => dispatch(getCompany(companyNo)),
    getNIOHpositive: (currentUser) => dispatch(getNIOHpositive(currentUser)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
    getSelectedUser: (company, id) => dispatch(getSelectedUser(company, id)),
});
 
 export default connect(mapStateToProps, mapDispatchToProps)(NIOHPositive);