import React from 'react'
import BodyTextBackground from './UI/BodyTextBackground';

const userRegistered = () => {
  return (
        <div className="wrapper">
            <div className="containerFlex  main-wrapper">
                <div className="filler">
                </div>
                <BodyTextBackground>
                    <p className='h3'>Thank you for registering with us</p>
                    <p>An email has been sent you</p>
                    <br />
                    <p>Please make sure to check your spam and trash if you can't find the email.</p>
                    <br />
                    <br />
                    <p>Once you verify your password your will be able to sign in.</p>
                    <br />
                    <p>When you sign in you will be taken to the Survey screen.</p>
                    <p>Please fill this in honestly.</p>
                    <br />
                    <p>Your company will be submitting this information to NIOH.</p>
                    <p>This is required by law during the lockdown period.</p>
                    <br />
                    <p>For more information please contact</p>
                    <br />
                    <p>email: clinsys@twinsolutions.co.za</p>
                </BodyTextBackground>
                <div className="filler">
                </div>
            </div>
        </div>
  )
}

export default userRegistered