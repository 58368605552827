import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {resetAuthLoading} from '../dataStore/AuthActions';
import {resetUserLoading} from '../dataStore/UserActions';
import {fetchCompanys } from '../dataStore/CompanyActions';
import {loadQuestions, createChecks, resetSurveyLoading} from '../dataStore/SurveyActions';

import BodyTextBackground from './UI/BodyTextBackground';
import PhoneMockup1 from '../Assets/PhoneMockup1.png';
import PhoneMockup2 from '../Assets/PhoneMockup2.png';

class Home extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.resetAuthLoading();
    this.props.resetSurveyLoading();
    this.props.resetUserLoading();
    // this.props.createChecks();
    this.props.loadQuestions();
    this.props.getAllCompanys();
  }

  render() {
  return (
    <div className="wrapper">
      <div className="homeWrapper">
          <img src={PhoneMockup1} className="phone1" alt="" />
          <BodyTextBackground>
                <p className='h3'>Welcome to Check Point</p>
                <p className='h3'>Why use our App?</p>
                <p>Enviromently friendly</p>
                <p>Paperless system</p>
                <p>Less chance of spreading disease</p>
                <p className='h3'>How it works</p>
                <p>Companies register to use the site.</p>
                <p>Each company will receive a unique ID.</p>
                <p>Your employees and visitors will link in using this ID via the mobile App or this website.</p>
                <p>All information will be stored so that companies can get daily/monthly reports when required.</p>
                <div className="btn-containerFlex">
                    <Link to='/newCompany'>
                    <button className="btnPrimary" style={{width: 200}}>Register New Company</button>
                    </Link>
                </div>
            </BodyTextBackground>
            <img src={PhoneMockup2} className="phone2" alt="" />
      </div>
    </div>
  );
}};

const mapDispatchToProps = (dispatch) => {
    return {
      resetAuthLoading: () => dispatch(resetAuthLoading()),
      resetSurveyLoading: () => dispatch(resetSurveyLoading()),
      resetUserLoading: () => dispatch(resetUserLoading()),
      loadQuestions: () => dispatch(loadQuestions('')),
      createChecks: () => dispatch(createChecks()),
      getAllCompanys: () => dispatch(fetchCompanys()),
    };
};

export default connect(null, mapDispatchToProps)( Home)