
function monthExport (ID, FullName, AvgTemp, Day1, Day2, Day3, Day4, Day5, Day6, Day7, Day8, Day9, Day10,
    Day11, Day12, Day13, Day14, Day15, Day16, Day17, Day18, Day19, Day20,
    Day21, Day22, Day23, Day24, Day25, Day26, Day27, Day28, Day29, Day30, Day31 ){
    this.ID = ID;
    this.FullName = FullName;
    this.AvgTemp = AvgTemp;
    this.Day1 = Day1;
    this.Day2 = Day2;
    this.Day3 = Day3;
    this.Day4 = Day4;
    this.Day5 = Day5;
    this.Day6 = Day6;
    this.Day7 = Day7;
    this.Day8 = Day8;
    this.Day9 = Day9;
    this.Day10 = Day10;
    this.Day11 = Day11;
    this.Day12 = Day12;
    this.Day13 = Day13;
    this.Day14 = Day14;
    this.Day15 = Day15;
    this.Day16 = Day16;
    this.Day17 = Day17;
    this.Day18 = Day18;
    this.Day19 = Day19;
    this.Day20 = Day20;
    this.Day21 = Day21;
    this.Day22 = Day22;
    this.Day23 = Day23;
    this.Day24 = Day24;
    this.Day25 = Day25;
    this.Day26 = Day26;
    this.Day27 = Day27;
    this.Day28 = Day28;
    this.Day29 = Day29;
    this.Day30 = Day30;
    this.Day31 = Day31;
  }

  export default monthExport;