import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import {surveyWeekTable} from '../dataStore/SurveyActions';
import {getCompany} from '../dataStore/CompanyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import Spinner from '../components/UI/Spinner';
import convertDate from '../components/ConvertDate';
import ExportToExcel from '../components/ExportToExcel';
import '../components/users/SurveyResults.css'

const provOptions = [
    { value: 'XX-00', displayValue: 'Province' },
    { value: 'EC-05', displayValue: 'Eastern Cape' },
    { value: 'FS-04', displayValue: 'Free State' },
    { value: 'GP-01', displayValue: 'Gauteng' },
    { value: 'KN-03', displayValue: 'KwaZulu-Natal' },
    { value: 'LI-08', displayValue: 'Limpopo' },
    { value: 'MP-07', displayValue: 'Mpumalanga' },
    { value: 'NC-09', displayValue: 'Northern Cape' },
    { value: 'NW-06', displayValue: 'North West' },
    { value: 'WC-02', displayValue: 'Western Cape' }
];

let exportData = [];

class NIOHWeekly extends Component {
    state = {
        startDate: new Date(),
        companyName: '',
        province: '',
        fileName: ''
    };

    componentDidMount () {
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');   
        };
        if (this.props.loggedInUser.companyNo ) {
            this.props.getCompany(this.props.loggedInUser.companyNo);
            const first  =  this.state.startDate.getDate() - this.state.startDate.getDay();
            const curWeek = new Date(this.state.startDate.setDate(first));
            const week = convertDate(curWeek);
            const fileName  = this.props.companyDetails.companyName + '-' + week;
            this.setState({fileName: fileName});
            this.props.getWeekSurveys(this.props.loggedInUser.companyNo,  this.state.startDate);
        };
        exportData = [];
    };
    
    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (!this.props.coLoading && this.state.companyName === '' ) {
            const companyKey = {...this.props.companyDetails};
            for (let key in companyKey) {
                const { ...companyDetails } = companyKey[key];
                this.setState({companyName: companyDetails.companyName});
                for (let index = 0; index < provOptions.length; index++) {
                    const element = provOptions[index];
                    if (element.value === this.props.companyDetails.province) {
                        this.setState({province: element.displayValue}); 
                    };
                };
            };
        };
    };
  
    handleChangeDate = date => {
        this.setState({
            startDate: date,
        });

        const first  =  date.getDate() - date.getDay();
        const curWeek = new Date(date.setDate(first));
        const week = convertDate(curWeek);
        const fileName  = this.props.companyDetails.companyName + '-' + week;
        this.setState({fileName: fileName})
        this.props.getWeekSurveys(this.props.loggedInUser.companyNo,  date);
        exportData = [];
    };

    async retrieveExportData  ()  {
        if (exportData.length > 0) {
            return;
        };
        for (let index = 0; index <  this.props.weekSurveys.length; index++) {
            const survey =  this.props.weekSurveys[index];
            const updatedEmployee =  ({
                // BusinessID: this.props.companyDetails.UUID,
                BusinessID: survey.surname,
                // REMEMBER to CHANGE BACK
                Consent: survey.consent,
                EmployeeID: survey.idNo,
                Screening_Date: survey.surveyDate,
                Age: survey.age,
                Gender: survey.gender,
                Job: survey.jobCategory,
                Province: this.state.province,
                District: this.props.companyDetails.district,
                Symptoms_Status: 'YES',
                Fever: survey.check3,
                Chills: survey.check4,
                Dry_Cough: survey.check5,
                Sore_Throat: survey.check6,
                Shortness_of_Breath: survey.check7,
                Tired: survey.check8,
                Loss_of_Taste_or_Smell: survey.check9,
                Conjunctivitis: survey.check10,
                Diarrhoea: survey.check11,
                Muscle_Pains: survey.check12,
                Nausea_or_Vomiting: survey.check13,
                Dizziness: survey.check14,
                Headache: survey.check15,
                ScreeningOutcome__no_Action_required: survey.action1,
                ScreeningOutcome__referred_for_self_isolation: survey.action2,
                ScreeningOutcome__referred_for_testing: survey.action3,
                ScreeningOutcome__referred_to_doctor: survey.action4
            });
            exportData.push({ ...updatedEmployee });
        };
    };
    
     renderTableData() {
        if (this.props.weekSurveys  && !this.props.loadingSurveys) {
             this.retrieveExportData();
            return exportData.map( (survey, s) => {
                return (
                    <tr key={s}style={{color: survey.colour}}>
                        <td className="width120">{survey.BusinessID}</td>
                        <td className="width50">{survey.Consent}</td>
                        <td className="width120">{survey.EmployeeID }</td>
                        <td className="width120">{survey.Screening_Date}</td>
                        <td className="width50">{survey.Age}</td>
                        <td className="width50">{survey.Gender}</td>
                        <td className="width120">{survey.Job}</td>
                        <td className="width120">{survey.Province}</td>
                        <td className="width120">{survey.District}</td>
                        <td className="width120">{'YES'}</td>
                        <td className="width50">{survey.Fever}</td>
                        <td className="width50">{survey.Chills}</td>
                        <td className="width50">{survey.Dry_Cough}</td>
                        <td className="width50">{survey.Sore_Throat}</td>
                        <td className="width50">{survey.Shortness_of_Breath}</td>
                        <td className="width50">{survey.Tired}</td>
                        <td className="width50">{survey.Loss_of_Taste_or_Smell}</td>
                        <td className="width50">{survey.Conjunctivitis}</td>
                        <td className="width50">{survey.Diarrhoea}</td>
                        <td className="width50">{survey.Muscle_Pains}</td>
                        <td className="width50">{survey.Nausea_or_Vomiting}</td>
                        <td className="width50">{survey.Dizziness}</td>
                        <td className="width50">{survey.Headache}</td>
                        <td className="width120">{survey.ScreeningOutcome__no_Action_required}</td>
                        <td className="width120">{survey.ScreeningOutcome__referred_for_self_isolation}</td>
                        <td className="width120">{survey.ScreeningOutcome__referred_for_testing}</td>
                        <td className="width120">{survey.ScreeningOutcome__referred_to_doctor}</td>
                    </tr>)
            });
        };
    };

    renderTableHeader() {
        if (!this.props.weekSurveys || (this.props.weekSurveys.length === 0)) {
            return null
        } else
            if (this.props.weekSurveys  && !this.props.loadingSurveys ) {
                let  header = ['BusinessID', 'Consent', 'Employee ID',  'Screening Date','Age', 
                'Gender', 'Job',  'Province',  'District',  'Symptoms Status',
                'Fever', 'Chills',  'Dry Cough',  'Sore Throat',  'Shortness of Breath',
                'Tired', 'Loss of taste or smell',  'Conjunctivitis',  'Diarrhoea',  'Muscle pains',
                'Nausea or vomiting', 'Dizziness',  'Headache',  'ScreeningOutcome - no Action required',  
                'ScreeningOutcome - referred for self-isolation', 'ScreeningOutcome - referred for testing', 
                'ScreeningOutcome - referred to doctor', ];    
                return header.map((colTitle, index) => {
                    return <th key={index}>{colTitle}</th>
                });
            };  
    };

    
    render() {

        let dateArea = <Spinner />;

        if ( !this.props.loadingSurveys ) {
            dateArea = (
                <div className="date" >
                {/* <label className="blue centre" >Week starting: </label> */}
                <h2 className="centred" id='titledate'>Click box to Select Week starting Sunday:</h2>
                    <DatePicker className="date-picker"
                        selected={ this.state.startDate }
                        onChange={e => this.handleChangeDate(e)}
                        name="startDate"
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
            );
        };

        let surveyWeekTable = null;
        if ( !this.props.loadingSurveys ) {
            surveyWeekTable = (
                <tbody>
                    <tr>{this.renderTableHeader()}</tr>
                    {this.renderTableData()}
                </tbody>
            );
        };

        let message = null;
        if ( !this.props.weekSurveys || (this.props.weekSurveys.length === 0)) {
            message = <h2 style={{color:"red"}} className="message">Nothing to report - Everyone is healthy!!</h2>
        };

        return (
                <div className="flexGrow" style={{ marginBottom: 30}}>
                    <div className="right" >
                        <ExportToExcel csvData={exportData} fileName={this.state.fileName} />
                    </div>
                    <h1 className="centred" id='title'>NIOH Weekly Report for</h1>
                    <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>       
                    <div className="centred" >
                        {dateArea}
                        {message}
                    </div>
                    <table className='table' id='surveys'>
                            {surveyWeekTable}
                    </table>
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        loadingSurveys: state.surveyData.loading,
        weekSurveys: state.surveyData.weekSurveys,
        questions: state.surveyData.questions,
        maxDays: state.surveyData.maxDays,
        loggedInUser : state.userData.loggedInUser,
        companyDetails : state.companyData.companyDetails,
        coLoading: state.companyData.loading,
    };
};
 
const mapDispatchToProps = (dispatch) => ({
    getWeekSurveys: (company, startDate) => dispatch(surveyWeekTable(company, startDate)),
    getCompany: (companyNo) => dispatch(getCompany(companyNo)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});
 
 export default connect(mapStateToProps, mapDispatchToProps)(NIOHWeekly);