import getCurrentDate from '../components/CurrentDate';
import calcAge from '../components/CalcAge';

export const FETCH_USERS = 'FETCH_USERS';
export const CURRENT_USER = 'CURRENT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOADING_USER = 'LOADING_USER';
export const CHECK_USER_EXISTS = 'CHECK_USER_EXISTS';
export const LOADING_USER_RESET = 'LOADING_USER_RESET';
export const FILE_FINISH = 'FILE_FINISH';
export const FILE_LOADING = 'FILE_LOADING';
export const USER_FAIL = 'USER_FAIL';
export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_LOGINUSER = 'CLEAR_LOGINUSER';
export const UPDATE_LOGINUSER = 'UPDATE_LOGINUSER';


export const loadingUser = () => {
  return {
    type: LOADING_USER
  };
};

export const clearLoginUser = () => {
  return {
    type: CLEAR_LOGINUSER
  };
};

export const updateLoginUser = (currentUser) => {
    console.log('in updateLoginUser- currentUser.firebaseID = ', currentUser.firebaseID)
    console.log('in updateLoginUser- currentUser = ', currentUser)
  return async (dispatch) => {
    try {
        const  loginUser = {
            firebaseID: currentUser.firebaseID,
            email: currentUser.email,
            idNo: currentUser.idNo,
            companyNo: currentUser.companyNo,
            mainUser: currentUser.mainUser,
            surname: currentUser.surname,
        };

        if (currentUser.mainUser) {
            dispatch({ type: 'MAIN_USER' });
        }

        dispatch({
            type: UPDATE_LOGINUSER,
            loginUser: loginUser,
        });
    }
    catch (err) {
        dispatch({
            type: USER_FAIL,
            error: err
        });
    };
  };
};

export const resetUserLoading = () => {
  return async (dispatch) => {
    dispatch({ type: 'LOADING_USER_RESET' });
  };
};

export const fileLoading = () => {
    return {
        type: FILE_LOADING
    };
};

function sortAlphabeticallyAscending(a, b) {
    const surnameA = a.surname[0].toUpperCase() + a.surname.substring(1);
    const surnameB = b.surname[0].toUpperCase() + b.surname.substring(1);
    const a1 = surnameA + a.firstName + a.id;
    const b1 = surnameB + b.firstName + b.id;
    if (a1 < b1) return -1;
    else if (a1 > b1) return 1;
    else return 0;
}

export const fetchUsers = (companyNo) => {
    if (companyNo === ' ' ) {
        return;
    };
    return async (dispatch) => {
        dispatch(loadingUser());
        try {
            const response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS.json?`,
                );
                
                if (!response.ok) {
                    throw new Error(`Unable to connect to server! ${response.message}`);
                };
                
            const resData = await response.json();
            let curUsers = [];
            for (const key in resData) {
                if (resData[key].surname !== undefined) {
                    curUsers.push({
                        ...resData[key],
                        id: key
                    });
                };
            };

            if (curUsers.length > 0) {
                await curUsers.sort(sortAlphabeticallyAscending);
            };
            
            dispatch({
                type: FETCH_USERS,
                availableUsers: curUsers,
            });
        }
        catch (err) {
            dispatch({
                type: USER_FAIL,
                error: err
            });
        }
    };
};


export const clearCurrentUser= () => {
    return async (dispatch) => {
      dispatch(loadingUser());
      try {
        const  currentUser = {
            id: 0,
            email: 'Enter email address here',
            firstName: '',
            surname: '',
            companyNo: '',
            jobCategory: 'unknown',
            employeeNo: '',
            cellNo: '',
            idNo: '',
            DOB: '',
            mainUser: false,
            visitor: false,
            altCompany: '',
            firebaseID: '',
            telNo: '',
            userType: '',
            address: '',
            suburb: '',
            postalCode: '',
            district : '',
            province : '',
            country: '',
            surveyDate: '',
            createdDate: '',
            modifiedDate: '' 
        };
  
        dispatch({
          type: CLEAR_USER,
          currentUser: currentUser,
        });
      }
      catch (err) {
        dispatch({
          type: USER_FAIL,
          error: err
        });
      };
    };
  };
  
  

export const getAllRegUsers = () => {
  return async (dispatch) => {
    dispatch(loadingUser());
    try {
        let response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/ALLUSERS.json?`,
        );

        if (!response.ok) {
            throw new Error(`Unable to connect to server! ${response.message}`);
        };

        let resData = await response.json();
        let curUsers = [];
        let allRegUsers = [];
        for (const key in resData) {
            curUsers.push({
            ...resData[key],
            id: key
            });
        };
        
        for (let index = 0; index < curUsers.length; index++) {
            const element = curUsers[index];
            const keyNames = Object.keys(element);
            const fbKey = keyNames[0];
            let  companyNo = ''
            let  firebaseID = ''
            for(var i in element){
                if (fbKey === i) {
                    const company = element[i];
                    companyNo = company.companyNo;
                    firebaseID = company.firebaseID;
                };
              };
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,
                );
            resData = await response.json();
            if (resData && (resData.surname !== undefined)) {
                allRegUsers.push({...resData,
                                id: firebaseID});
            }
        };
        
        if (allRegUsers.length > 0) {
            await allRegUsers.sort(sortAlphabeticallyAscending);
        };

        dispatch({
            type: FETCH_USERS,
            availableUsers: allRegUsers,
        });
    }
    catch (err) {
      dispatch({
        type: USER_FAIL,
        error: err
      });
    }
  };
};


export const createUser = (currentUser) => {
  return async (dispatch) => {
    dispatch(loadingUser());
    let email = currentUser.email;
    const firstName = currentUser.firstName;
    const surname = currentUser.surname;
    const companyNo = currentUser.companyNo;
    const jobCategory = currentUser.jobCategory;
    const employeeNo = currentUser.employeeNo;
    const cellNo = currentUser.cellNo;
    const telNo = currentUser.telNo;
    const idNo = currentUser.idNo;
    const DOB = currentUser.DOB;
    const age = calcAge(currentUser.DOB);
    const address = currentUser.address;
    const suburb = currentUser.suburb;
    const postalCode = currentUser.postalCode;
    const gender = currentUser.gender;
    const userType = currentUser.userType;
    const foundFirebaseID = currentUser.firebaseID;
    let mainUser = false;
    if (currentUser.userType === 4) {
      mainUser = true;
    };
    const lastSurveyDate = '2020/01/01';
    const lastSurveyID = '2020/01/01';
    const createdDate = getCurrentDate();
    const modifiedDate = getCurrentDate();

    let district = 'district';
    let province = 'province';
    let country = 'South Africa';

    let altCompany = '00999999';
    
    let modEmail = String(idNo);
    if (email && (email !== '')){modEmail = String(email)};
    modEmail = modEmail.replace(/\./g, "__dot__");
    modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');

    try {
        let response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json`,
            )

        const resDataCo = await response.json();
        let curCompany = [];
        if (resDataCo) {
            for (const key in resDataCo) {
                curCompany.push({
                ...resDataCo[key],
                firebaseID: key
                })
            };
        };
        district = curCompany[0].district;
        province = curCompany[0].province;
        country = curCompany[0].country;
        response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
        );

        let userFound = false;
        let resData = await response.json();
        let company = '';
        let oldSurname = '';
        let firebaseID = '';
        if (resData) {
            let curUsers = [];
            for (const key in resData) {
                curUsers.push({ ...resData[key] });
            }
            company = curUsers[0].companyNo;
            firebaseID = curUsers[0].firebaseID;
            oldSurname = curUsers[0].surname;
            userFound = true;
        }
        if (companyNo && company !== companyNo) {
            altCompany = company
        };

        if (foundFirebaseID) {
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${foundFirebaseID}.json`,
            );
            let resData = await response.json();
            if (resData) {
                firebaseID = foundFirebaseID;
                company = companyNo;
                userFound = true;
            };
        };

        let url = `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS.json`
        let curMethod = 'POST'
        if (userFound && (company === companyNo)) {
            url = `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`
            curMethod = 'PATCH'
        }; 

        response = await fetch(
            url,
            {
                method: curMethod,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    firstName,
                    surname,
                    companyNo,
                    jobCategory,
                    employeeNo,
                    telNo,
                    cellNo,
                    idNo,
                    DOB,
                    age,
                    userType,
                    mainUser,
                    altCompany,
                    address,
                    suburb,
                    postalCode,
                    district,
                    province,
                    country,
                    gender,
                    lastSurveyDate,
                    lastSurveyID,
                    createdDate,
                    modifiedDate
                })
            }
        );

        resData = await response.json();
        if (userFound && foundFirebaseID) {
            userFound = false;
        }; 

        if (!userFound) {
            firebaseID = resData.name
            if (foundFirebaseID) {
                firebaseID = foundFirebaseID;
            };
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    companyNo,
                    firebaseID,
                    firstName,
                    surname,
                    idNo,
                    email,
                    mainUser,
                    createdDate
                    })
                }
            );
        };

        if (userFound && (company !== companyNo) && (oldSurname === surname)) {
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            );

            let resData = await response.json();
            let fBKey = '';
            for (let key in resData) {
                fBKey = key;
            }

            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}/${fBKey}.json`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        companyNo,
                        firebaseID,
                        modifiedDate
                    })
                }
            );
        };

        if (userFound && (company !== companyNo) && (oldSurname !== surname)) {
                throw new Error('This email address is currently used by someone else');
        };

        const curDBUser = ({
            ...currentUser,
            firebaseID: firebaseID,
            altCompany: altCompany,
            lastSurveyDate: lastSurveyDate,
            lastSurveyID: lastSurveyID,
            dateModified: modifiedDate
        });
        dispatch({
            type: CURRENT_USER,
            currentUser: curDBUser
        });
    } catch (error) {
        dispatch({
            type: USER_FAIL,
            error: error
        });
    }
  };
};

export const updateUser = (currentUser) => {
    let email = currentUser.email;
    const firstName = currentUser.firstName;
    const surname = currentUser.surname;
    const companyNo = currentUser.companyNo;
    const jobCategory = currentUser.jobCategory;
    const employeeNo = currentUser.employeeNo;
    const cellNo = currentUser.cellNo;
    const telNo = currentUser.telNo;
    const idNo = currentUser.idNo;
    const DOB = currentUser.DOB;
    const age = calcAge(currentUser.DOB);
    const address = currentUser.address;
    const suburb = currentUser.suburb;
    const postalCode = currentUser.postalCode;
    let district = currentUser.district;
    let province = currentUser.province;
    let country = currentUser.country;
    const gender = currentUser.gender;
    const lastSurveyDate = currentUser.lastSurveyDate;
    const lastSurveyID = currentUser.lastSurveyID;
    const modifiedDate = getCurrentDate();
    let userType = currentUser.userType;
    let firebaseID = currentUser.firebaseID;
    let mainUser = currentUser.mainUser;
    if (email === 'gina@softsense.co.za' || (email === 'checkpoint@twinsolutions.co.za')  || email === 'clinsys@twinsolutions.co.za' || email === 'gina7.phillips@gmail.com' ) {
        userType = 5;
    };
    if (userType > 3) {
        mainUser = true;
    };

    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const isValid = pattern.test(email);
    if (!isValid) {
        email = '';
    };

    let modEmail = String(idNo);
    if (email && (email !== '')){modEmail = String(email)};
    modEmail = modEmail.replace(/\./g, "__dot__");
    modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
    
    return async (dispatch) => {
        dispatch(loadingUser());
        try {
            let response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json`,
                )
    
            const resDataCo = await response.json();
            let curCompany = [];
            if (resDataCo) {
                for (const key in resDataCo) {
                    curCompany.push({
                    ...resDataCo[key],
                    firebaseID: key
                    })
                };
            };
            district = curCompany[0].district;
            province = curCompany[0].province;
            country = curCompany[0].country;
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            );

            let resData = await response.json();
            let curUsers = [];
            let fBKey = '';
            for (let key in resData) {
                fBKey = key;
            }

            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}/${fBKey}.json`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        mainUser,
                        modifiedDate
                    })
                });

            for (const key in resData) {
                curUsers.push({ ...resData[key] });
            };
            if (curUsers[0].firebaseID) {
                firebaseID = curUsers[0].firebaseID;
            };


        response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({
                    email,
                    firstName,
                    surname,
                    companyNo,
                    jobCategory,
                    employeeNo,
                    cellNo,
                    telNo,
                    idNo,
                    DOB,
                    age,
                    userType,
                    mainUser,
                    address,
                    suburb,
                    postalCode,
                    district,
                    province,
                    country,
                    gender,
                    lastSurveyDate,
                    lastSurveyID,
                    modifiedDate
                })
            }
        );

        resData = await response.json();

        //*****checking user is also on ALLUSERS if not add */

      response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
      );
      resData = await response.json();
      if (!resData) {
        response = await fetch(
          `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              companyNo,
              firebaseID,
              firstName,
              surname,
              idNo,
              mainUser,
              modifiedDate
            })
          }
        );
      };

      dispatch({
        type: CURRENT_USER,
        currentUser: currentUser
      });
    } catch (error) {
      dispatch({
        type: USER_FAIL,
        error: error
      });
    }
  };
};


export const checkUserExists = (email, forCompany) => {
    //for new companies forCompany = true
    let userExists = false;
    if (email === undefined) {
        return
    };
    return async (dispatch) => {
        dispatch(loadingUser());
        try {
            let modEmail = String(email);
            modEmail = modEmail.replace(/\./g, "__dot__");
            modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
            let response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            );
            let resData = await response.json();
            if (resData) {
                userExists = true;

                if (forCompany) {
                    let curUsers = [];
                    for (const key in resData) {
                        curUsers.push({ ...resData[key] });
                    };
                    const companyNo = curUsers[0].companyNo;
                    response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json`,
                    );
        
                    const resDataCo = await response.json();
                    let curCompany = [];
                    if (resDataCo) {
                        for (const key in resDataCo) {
                            curCompany.push({
                            ...resDataCo[key],
                            firebaseID: key
                            })
                        };
                        if (curCompany[0].email === email || email === 'gina@softsense.co.za' || email === 'clinsys@twinsolutions.co.za' || email === 'gina@interfaces.co.za' ) {
                            const firebaseID = curUsers[0].firebaseID;
                            let userType = 4;
                            
                            const mainUser = true;
                            const modifiedDate = getCurrentDate();
                            if ((email === 'gina@softsense.co.za') || (email === 'clinsys@twinsolutions.co.za')) {
                                userType = 5;
                            }
                            // if (email === 'clinsys@twinsolutions.co.za') {
                                //     userType = 5;
                                // }
                            if (email === 'gina7.phillips@gmail.com'  || (email === 'checkpoint@twinsolutions.co.za')) {
                                userType = 5;
                            }
                            response = await fetch(
                            `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,
                            {
                                method: 'PATCH',
                                headers: {
                                'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                userType,
                                mainUser,
                                modifiedDate
                                })
                            });
        
                            response = await fetch(
                            `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
                            );
        
                            let resData = await response.json();
                            let fBKey = '';
                            for (let key in resData) {
                                fBKey = key;
                            }
        
                            response = await fetch(
                            `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}/${fBKey}.json`,
                            {
                                method: 'PATCH',
                                headers: {
                                'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                mainUser,
                                modifiedDate
                                })
                            });
                            response = await fetch(
                                `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,);
                            resData = await response.json();    
                            const curDBUser = { ...resData };
                
                            dispatch({
                                type: CURRENT_USER,
                                currentUser: curDBUser
                            });    
                        };
                    };
                };


            };
            dispatch({
                type: CHECK_USER_EXISTS,
                userStatus: userExists,
            });
            } 
        catch (error) {
            dispatch({
                type: USER_FAIL,
                error: error
            });
        }
    };
};


export const checkUserExistsOnly = (email) => {
    //for new companies forCompany = true

    console.log('in checkUserExistsOnly - email =  ', email)
    let userExists = false;
    if (email === undefined) {
        return
    };
    return async (dispatch) => {
        dispatch(loadingUser());
        try {
            let modEmail = String(email);
            modEmail = modEmail.replace(/\./g, "__dot__");
            modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
            let response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            );
            let resData = await response.json();
            if (resData) {
                userExists = true;
            };

            console.log('userExists = ', userExists)
            dispatch({
                type: CHECK_USER_EXISTS,
                userStatus: userExists,
            });
            } 
        catch (error) {
            dispatch({
                type: USER_FAIL,
                error: error
            });
        }
    };
};



export const getSelectedUser = (companyNo, firebaseID) => {

    console.log('in getSelectedUser - firebaseID = ', firebaseID)
    return async (dispatch) => {
        dispatch(loadingUser());
        try {
            let response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,
                );
                
            const resData = await response.json();

            if (resData.DOB === undefined) {
                const DOB = 'dd/mm/ccyy';
                const jobCategory = 'unknown';
                const district = 'unknown';
                const age = '0';
                response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            DOB,
                            jobCategory,
                            district,
                            age
                        })
                    });
            } else if (resData.age === undefined) {
                const age = '0';
                response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            age
                        })
                    });
            };

            const currentUser = { ...resData };
            currentUser.id = firebaseID;

            dispatch({
                type: CURRENT_USER,
                currentUser: currentUser
            });
        } catch (error) {
        dispatch({
            type: USER_FAIL,
            error: error
        });
        }
  };
};


export const loginUser = (email) => {
  return async (dispatch) => {
    dispatch(loadingUser());
    let modEmail = email.replace(/\./g, "__dot__");
    modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
    try {
        let response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            );
            
        let userFound = false;
        let resData = await response.json();
        let companyNo = '';
        let firebaseID = '';
        let updatedCurUser = {};

        if (resData) {
            let curUsers = [];
            for (const key in resData) {
                curUsers.push({ ...resData[key] });
            }
            companyNo = curUsers[0].companyNo;
            firebaseID = curUsers[0].firebaseID;
            if (companyNo === undefined) {
                companyNo = resData.companyNo;
                firebaseID = resData.firebaseID;
            }
            userFound = true;
        }
        if (userFound) {
            const responseFetch = await fetch(
            `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json?`,
            )

            if (!responseFetch.ok) {
                throw new Error(`Unable to connect to server! ${responseFetch.message}`);
            }
            const resData2 = await responseFetch.json();
            updatedCurUser = { ...resData2, id: firebaseID };
        };

        if (typeof updatedCurUser.mainUser !== undefined && (updatedCurUser.userType > 3)) {
            if (updatedCurUser.mainUser) {
                dispatch({ type: 'MAIN_USER' });
            }
        };

        dispatch({
            type: LOGIN_USER,
            currentUser: updatedCurUser
        });

        response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json`,
        );

        resData = await response.json();
        let curCompany = [];
        if (resData) {
            for (const key in resData) {
                curCompany.push({
                    ...resData[key],
                    firebaseID: key
                })
            }
        };
        const companyInfo = curCompany[0];

        dispatch({
            type: 'UPDATE_COMPANY',
            company: companyInfo
        });

    } catch (error) {
        dispatch({
            type: USER_FAIL,
            error: error
        });
    }
  };
};


export const updateMainUser = (email) => {
  return async (dispatch) => {
    dispatch(loadingUser());
    const mainUser = true;
    let modEmail = email.replace(/\./g, "__dot__");
    modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
    try {
      let response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
      );

      let resData = await response.json();
      let fBKey = '';
      for (let key in resData) {
        fBKey = key;
      }

      response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}/${fBKey}.json`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            mainUser
          })
        });

      response.json();

      dispatch({ type: 'MAIN_USER' });

    } catch (error) {
      dispatch({
        type: USER_FAIL,
        error: error
      });
    }
  };
};

function makeTwoDigits(number) {
    const numberString = `${number}`;
    if (numberString.length === 2) return number
    return `0${number}`
};

export const fileUpload = (companyNo, uploadDetails) => {
  return async (dispatch) => {
    dispatch(loadingUser());
    if (uploadDetails.length === 0) {
        dispatch({
          type: USER_FAIL,
          error: 'No details found to upload  \n Please check spelling of row 0 headings below\n Must be the same as the name in brackets '
        });
        return;   
    };
    for (let index = 0; index < uploadDetails.length; index++) {
        const currentUser = uploadDetails[index];
        const email = currentUser.email;
        const firstName = currentUser.firstName;
        const surname = currentUser.surname;
        const jobCategory = currentUser.jobCategory;
        const employeeNo = currentUser.employeeNo;
        const cellNo = currentUser.cellNo;
        const idNo = currentUser.idNo;
        let DOB = currentUser.DOB;
        const address = currentUser.address;
        const suburb = currentUser.suburb;
        const postalCode = currentUser.postalCode;
        const district = currentUser.district;
        const province = currentUser.province;
        const country = currentUser.country;
        let gender = currentUser.gender[0].toUpperCase() + currentUser.gender.substring(1);
        const userType = 1;
        const mainUser = false;
        const lastSurveyDate = '2020/01/01';
        const lastSurveyID = '2020/01/01';
        const createdDate = getCurrentDate();
        const modifiedDate = getCurrentDate();
        let altCompany = '00999999';
        let errMess = '';

        if (DOB === undefined) {
            errMess = 'NO data uploaded \n \n DOB not found  \n Please check spelling of row 0 headings below\n Must be the same as the name in brackets'
        }
        else if (DOB.length === undefined || (DOB.length < 10 )){
            errMess = 'NO data uploaded \n \n DOB format incorrect must be dd/mm/ccyy'
        };
        if (gender === undefined) {
            errMess = 'NO data uploaded \n \n gender not found  \n Please check spelling of row 0 headings below\n Must be the same as the name in brackets'
        };
        if (firstName === undefined) {
            errMess = 'NO data uploaded \n \n firstName not found  \n Please check spelling of row 0 headings below\n Must be the same as the name in brackets'
        };
        if (surname === undefined) {
            errMess = 'NO data uploaded \n \n surname not found \n Please check spelling of all row 0 headings below\n Must be the same as the name in brackets'
        };
        if (idNo === undefined) {
            errMess = 'NO data uploaded \n \n idNo not found \n Please check spelling of row 0 headings below\n Must be the same as the name in brackets'
        };

        if (errMess !== '') {
            dispatch({
              type: USER_FAIL,
              error: errMess
            });
            return;   
        };
      
        if (gender.length === 1 ) {
            if (gender === 'M') {
                gender = "Male"
                } else if (gender === 'F'){
                    gender = "Female"
                } else {gender = "Other"}
            };

        let slash = DOB.substr(2, 1);
        if (!isNaN(slash)) {
            const day = DOB.substr(8, 2);
            const month = DOB.substr(5, 2);
            const year = DOB.substr(0, 4);
            DOB = makeTwoDigits(day) + '/'+ makeTwoDigits(month) + '/' + year;
        };
        const age = calcAge(DOB);

        let modEmail = String(idNo);
        if (email && (email !== '')){modEmail = String(email)};
        modEmail = modEmail.replace(/\./g, "__dot__");
        modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
      try {
        let response = await fetch(
          `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
        );

        let userFound = false;
        let resData = await response.json();
        let company = '';
        let firebaseID = '';
        if (resData) {
            let curUsers = [];
            for (const key in resData) {
                curUsers.push({ ...resData[key] });
            }
            company = curUsers[0].companyNo;
            firebaseID = curUsers[0].firebaseID;
            userFound = true;
        }
        if (!userFound && email !== '') {
            modEmail = String(idNo).replace(/\//g, "__");
            modEmail = modEmail.replace(/\./g, "__dot__");
            modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            );
            if (resData) {
                let curUsers = [];
                for (const key in resData) {
                curUsers.push({ ...resData[key] });
                }
                company = curUsers[0].companyNo;
                firebaseID = curUsers[0].firebaseID;
                userFound = true;
            }
        }
        if (companyNo && company !== companyNo) {
            altCompany = company
        }
        let url = `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS.json`
        let curMethod = 'POST'
        if (userFound) {
            url = `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`
            curMethod = 'PATCH'
        };

        response = await fetch(
          url,
          {
            method: curMethod,
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email,
              firstName,
              surname,
              companyNo,
              jobCategory,
              employeeNo,
              cellNo,
              idNo,
              DOB,
              age,
              userType,
              mainUser,
              altCompany,
              address,
              suburb,
              postalCode,
              district,
              province,
              country,
              gender,
              lastSurveyDate,
              lastSurveyID,
              createdDate,
              modifiedDate
            })
          }
        );

        resData = await response.json();

        if (!userFound) {
          firebaseID = resData.name
          if ((email !== undefined) && email !== '') {
            modEmail = email.replace(/\./g, "__dot__");
          }
          response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                companyNo,
                firebaseID,
                firstName,
                surname,
                idNo,
                email,
                mainUser,
                createdDate
              })
            }
          );
        }

      } catch (error) {
        dispatch({
          type: USER_FAIL,
          error: error
        });
      }
    };
    dispatch({
      type: LOADING_USER_RESET,
    });
    dispatch({
      type: FILE_FINISH,
    });
  }
};


export const deleteUser = (companyNo, email, idNo, id) => {
  return async (dispatch) => {
    dispatch(loadingUser());
    try {
        let modEmail = String(idNo);
        if (email && (email !== '')){modEmail = String(email)};
        modEmail = modEmail.replace(/\./g, "__dot__");
        modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
        let response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
            {
            method: 'DELETE',
            }
        );
        await response.json();

        response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${id}.json?`,
            {
            method: 'DELETE',
            }
        );

        dispatch(fetchUsers(companyNo));

        } catch (error) {
            dispatch({
                type: USER_FAIL,
                error: error
            });
        }
    };
};