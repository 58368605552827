const getCurrentDate = () => {

    function makeTwoDigits(number) {
        const numberString = `${number}`;
        if (numberString.length === 2) return number
        return `0${number}`
    }

    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    makeTwoDigits(day);
    makeTwoDigits(month);
    makeTwoDigits(hours);
    makeTwoDigits(minutes);

    return makeTwoDigits(hours) + ':' + makeTwoDigits(minutes) + ' ' +
        makeTwoDigits(day) + '-' + makeTwoDigits(month) + '-' + year;//format: hh:mm dd-mm-yyyy;
}

export default getCurrentDate;
