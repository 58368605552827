import React from 'react';

const BodyTextBackground = ({children}) => (
    <div className="main-border">
        <div className="main">
        {children}
        </div>
    </div>
);

export default BodyTextBackground;
