import monthExport from '../modals/MonthExport'; 
import {
    CREATE_SURVEY,
    UPDATE_SURVEY,
    UPDATE_ACTIONS,
    GET_SURVEY,
    LOADING_SURVEY,
    FETCH_ALL_SURVEYS,
    MONTH_SURVEYS,
    WEEK_SURVEYS,
    LOAD_QUESTIONS,
    SURVEY_FAIL,
    SURVEY_COMPLETE,
} from './SurveyActions';


const initialState = {
    selectedSurveys: [],
    monthSurvey : [],
    weekSurvey : [],
    exportData: [],
    userDetails: {
        id: '',
        idNo: '',
        userEmail: '',
        firstName: '',
        surname: '',
        companyNo: '109999',
        loadedTime: '',
        loadedDate: '',
        declaration: false,
        temperature: 0,
        yesChecks: 0,
        colour: 'black',
    },
    checks: Array(15).fill(0),
    actions: Array(4).fill(0),
    questions: [{
        id: '',
        identifier: '',
        title: '',
        question: '',
        colour: 'black',
    }],
    maxDays: 0,
    loading: false,
    surveyComplete: false,
    error: null,
    // surveyUpdated: false,
};


export default (state = initialState, action) => {
    switch (action.type) {

        case LOADING_SURVEY:
            return {
                ...state,
                loading: true,
                error: null,
                // surveyUpdated: false,
            };
            
        case 'LOADING_SURVEY_RESET':
            return {
                ...state,
                loading: false,
                // surveyUpdated: false,
                surveyComplete: false,
        };
            
        case SURVEY_COMPLETE:
            return {
                ...state,
                surveyComplete: true,
        };
  
        case CREATE_SURVEY:
            const initChecks = Array(15).fill(0);
            return {
                ...state,
                userDetails: action.userDetails,
                checks: initChecks,
                loading: false,
            };

        case UPDATE_SURVEY:
            return {
                ...state,
                userDetails: action.userDetails,
                checks: action.todaysChecks,
                loading: false,
                surveyComplete: true,
            };

        case UPDATE_ACTIONS:
            return {
                ...state,
                actions: action.actions,
                loading: false,
            };

        case GET_SURVEY:
            return {
                ...state,
                userDetails: action.userDetails,
                checks: action.curChecks,
                loading: false,
            };

        case FETCH_ALL_SURVEYS:
            return {
                ...state,
                selectedSurveys: action.allSurveys,
                exportData: action.allSurveys,
                loading: false,
            };

        case WEEK_SURVEYS:
            return {
                ...state,
                weekSurveys: action.weekSurveys,
                exportData: action.weekSurveys,
                loading: false,
            };

        case MONTH_SURVEYS:
            let exportInfo = [];
            for (const key in action.allSurveys) {
                // const keyName = key[0].toUpperCase() + key.substring(1);
                let day31 = '';
                if (action.maxDays === 31) {
                    day31= action.allSurveys[key].result[30]
                }
                exportInfo.push(new monthExport(
                    action.allSurveys[key].id,
                    action.allSurveys[key].fullName, 
                    action.allSurveys[key].avgTemp, 
                    action.allSurveys[key].result[0],
                    action.allSurveys[key].result[1],
                    action.allSurveys[key].result[2],
                    action.allSurveys[key].result[3],
                    action.allSurveys[key].result[4],
                    action.allSurveys[key].result[5],
                    action.allSurveys[key].result[6],
                    action.allSurveys[key].result[7],
                    action.allSurveys[key].result[8],
                    action.allSurveys[key].result[9],
                    action.allSurveys[key].result[10],
                    action.allSurveys[key].result[11],
                    action.allSurveys[key].result[12],
                    action.allSurveys[key].result[13],
                    action.allSurveys[key].result[14],
                    action.allSurveys[key].result[15],
                    action.allSurveys[key].result[16],
                    action.allSurveys[key].result[17],
                    action.allSurveys[key].result[18],
                    action.allSurveys[key].result[19],
                    action.allSurveys[key].result[20],
                    action.allSurveys[key].result[21],
                    action.allSurveys[key].result[22],
                    action.allSurveys[key].result[23],
                    action.allSurveys[key].result[24],
                    action.allSurveys[key].result[25],
                    action.allSurveys[key].result[26],
                    action.allSurveys[key].result[27],
                    action.allSurveys[key].result[28],
                    action.allSurveys[key].result[29],
                    day31
                    ));
                };
            return {
                ...state,
                monthSurvey: action.allSurveys,
                exportData: exportInfo,
                maxDays: action.maxDays,
                loading: false,
            };
        
        case SURVEY_FAIL:  
        return {
            ...state,
            error: action.error,
            loading: false
            };
    
        case LOAD_QUESTIONS:
            return {
                ...state,
                questions: action.allQuestions,
                loading: false,
            };

        default:
            return state;
    }
};
