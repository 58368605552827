import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {connect} from "react-redux";

import {getAllRegUsers, fetchUsers, getSelectedUser, deleteUser} from '../dataStore/UserActions';
import { fetchCompanys } from '../dataStore/CompanyActions';
import {resetSurveyLoading} from '../dataStore/SurveyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import Spinner from '../components/UI/Spinner';
import getCurrentDate from '../components/CurrentDate';
import BodyTextBackground from './UI/BodyTextBackground';

const tick = '✓';
const modifiedDate = getCurrentDate();
const surveyDate = modifiedDate.slice(6, 16);

class RegisteredUsers extends Component {
    state = {
        deleteUserInd: false,
        coSelected: false,
        companys: [{
            companyNo: '100000',
            companyName: 'ALL',
          }],
        userCompany: '100000',
    };
    componentDidMount () {
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');   
        };
        if (this.props.loggedInUser.companyNo ) {
            this.props.getAllRegUsers();
            this.props.getAllCompanys();
        };
    };

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
    };
    
    userSelectedHandler = (id, companyNo) => {
        if (this.state.deleteUserInd) {
            this.setState({deleteUserInd : false});
            return;
        };
        console.log('in userSelectedHandlerid =', id, companyNo)
        this.props.getSelectedUser(companyNo,id);
        this.props.history.push( '/user' );
    };
    
    onChangeCompany = (event) => {
        if (event.target.value === 'ALL') {
            console.log('All found')
            this.props.getAllCompanys();
            this.setState({coSelected: false});
            this.setState({userCompany: ''});
        } else {
            this.props.getAllUsers(event.target.value);
            this.setState({coSelected: true});
            this.setState({userCompany: event.target.value});
        };
    };
  
    userDeleteHandler = (id, email, idNo) => {
        this.props.deleteUser(this.props.loggedInUser.companyNo, email, idNo, id);
        this.setState({deleteUserInd : true});
        this.props.history.push( '/users' );
    };    

    render() {  

        const {curUsers} = this.props;
        let allUsersList = <Spinner />;
        if ( !this.props.loading ) {

            allUsersList = curUsers.length ? (
                curUsers.map(user => {
                    let userSurvey = '-'
                    if (surveyDate=== user.lastSurveyDate) {
                        userSurvey =tick
                    }
                    return (
                        <div className="list-wrapper" key={user.id} >
                        <div className="user-items" >
                            <div id="user-name" >
                                <Link to={'/user/' + user.id} style={{ textDecoration: 'none' }}>
                                    <div className="user-column"
                                            key={user.id} 
                                            onClick={() => {this.userSelectedHandler(user.id, user.companyNo)}}>
                                        <p className="user-name">{user.firstName}</p>
                                        <p className="user-name">{user.surname} </p>
                                        <p className="user-name">{user.cellNo} </p>
                                    </div>
                                </Link>
                            </div>
                            <div className="user-survey" >
                                <p >{userSurvey}</p>
                            </div>
                            <div className="verticalcenter">
                                <img src="../images/delete-icon.png" alt="delete" 
                                    width="20" height="25"
                                    onClick={() => {this.userDeleteHandler(user.id, user.email, user.idNo)}}></img>
                            </div>
                        </div>
                        </div>
                    )
                })
            ) : (
                <p className="center">There are no users registered for this company</p>
                );
        }

        const curCompanys =  this.state.companys.concat(this.props.curCompanys);

        let companyList = curCompanys.length > 0
            && curCompanys.map((item, i) => {
                return (
                    <option key={item.companyNo} value={item.companyNo}>{item.companyName}</option>
                    )
                }, this);

    return (
        <div className="wrapper">
            <div className="containerFlex  main-wrapper">
                <div className="filler">
                </div>
                <BodyTextBackground>  
                    <p className="h3">All Registered Users</p>
                    <select onChange={this.onChangeCompany}>
                        {companyList}
                    </select>
                    {allUsersList}
                </BodyTextBackground>
                {/* <Survey/> */}

                <div className="filler">
                </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        curUsers: state.userData.availableUsers,
        loggedInUser: state.userData.loggedInUser,
        loading: state.userData.loading,
        companyDetails : state.companyData.companyDetails,
        curCompanys: state.companyData.allCompanys,
        // surveyUpdated: state.surveyData.surveyUpdated
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAllRegUsers: () => dispatch(getAllRegUsers()),
    getSelectedUser: (company, id) => dispatch(getSelectedUser(company, id)), 
    deleteUser: (company, email, idNo, id) => dispatch(deleteUser(company, email, idNo, id)), 
    resetSurveyLoading: () => dispatch(resetSurveyLoading()), 
    getAllCompanys: () => dispatch(fetchCompanys()),
    getAllUsers: (company) => dispatch(fetchUsers(company)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);
