import React, { Component } from "react";
import DatePicker from 'react-datepicker';
import { connect } from "react-redux";

import { weekSurveys, updateActions } from '../dataStore/SurveyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import '../components/users/Survey.css';
import Spinner from '../components/UI/Spinner';
import convertDate from '../components/ConvertDate';
import BodyTextBackground from './UI/BodyTextBackground';

class Survey extends Component {
    state = {
        loadedChecks: false,
        index : 0,
        startDate: new Date(),
        surname: 'today!',
        firstName: 'Nobody',
        id: '',
        check1: 0,
        check2: 0,
        check3: 0,
        check4: 0,
        check5: 0,
        check6: 0,
        check7: 0,
        check8: 0,
        check9: 0,
        check10: 0,
        check11: 0,
        check12: 0,
        check13: 0,
        check14: 0,
        check15: 0,
        action1: 0,
        action2: 0,
        action3: 0,
        action4: 0,
        consent: 0,
        message: '',
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');
        };
        if (this.props.loggedInUser.companyNo ) {
            const surveyDate = convertDate(this.state.startDate);
            this.props.getAllSymptoms(this.props.loggedInUser.companyNo, surveyDate);
        };
    };

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (!this.props.loadingSurveys && !this.state.loadedChecks && (this.props.currentSurveys.length > 0)) {
            const formDetails = {
                ...this.props.currentSurveys[this.state.index]
            };
            this.setState({index : this.state.index+1});
            for (const elementIdentifier in formDetails) {
                this.setState(state => {
                    state[elementIdentifier] = formDetails[elementIdentifier]
                    return state
                    });
                };
            this.setState({ loadedChecks: true });
        };
    };
  
    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.checked  });
    };

    handleChangeDate = date => {
        this.setState({
            startDate: date,
        });
        const surveyDate = convertDate(date);
        const fileName = this.props.companyDetails.companyName + '-' + surveyDate;
        this.setState({ fileName: fileName })
        this.props.getAllSymptoms(this.props.loggedInUser.companyNo, surveyDate)
    }

    handleNext = (event) => {
        event.preventDefault();
        const actionInfo = Array(4).fill(0);
        actionInfo[0] = this.state.action1;
        actionInfo[1] = this.state.action2;
        actionInfo[2] = this.state.action3;
        actionInfo[3] = this.state.action4;
        const consent = this.state.consent;
        
        const surveyDate = convertDate(this.state.startDate);
        this.props.updateActions(this.props.loggedInUser.companyNo, surveyDate, this.state.id, actionInfo, consent);
        
        if (this.props.currentSurveys.length  === 1) {
            return;
        }

        this.setState({ action1 : 0 });     
        this.setState({ action2 : 0 });
        this.setState({ action3 : 0 });
        this.setState({ action4 : 0 });
        this.setState({ consent : 0 });
        this.setState({ check3 : 0 });     
        this.setState({ check4 : 0 }); 
        this.setState({ check5 : 0 });  
        this.setState({ check6 : 0 });   
        this.setState({ check7 : 0 });  
        this.setState({ check8 : 0 });   
        this.setState({ check9 : 0 }); 
        this.setState({ check10 : 0 }); 
        this.setState({ check11 : 0 }); 
        this.setState({ check12 : 0 }); 
        this.setState({ check13 : 0 }); 
        this.setState({ check14 : 0 }); 
        this.setState({ check15 : 0 });

        if (this.state.index < this.props.currentSurveys.length) {
            const formDetails = {
                ...this.props.currentSurveys[this.state.index]
            };
        if (this.state.index === this.props.currentSurveys.length - 1 || (this.state.index === this.props.currentSurveys.length)) {
                this.setState({index : 0}); 
            } else {
                this.setState({index : this.state.index+1});}
            for (const elementIdentifier in formDetails) {
                this.setState(state => {
                    state[elementIdentifier] = formDetails[elementIdentifier]
                    return state
                    })
                };   
            this.setState({ loadedChecks: true });
        };
    };

  render() {
    let dateArea = null;  
    if (!this.props.loadingSurveys) {
        dateArea = (
            <div className="date"   >
            <h3 className="centred" id='titledate'>Click to Change Date:</h3>
                <DatePicker className="date-picker"
                    selected={this.state.startDate}
                    onChange={e => this.handleChangeDate(e)}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        );
    };

    let form = (
        <div className="flexGrow">
            <p className="h3">Action for {this.state.firstName} {this.state.surname}</p>
            <br></br>
            <div  className="NIOH-columns">
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check3 ? 'red' : 'white'}} 
                    id="check3">Fever</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check4 ? 'red' : 'white'}}
                    id="check4">Chills</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check5 ? 'red' : 'white'}}
                    id="check5">Dry cough</label>
                </div>
            </div>
            <div className="NIOH-columns">
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check6 ? 'red' : 'white'}}
                    id="check6">Sore throat</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check7 ? 'red' : 'white'}}
                    id="check7">Shortness of Breath</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check8 ? 'red' : 'white'}}
                    id="check8">Tiredness</label>
                </div>
            </div>
            <div className="NIOH-columns">
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check9 ? 'red' : 'white'}}
                    id="check9">Loss of Smell or Taste</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check10 ? 'red' : 'white'}}
                    id="check10">Conjuctivitis</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check11 ? 'red' : 'white'}}
                    id="check11">Diarrhoea</label>
                </div>
            </div>
            <div className="NIOH-columns">
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check12 ? 'red' : 'white'}}
                    id="check12">Muscle pain</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check13 ? 'red' : 'white'}}
                    id="check13">Nausea or Vomiting</label>
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check14 ? 'red' : 'white'}}
                    id="check14">Dizziness</label>
                </div>
            </div>
            <div className="NIOH-columns">
                <div className="survey-field-NIOH">
                </div>
                <div className="survey-field-NIOH">
                    <label style={{color: this.state.check15 ? 'red' : 'white'}}
                    id="check15">Headaches</label>
                </div>
                <div className="survey-field-NIOH">
                </div>
            </div>
            <br></br>
            <div className="survey-field white">
                <label className="alignRHS" htmlFor="action1">Screening Outcome -no action required</label>
                <input className="alignRHS" type="checkbox" id='action1' 
                checked={this.state.action1} onChange={this.handleChange} />
            </div>
            <div className="survey-field white">
                <label className="alignRHS" htmlFor="action2">Screening Outcome -referred for self-isolation</label>
                <input className="alignRHS" type="checkbox" id='action2' 
                checked={this.state.action2} onChange={this.handleChange} />
            </div>
            <div className="survey-field white">
                <label className="alignRHS" htmlFor="action3">Screening Outcome -referred for testing</label>
                <input className="alignRHS" type="checkbox" id='action3' 
                checked={this.state.action3} onChange={this.handleChange} />
            </div>
            <div className="survey-field white">
                <label className="alignRHS" htmlFor="action4">Screening Outcome-referred to the doctor</label>
                <input className="alignRHS" type="checkbox" id='action4' 
                checked={this.state.action4} onChange={this.handleChange} />
            </div>
            <div className="survey-field white">
                <label className="alignRHS" htmlFor="consent">Consent given to submit symptoms to NIOH</label>
                <input className="alignRHS" type="checkbox" id='consent' 
                checked={this.state.consent} onChange={this.handleChange} />
            </div>
            <div className="btn-containerFlex">
                <button className="btnPrimary">Next</button>
            </div>
            <p className="message"></p>
        </div>
    )

    if (this.state.firstName === 'Nobody'){
        form = (
            <div className="flexGrow">
                <p className="h3">Action for {this.state.firstName} {this.state.surname}</p>
            </div>
        )
    };

    if (this.props.loadingSurveys) {
        form = <Spinner />
    }

    return (
        <div className="flexGrow">
            <h1>In Survey</h1>
            <div className="wrapper">
                <div className="containerFlex  main-wrapper">
                    <div className="filler">
                    </div>
                    <BodyTextBackground>
                        <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>  
                        <div className="centred" >
                            {dateArea}
                        </div> 
                        <form className="form" onSubmit={this.handleNext}>
                            {form}
                        </form>
                        <h4 className="red" >{this.state.message}</h4>
                    </BodyTextBackground>
                    <div className="filler">
                    </div>
                </div>
            </div>
        </div>
    );
  }
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        currentUser: state.userData.currentUser,
        loggedInUser: state.userData.loggedInUser,
        loadingSurveys: state.surveyData.loading,
        currentSurveys: state.surveyData.selectedSurveys,
        questions: state.surveyData.questions,
        companyDetails : state.companyData.companyDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateActions: (currentUser, surveyDate, surveyID, actionInfo, consent) => dispatch(updateActions(currentUser, surveyDate, surveyID, actionInfo, consent)),
    getAllSymptoms: (company, startDate) => dispatch(weekSurveys(company, startDate)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
