import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../components/UI/Spinner';
import '../Form.css';
import Input from '../../components/UI/Input';
import {
    clearCurrentUser, clearLoginUser, createUser, updateLoginUser,
    checkUserExistsOnly, loginUser, getSelectedUser,
} from '../../dataStore/UserActions';
import { auth, verifyEmail, setDisplayName, logoutUser, } from '../../dataStore/AuthActions';
import { getCompany } from '../../dataStore/CompanyActions';
import BodyTextBackground from '../UI/BodyTextBackground';

let invalidMess = '';

class SignUp extends Component {
    state = {
        gender: '',
        userType: '1',
        signUpForm: {
            companyNo: {
                label: 'Clinsys Check Point Unique company Number: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Clinsys Check Point Unique company Number'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                    maxLength: 8,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            idNo: {
                label: 'ID No/Passport No: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'ID number - 13 digits'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 13,
                    maxLength: 20,
                },
                valid: false,
                touched: false
            },
            firstName: {
                label: 'First Name/s: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your first name/s'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            surname: {
                label: 'Surname: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your last name (surname)'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            email: {
                label: 'E-mail address: *',
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Your login email address'
                },
                value: '',
                validation: {
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            userID: {
                label: 'Password: *',
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password - this will be required for logging in'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                },
                valid: false,
                touched: false
            },
            DOB: {
                label: 'Date of Birth: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Date of Birth - DD/MM/CCYY'
                },
                value: '',
                validation: {
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            cellNo: {
                label: 'Mobile Phone:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Mobile number - 10 digits'
                },
                value: '',
                validation: {
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            address: {
                label: 'Street address:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Street address'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
            suburb: {
                label: 'Suburb:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Suburb'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
            postalCode: {
                label: 'Postal Code:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Postal Code'
                },
                value: '',
                validation: {
                    minLength: 4,
                    maxLength: 4,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            employeeNo: {
                label: 'Employee Number:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Employee Number'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
        },
        formIsValid: false,
        genderUpdated: false,
        infoAdded: false,
        gotDetails: false,
        idChecked: false,
        enterID: false,
        exitID: false,
        submitForm: false,
        coError: null,
        coNoChecked: ''
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.clearCurrentUser();
        this.props.clearLoginUser();
        this.props.clearAuth();
        invalidMess = '';
    }

    componentDidUpdate() {
        if (this.props.authLoading || this.props.loadingCompany || this.props.loadingUser) {
            return
        };
        if (this.props.companyDetails.companyNo === this.state.signUpForm.companyNo.value) {
            if (this.state.coError !== null) {
                this.setState({ coError: null});
            };
        };
        if (this.state.signUpForm.companyNo.value.length > 6 && (this.state.signUpForm.companyNo.value !== this.state.coNoChecked)) {
            if (this.props.companyDetails.companyNo !== this.state.signUpForm.companyNo.value) {
                this.props.getCompany(this.state.signUpForm.companyNo.value.trim());
                this.setState({ coError: 'Clinsys unique number not valid'});
                this.setState({ coNoChecked: this.state.signUpForm.companyNo.value});
            } else {
                this.setState({ coError: null});
            };
        };
        if (!this.state.idChecked && this.props.userExists) {
            this.setState({ idChecked: true });
            this.props.loginUser(this.state.signUpForm.idNo.value);
        };
        if (!this.state.gotDetails && (this.state.idChecked) && (this.props.loggedInUser.firebaseID)) {
            this.setState({ gotDetails: true });
            this.props.getSelectedUser(this.props.loggedInUser.companyNo, this.props.loggedInUser.firebaseID);
        };
        if (!this.state.infoAdded && (this.state.gotDetails) && (this.props.currentUser.surname !== '')) {
            this.setState({ infoAdded: true });
            const userDetails = this.props.currentUser;
            const formDetails = {
                ...this.state.signUpForm
            };
            for (let formElementIdentifier in userDetails) {
                const stringArray = ["email", "firstName", "surname", "DOB", "cellNo", "address", "suburb", "postalCode", "companyNo", "employeeNo"];
                const process = (stringArray.indexOf(formElementIdentifier) > -1)
                if (process) {
                    formDetails[formElementIdentifier].value = userDetails[formElementIdentifier];
                    formDetails[formElementIdentifier].valid = true;
                };
            };

            this.setState({ gender: userDetails.gender });
            this.setState({ userType: String(userDetails.userType) });
            this.setState({ signUpForm: formDetails });
        };
        if (this.state.submitForm && !this.props.authError) {
            const currentUser = {};
            for (let formElementIdentifier in this.state.signUpForm) {
                currentUser[formElementIdentifier] = this.state.signUpForm[formElementIdentifier].value;
            };
            currentUser.gender = this.state.gender;
            currentUser.mainUser = false;
            currentUser.userType = this.state.userType;
            if (this.props.companyDetails.coEmail === currentUser.email) {
                currentUser.userType = 4;
                currentUser.mainUser = true;
            };
            if (this.props.loggedInUser.firebaseID) {
                currentUser.firebaseID = this.props.loggedInUser.firebaseID;
            };
            this.props.setDisplayName(this.props.tokenId, currentUser.firstName);
            setTimeout(() => {
                this.props.verifyEmail(this.props.tokenId);
            }, 2000);
            this.props.getCompany(currentUser.companyNo)
            this.props.updateLoginUser(currentUser);
            this.props.createUser(currentUser);
            this.props.clearAuth();
            this.props.history.replace('/userReg');
        };
    };

    signInHandler = (event) => {
        event.preventDefault();

        if (this.props.companyDetails.companyNo !== this.state.signUpForm.companyNo.value) {
            this.setState({ coError: 'Clinsys unique number not valid'});
            return;
        } else {
            this.setState({ coError: null});
        };
        this.setState({ submitForm: true })
        this.props.signUp(this.state.signUpForm.email.value, this.state.signUpForm.userID.value);
    }

    checkValidity(name, value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        };

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        };

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        };

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^[/\d-+]+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        this.setState({ submitForm: false })
        const updatedsignUpForm = {
            ...this.state.signUpForm
        };
        if (inputIdentifier === 'idNo' && (!this.state.enterID)) {
            this.setState({ enterID: true });
        };
        if (inputIdentifier !== 'idNo' && (this.state.enterID) && (!this.state.exitID)) {
            this.setState({ exitID: true });
            this.props.checkUserExists(this.state.signUpForm.idNo.value)
        };
        const updatedFormElement = {
            ...updatedsignUpForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.name, updatedFormElement.value, updatedFormElement.validation);
        if (event.target.value !== '') {
            updatedFormElement.touched = true;
        } else {
            updatedFormElement.touched = false;
            const stringArray = ["cellNo", "address", "suburb", "postalCode", "employeeNo"];
            const process = (stringArray.indexOf(inputIdentifier) > -1)
            if (process) {
                updatedFormElement.valid = true;
            };
        };
        updatedsignUpForm[inputIdentifier] = updatedFormElement;

        let formIsValid = this.state.genderUpdated;
        for (let inputIdentifier in updatedsignUpForm) {
            formIsValid = updatedsignUpForm[inputIdentifier].valid && formIsValid;
        };
        this.setState({ signUpForm: updatedsignUpForm, formIsValid: formIsValid });
    };

    onChangeRadio = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        if (name === 'gender') {
            this.setState({ genderUpdated: true });
        }
    }


    render() {
        const formElementsArray = [];
        for (let key in this.state.signUpForm) {
            formElementsArray.push({
                id: key,
                config: this.state.signUpForm[key]
            });
        }
        let gender = (
            <Fragment>
                <div className="radioTop" onChange={this.onChangeRadio}>
                    <input type="radio" value="Male" name="gender" className="radioSpace" /> Male
                    <input type="radio" value="Female" name="gender" className="radioSpace" /> Female
                    <input type="radio" value="Other" name="gender" className="radioSpace" /> Other  *
                </div>
            </Fragment>
        )

        let form = (
            <form onSubmit={this.signInHandler}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        label={formElement.config.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        cbLabel={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
                <div className="btn-containerFlex">
                    <button className="btnPrimary" disabled={!this.state.formIsValid}>Submit</button>
                </div>
            </form>
        );

        let errorMessage = this.props.authError;
        if (invalidMess !== '') {
            errorMessage = invalidMess;
            form = null;
        };

        if (this.props.authLoading) {
            form = <Spinner />;
        };

        return (
            <div className="wrapper">
                <div className="containerFlex  main-wrapper">
                    <div className="filler">
                    </div>
                    <BodyTextBackground>
                        <h2>Sign Up</h2>
                        <h3>Please enter your Details</h3>
                        <div className="label-left-black">
                            <p>*Required field</p>
                        </div>
                        <div className="error-text red">
                            <p>{this.state.coError}</p>
                        </div>
                        {gender}
                        {form}
                        <div className="error-text red">
                            {errorMessage ? <p>{errorMessage}</p> : null}
                        </div>
                    </BodyTextBackground>
                    <div className="filler">
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        authLoading: state.authData.loading,
        loadingUser: state.userData.loading,
        loadingCompany: state.companyData.loading,
        authError: state.authData.error,
        tokenId: state.authData.token,
        userExists: state.userData.userExists,
        currentUser: state.userData.currentUser,
        companyDetails: state.companyData.companyDetails,
        loggedInUser: state.userData.loggedInUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearCurrentUser: () => dispatch(clearCurrentUser()),
        clearLoginUser: () => dispatch(clearLoginUser()),
        signUp: (email, password) => dispatch(auth(email, password, true)),
        verifyEmail: (tokenId) => dispatch(verifyEmail(tokenId)),
        setDisplayName: (email, name) => dispatch(setDisplayName(email, name)),
        createUser: (currentUser) => dispatch(createUser(currentUser)),
        checkUserExists: (email) => dispatch(checkUserExistsOnly(email)),
        updateLoginUser: (currentUser) => dispatch(updateLoginUser(currentUser)),
        loginUser: (email) => dispatch(loginUser(email)),
        getCompany: (companyNo) => dispatch(getCompany(companyNo)),
        getSelectedUser: (company, id) => dispatch(getSelectedUser(company, id)),
        clearAuth: () => dispatch(logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);