import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import getCurrentDate from '../components/CurrentDate';
import {clearCurrentUser}  from '../dataStore/UserActions';
import Spinner from '../components/UI/Spinner';
import { createSurvey, getSurvey, resetSurveyLoading, surveyComplete } from '../dataStore/SurveyActions';
import BodyTextBackground from './UI/BodyTextBackground';

let colourTemp = 'white';

class SurveyComplete extends Component {

    componentDidMount() {
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');
        };
        colourTemp = 'white';
    }
    
    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if ((this.props.loadingSurvey) || (this.props.loadingUser)) {
            return; 
        };
        const curDate = getCurrentDate();
        const surveyDate = curDate.slice(6, 16);
        // console.log('this.props.currentUser = ', this.props.currentUser)
        // console.log('this.props.lastSurvey.firstName = ', this.props.lastSurvey.firstName)
        if (this.props && (this.props.currentUser.firstName !== this.props.lastSurvey.firstName)) {
            this.props.getSurvey(this.props.currentUser.companyNo, surveyDate, this.props.currentUser.lastSurveyID)
        };
        let temperature = this.props.lastSurvey.temperature;
        if (temperature >= 37.5) {
            colourTemp = 'red'
        };
        if ((this.props.lastSurvey.surname === this.props.currentUser.surname) && 
            (this.props.currentUser.lastSurveyDate === surveyDate) && (this.props.lastSurvey.temperature > 0)) {
            this.props.surveyComplete();
        }else  if ((this.props.lastSurvey.companyNo !== '109999' ) && 
                    (this.props.lastSurvey.loadedDate === surveyDate) && 
                    (this.props.currentUser.lastSurveyDate === surveyDate) && (this.props.lastSurvey.temperature === 0)) {
            this.props.history.replace("/survey");
        }else  if (this.props.lastSurvey.companyNo !== '109999') {
            this.props.createSurvey(this.props.currentUser);
            this.props.history.replace("/survey");
        };
    };


    handleSubmit = () => {
        if (this.props.loggedInUser.mainUser) {
            this.props.history.replace('/users');
        } else {
            this.props.history.replace('/info');
        }
    }

    render() {

        let form = <Spinner />;
        if (!this.props.loadingSurvey) {
            form = (
                <Fragment>
                    <h1>{this.props.lastSurvey.firstName} {this.props.lastSurvey.surname}</h1>
                    <h1>has successfully completed the Survey</h1>
                    <h2 className='yellow h3'>{this.props.lastSurvey.loadedTime}</h2>
                    <h2 className='yellow h3'>{this.props.lastSurvey.loadedDate}</h2>
                    <h2 className={colourTemp}>temperature {this.props.lastSurvey.temperature}°C</h2>
                    <h2 style={{ color: this.props.lastSurvey.colour }}>No of symptoms {this.props.lastSurvey.yesChecks} </h2>
                    <div className="btn-containerFlex">
                        <button className="btnPrimary" onClick={this.handleSubmit}>OK</button>
                    </div>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <div className="wrapper">
                    <div className="containerFlex  main-wrapper">
                        <div className="filler">
                        </div>
                        <BodyTextBackground>
                            <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>   
                            <div className="form" >
                                {form}
                            </div>
                        </BodyTextBackground>
                        <div className="filler">
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        currentUser: state.userData.currentUser,
        surveyChecks: state.surveyData.checks,
        loadingSurvey: state.surveyData.loading,
        loadingUser: state.userData.loading,
        lastSurvey: state.surveyData.userDetails,
        surveyComplete: state.surveyData.surveyComplete,
        loggedInUser: state.userData.loggedInUser,
        companyDetails : state.companyData.companyDetails,
    };
};


const mapDispatchToProps = (dispatch) => ({
    resetSurveyLoading: () => dispatch(resetSurveyLoading()),
    surveyComplete: () => dispatch(surveyComplete()),
    createSurvey: (currentUser) => dispatch(createSurvey(currentUser)),
    getSurvey: (companyNo, surveyDate, LastSurveyID) =>
        dispatch(getSurvey(companyNo, surveyDate, LastSurveyID)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyComplete);
