import {
    AUTH_LOADING,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
    CHANGED_PASSWORD,
    SET_AUTH_REDIRECT_PATH,
    ACCOUNT_INFO,
} from './AuthActions';

const initialState = {
    token: null,
    loggedIn: null,
    userId: null,
    displayName: null,
    error: null,
    emailVerified: null,
    loading: false,
    changedPassword: false,
    authRedirectPath: '/survey',
    mainUser: false
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case AUTH_LOADING: 
            return {
            ...state,
            loading: true,
            error: null,
            };
 
        case 'LOADING_AUTH_RESET':
            return {
                ...state,
                emailVerified: null,
                loading: false,
            };    
 
        case 'MAIN_USER':
            return {
                ...state,
                mainUser: true,
            };   

        case AUTH_SUCCESS: 
        return {
            ...state,
            token: action.idToken,
            loggedIn: action.loggedIn,
            userId: action.userId,
            displayName: action.displayName,
            emailVerified: null,
            error: null,
            loading: false
            };

        case AUTH_FAIL:  
        return {
            ...state,
            error: action.error,
            loading: false
            };

        case AUTH_LOGOUT:  
        return {
            ...state,
            token: null, 
            loggedIn: null, 
            userId: null,
            error: null,
            loading: false,
            mainUser: false, 
            changedPassword: false,
            };

        case CHANGED_PASSWORD: 
        return {
            ...state,
            changedPassword: true,
            };

        case SET_AUTH_REDIRECT_PATH:  
        return {
            ...state,
            authRedirectPath: action.path 
            };

        case ACCOUNT_INFO:  
        // let error = null;
        // if (!action.emailVerified) {
        //     error = 'Please verify your email address'
        // };
        return {
            ...state,
            // error: error, 
            emailVerified: action.emailVerified 
            };

        default:
            return state;
    }
};

export default reducer;
