import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {connect} from "react-redux";

import {fetchCompanys, getCompany} from '../dataStore/CompanyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import Spinner from './UI/Spinner';
import BodyTextBackground from './UI/BodyTextBackground';

// import getCurrentDate from '../components/CurrentDate';

// const tick = '✓';
// const modifiedDate = getCurrentDate();
// const currectDate = modifiedDate.slice(6, 16);


const sortBlocksAscending  = async (a, b) => {
    const a1 = a.blockNo + a.companyName + a.companyNo ;
    const b1 = b.blockNo + b.companyName + b.companyNo ;
    if (a1 < b1) return -1;
    else if (a1 > b1 ) return 1;
    else return 0;
};

class BlocksAll extends Component {
    state = {
        companys: [],
        dataUpdated : false,
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        this.props.getAllCompanys();
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');   
        }
    }
    async componentDidUpdate () {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (!this.state.dataUpdated){
            if (!this.props.loadingCo &&  (this.props.curCompanys.length > 0)) {
                const curcompanys = [];
                for (let index = 0; index < this.props.curCompanys.length; index++) {
                    const element = this.props.curCompanys[index];
                    if (element.blockNo === undefined) {
                        continue;
                    };
                    if (element.companyType !== 'Alone') {
                        curcompanys.push(element);
                    };
                };
                try {
                    await curcompanys.sort(sortBlocksAscending);
                } catch (error) {
                    console.log('error = ', error)
                }
                this.setState({dataUpdated: true})
                this.setState({ companys: [...curcompanys] })
            }
        };
    }
    

    companySelectedHandler = (companyNo) => {
        console.log('selected company -  = ','company = ', companyNo)
        console.log('history = ', this.props.history)
        this.props.getCompany(companyNo);
        this.props.history.replace('/updateCo');
    }

    onChangeRadio = (event) => {
        const { name, value } = event.target;
        this.setState({[name]: value });
    }
  

    render() {  
        
        let allcompanysList = <Spinner />;
        if ( !this.props.loadingCo ) {
            console.log('this.state.companys = ', this.props.loadingCo, this.state.companys.length)
            allcompanysList = this.state.companys.length ? (

                this.state.companys.map(company => {
                    return (         
                        <div className="list-wrapper " key={company.id}  >
                        <div className="user-items" >
                            <div id="user-name" >
                                <Link to={'/payment'} style={{ textDecoration: 'none' }}>
                                    <div className="user-column" 
                                            key={company.id} 
                                            onClick={() => {this.companySelectedHandler(company.companyNo)}}>
                                        <p className="user-name">{company.companyName}</p>
                                        <p className="user-name">{company.address} </p>
                                        <p className="user-name">{company.suburb} </p>
                                        <p className="user-name">{company.contactFirstName} </p>
                                        <p className="user-name">{company.cellNo} </p>
                                        <p className="user-name">{company.createdDate} </p>
                                        <p className="user-name">{company.paymentDate} </p>
                                        <p className="user-name">{company.status} </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        </div>
                    )
                })
            ) : (
                <p className="center">You have no companies!</p>
            );
        }


    return (
        <div className="wrapper">
            <div className="containerFlex  main-wrapper">
                <div className="filler">
                </div>
                <BodyTextBackground>
                    <p className="h3">All block companys</p>
                    {allcompanysList}
                </BodyTextBackground>
                <div className="filler">
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authData.emailVerified !== null,
    curCompanys: state.companyData.allCompanys,
    loadingCo: state.companyData.loading,
    loggedInUser : state.userData.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCompanys: () => dispatch(fetchCompanys()),
  getCompany: (company) => dispatch(getCompany(company)), 
  clearCurrentUser: () => dispatch(clearCurrentUser()), 
});

export default connect(mapStateToProps, mapDispatchToProps)(BlocksAll);
