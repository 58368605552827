import React, { Component,  } from 'react';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';

import { getCompanyMonthlyStats } from '../dataStore/CompanyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import Spinner from './UI/Spinner';
import convertDate from '../components/ConvertDate';
import getMonth from '../components/GetMonth';
import ExportToExcel from '../components/ExportToExcel';
import './users/SurveyResults.css'
import '../components/Form.css'

class StatsMonth extends Component {
    state = {
        companys: [],
        companyType: 'All',
        dataUpdated: false,
        startDate: new Date(),
        fileName: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');
        };
        if (this.props.loggedInUser.companyNo ) {
            const month = getMonth(this.state.startDate);
            const year = this.state.startDate.getFullYear();
            const fileName = 'Monthly Company Survey-' + year + '-' + month;
            this.setState({ fileName: fileName });
            const surveyDate = convertDate(this.state.startDate);
            this.props.getCompanyMonthlyStats(this.state.companyType, surveyDate);
        };
    };

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
    };

    handleChangeDate = date => {
        this.setState({
            startDate: date,
        });
        const surveyDate = convertDate(date);
        const month = getMonth(date);
        const year = date.getFullYear();
        const fileName = 'Monthly Company Survey-' + year + '-' + month;
        this.setState({ fileName: fileName });
        this.props.getCompanyMonthlyStats(this.state.companyType, surveyDate);
    };

    onChangeRadio = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        const surveyDate = convertDate(this.state.startDate);
        this.props.getCompanyMonthlyStats(event.target.value, surveyDate);
    }


    renderTableData() {
        if (this.props.companyMonthlyStats && !this.props.coLoading) {
            return this.props.companyMonthlyStats.map((survey, s) => {
                const surveyResult = survey.result
                const surResults = Object.values(surveyResult).map((c, i) => {
                    return <td key={i} >{c}</td>
                })
                return (
                    <tr key={s}>
                        <td className="width120">{survey.coName}</td>
                        <td className="width50">{survey.status}</td>
                        {surResults}
                    </tr>)
            });
        };
    };

    renderTableHeader() {
        if (this.props.companyMonthlyStats.length === 0) {
            return null
        } else
            if (this.props.companyMonthlyStats && !this.props.coLoading) {
                let header = ['Company name', 'Status',];
                for (let i = 1; i < this.props.maxDays + 1; ++i) {
                    ;
                    header.push(`${i}`);
                };
                return header.map((colTitle, index) => {
                    return <th key={index}>{colTitle}</th>
                });
            };
    };

    render() {
        let dateArea = <Spinner />;

        if (!this.props.coLoading) {
            dateArea = (
                <div className="date">
                    {/* <label>Select Date: </label> */}
                    <h2 className="centred" id='titledate'>Click box to Select Month:</h2>
                    <DatePicker className="date-picker"
                        selected={this.state.startDate}
                        onChange={e => this.handleChangeDate(e)}
                        name="startDate"
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                    />
                </div>
            );
        };

        let surveyMonthTable = null;
        if (!this.props.coLoading) {
            surveyMonthTable = (
                <tbody>
                    <tr>{this.renderTableHeader()}</tr>
                    {this.renderTableData()}
                </tbody>
            );
        }

        let message = null;
        if (this.props.companyMonthlyStats.length === 0) {
            message = <p className="message">No results - Must be the weekend!!</p>
        }

        let companyType = (
            <div className="radioTop" onChange={this.onChangeRadio}>
                <input type="radio" value="All" defaultChecked name="companyType"
                    onChange={this.onChangeRadio} className="radioSpace" /> ALL Companies
                <input type="radio" value="Alone" name="companyType"
                    onChange={this.onChangeRadio} className="radioSpace" /> Stand Alone
                <input type="radio" value="Owner" name="companyType"
                    onChange={this.onChangeRadio} className="radioSpace" /> Unit/Block Owner
                <input type="radio" value="Unit" name="companyType"
                    onChange={this.onChangeRadio} className="radioSpace" /> Part of Unit/Office Block
            </div>
        )

        return (
            <div className="flexGrow">
                <div className="right" >
                    <ExportToExcel csvData={this.props.exportData} fileName={this.state.fileName} />
                </div>
                <h1 id='title'>Monthly Survey Results for All Companies</h1>
                {/* <h1 id='title'>{this.state.companyName}</h1> */}
                {/* <h2>  {this.state.startDate}</h2> */}
                {dateArea}
                {companyType}
                {message}
                <table className='table' id='surveys'>
                    {surveyMonthTable}
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        companyMonthlyStats: state.companyData.companyMonthlyStats,
        coLoading: state.companyData.loading,
        loggedInUser: state.userData.loggedInUser,
        companyDetails: state.companyData.companyDetails,
        exportData: state.companyData.exportData,
        maxDays: state.companyData.maxDays,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCompanyMonthlyStats: (companyType, startDate) => dispatch(getCompanyMonthlyStats(companyType, startDate)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatsMonth);
