import React from 'react';

const BodyTextBackgroundWide = ({children}) => (
    <div className="main-border" style={{maxWidth:"1100px"}} >
        <div className="main" style={{maxWidth:"1100px"}} >
        {children}
        </div>
    </div>
);

export default BodyTextBackgroundWide;