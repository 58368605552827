import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import { updateUser, getSelectedUser } from '../../dataStore/UserActions';
import { createSurvey, getSurvey, updateSurveyWeekUser} from '../../dataStore/SurveyActions';
import { getCompany } from '../../dataStore/CompanyActions';
import {clearCurrentUser}  from '../../dataStore/UserActions';
import Spinner from '../../components/UI/Spinner';
import '.././Form.css';
import Input from '../../components/UI/Input';
import getCurrentDate from '../../components/CurrentDate';
import calcAge from '../../components/CalcAge';
import BodyTextBackground from '../UI/BodyTextBackground';


class userDetails extends Component {
    state = {
        coNum: 0,
        updateForm: {
            firstName: {
                label: 'First name: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'First name/s'
                },
                value: '',
                validation: {
                    required: true,
                    firstChar: true
                },
                valid: false,
                touched: false
            },
            surname: {
                label: 'Last name (surname): *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Last name (surname)'
                },
                value: '',
                validation: {
                    required: true,
                    firstChar: true
                },
                valid: false,
                touched: false
            },
            email: {
                label: 'email address:',
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'email address'
                },
                value: '',
                validation: {
                    isEmail: true,
                    lowerCase: true,
                },
                valid: true,
                touched: false
            },
            cellNo: {
                label: 'Mobile number:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Mobile number - 10 digits'
                },
                value: '',
                validation: {
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            DOB: {
                label: 'Date of Birth - DD/MM/CCYY: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Date of Birth - DD/MM/CCYY'
                },
                value: '',
                validation: {
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            idNo: {
                label: 'ID number/Passport: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'ID number - 13 digits'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 13,
                    maxLength: 15,
                },
                valid: false,
                touched: false
            },
            address: {
                label: 'Street address:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Street address'
                },
                value: '',
                validation: {},
                valid: false,
                touched: false
            },
            suburb: {
                label: 'Suburb:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Suburb'
                },
                value: '',
                validation: {
                    firstChar: true
                },
                valid: false,
                touched: false
            },
            postalCode: {
                label: 'Postal Code:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Postal Code'
                },
                value: '',
                validation: {
                    minLength: 4,
                    maxLength: 4,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            companyNo: {
                label: 'Clinsys Check Point Unique Company Number: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Clinsys Check Point Unique company Number'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 7,
                    maxLength: 9,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            jobCategory: {
                label: 'NIOH Job Category:',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'UNKNOWN', displayValue: 'NIOH job category' },
                        { value: 'Managers', displayValue: 'Managers' },
                        { value: 'Professionals', displayValue: 'Professionals' },
                        { value: 'Technicians and Associate Professionals', displayValue: 'Technicians and Associate Professionals' },
                        { value: 'Clerical Support Workers', displayValue: 'Clerical Support Workers' },
                        { value: 'Services and Sales Workers', displayValue: 'Services and Sales Workers' },
                        { value: 'Skilled Agricultural, Forestry, Fishery, Craft and Related Trades Workers', displayValue: 'Skilled Agricultural, Forestry, Fishery, Craft and Related Trades Workers' },
                        { value: 'Plant and Machine Operators and Assemblers', displayValue: 'Plant and Machine Operators and Assemblers' },
                        { value: 'Elementary Occupations', displayValue: 'Elementary Occupations' }
                    ]
                },
                value: 'NIOH job category',
                validation: {},
                valid: true
            },
            employeeNo: {
                label: 'Employee Number:',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Employee Number'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
        },
        gender: 'male',
        userType: 0,
        oldSurname: '',
        formIsValid: false,
        submitForm: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');
        } else
            this.setState({ coNum: this.props.loggedInUser.companyNo });
    }

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (this.props.loadingUser  || this.props.loadingSurvey) {
            return;
        };
        const curDate = getCurrentDate();
        const surveyDate = curDate.slice(6, 16);
        if ((this.state.updateForm.surname.value === '') && (this.props.currentUser.surname !== '')) {
            if (surveyDate === this.props.currentUser.lastSurveyDate) {
                this.props.getSurvey(this.props.currentUser.companyNo, surveyDate, this.props.currentUser.lastSurveyID)
            };
            const userDetails = this.props.currentUser;
            const formDetails = {
                ...this.state.updateForm
            };
            if (userDetails.DOB === undefined || (userDetails.DOB === 'dd/mm/ccyy')) { userDetails.DOB = '' };
            if (userDetails.jobCategory === undefined) { userDetails.jobCategory = 'unknown' };
            if (userDetails.suburb === undefined) { userDetails.suburb = '' };
            if (userDetails.address === undefined) { userDetails.address = '' };
            if (userDetails.postalCode === undefined) { userDetails.postalCode = '' };
            if (userDetails.employeeNo === undefined) { userDetails.employeeNo = '' };
            if (userDetails.email === undefined) { userDetails.email = '' };
            if (userDetails.cellNo === undefined) { userDetails.cellNo = '' };
            for (let formElementIdentifier in userDetails) {
                const stringArray = ["email", "firstName", "surname", "DOB", "idNo", "cellNo", "address", "suburb", "postalCode", "companyNo", "jobCategory", "employeeNo"];
                const process = (stringArray.indexOf(formElementIdentifier) > -1)
                if (process) {
                    formDetails[formElementIdentifier] =
                        userDetails[formElementIdentifier]
                };
            };
            this.setState({ gender: userDetails.gender });
            this.setState({ userType: String(userDetails.userType )});
            for (const elementIdentifier in formDetails) {
                if (formDetails[elementIdentifier]) {
                    this.setState(state => {
                        state.updateForm[elementIdentifier].value =
                            formDetails[elementIdentifier]
                        return state
                    });
                }
            };
        };
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^[/\d-+]+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        // if (oldSurname === '' && (inputIdentifier === 'surname')) {
        //     this.setState({ oldSurname: this.props.currentUser.surname });
        // }
        this.setState({ submitForm: false });
        const updatedsignUpForm = {
            ...this.state.updateForm
        };
        const updatedFormElement = {
            ...updatedsignUpForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;

        if (updatedFormElement.validation.firstChar) {
            updatedFormElement.value.charAt(0).toUpperCase();
        };
        if (updatedFormElement.validation.lowerCase) {
            updatedFormElement.value.toLowerCase();
        };

        updatedsignUpForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedsignUpForm) {
            formIsValid = updatedsignUpForm[inputIdentifier].valid && formIsValid;
        };
        if (event.target.value === '') {
            updatedsignUpForm[inputIdentifier].touched = false;
            if (inputIdentifier === 'coEmail') {
                formIsValid = true;
            };
        };
        this.setState({ updateForm: updatedsignUpForm, formIsValid: formIsValid });
    }

    onChangeCheckbox = (event) => {
        if (event.target.name === 'positive') {
            this.props.history.push( '/NIOHpositive' );
        };
        if (event.target.name === 'return') {
            this.props.history.push( '/NIOHreturn' );
        };
        if (event.target.name === 'vunerability') {
            this.props.history.push( '/NIOHvunerability' );
        };
    }

    onChangeRadio = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmitSurvey = (event) => {
        event.preventDefault()
        const curDate = getCurrentDate();
        const surveyDate = curDate.slice(6, 16);
        if (surveyDate === this.props.currentUser.lastSurveyDate && this.props.lastSurvey.temperature > 0) {
            this.props.getCompany(this.props.currentUser.companyNo)
            this.props.history.replace('/complete');
        } else {
            const currentUser = {};
            for (let formElementIdentifier in this.state.updateForm) {
                currentUser[formElementIdentifier] = this.state.updateForm[formElementIdentifier].value;
            };
            if (this.state.updateForm.DOB.value !== '') {
                currentUser.age = calcAge(this.state.updateForm.DOB.value);
            };
            currentUser.gender = this.state.gender;
            currentUser.userType = this.state.userType;
            currentUser.id = this.props.currentUser.id;
            currentUser.firebaseID = this.props.currentUser.firebaseID;
            if (!currentUser.id) {
                currentUser.id = this.props.currentUser.firebaseID;
            };
            currentUser.idNo = this.props.currentUser.idNo;
            if ((this.props.lastSurvey.companyNo !== '109999' ) && 
                    (this.props.lastSurvey.loadedDate === surveyDate) && 
                    (this.props.currentUser.lastSurveyDate === surveyDate) && (this.props.lastSurvey.temperature === 0)) {
                this.props.history.replace("/survey");
            }else  {
                this.props.createSurvey(this.props.currentUser);
                this.props.history.replace("/survey");
            };
        };
    };

    handleDetailsOK = (event) => {
        event.preventDefault();
        console.log('in handleDetailsOK')
        const currentUser = {};
        for (let formElementIdentifier in this.state.updateForm) {
            currentUser[formElementIdentifier] = this.state.updateForm[formElementIdentifier].value;
        };
        currentUser.gender = this.state.gender;
        currentUser.userType = this.state.userType;
        
        if (this.state.updateForm.DOB.value !== '') {
            currentUser.age = calcAge(this.state.updateForm.DOB.value);
        };
        if (this.props.currentUser.firebaseID === undefined){
            currentUser.firebaseID = this.props.currentUser.id;
        } else{
            currentUser.firebaseID = this.props.currentUser.firebaseID;};
        
        this.setState({ submitForm: true });
        console.log('usertype = ', currentUser.userType)
        this.props.updateUser(currentUser);
        if (this.props.currentUser.id) {
            this.props.getSelectedUser(this.props.loggedInUser.companyNo, this.props.currentUser.id);
        } else {
            this.props.getSelectedUser(this.props.loggedInUser.companyNo, this.props.currentUser.firebaseID);
        };
        const startDate = new Date();
        this.props.updateSurveyWeekUser (this.props.loggedInUser.companyNo, startDate, 
                                        currentUser.surname, currentUser.firstName,
                                        this.props.currentUser.id, currentUser.age, currentUser.idNo, 
                                        currentUser.gender, currentUser.jobCategory)
        if (this.props.admin) {
            this.props.history.push('/regUsers');
        }else if (this.props.loggedInUser.mainUser) {
                this.props.history.push('/users');
            }else {
                this.props.history.push('/complete');
            };
    };


    render() {
        const formElementsArray = [];
        for (let key in this.state.updateForm) {
            formElementsArray.push({
                id: key,
                config: this.state.updateForm[key]
            });
        }
        let NIOHdetails = (
            <Fragment>
                <div className="radioTop" onChange={this.onChangeCheckbox}>
                    <input type="checkbox" value='false' name="positive"
                        onChange={this.onChangeCheckbox}
                        className="checkbox" /> Positive Case Report
                    <input type="checkbox" value="false" name="return"
                            onChange={this.onChangeCheckbox}
                            className="checkbox" /> Return to work
                    <input type="checkbox" value="false" name="vunerability"
                            onChange={this.onChangeCheckbox}
                            className="checkbox" /> Vunerability report
            </div>
            </Fragment>
        );
        // if (!this.props.loggedInUser.mainUser) {
            NIOHdetails = null;
        // };
        let gender = (
            <Fragment>
                <div className="radioTop" onChange={this.onChangeRadio}>
                    <input type="radio" value="Male" name="gender"
                        checked={this.state.gender === "Male"}
                        onChange={this.onChangeRadio}
                        className="radioSpace" /> Male
                <input type="radio" value="Female" name="gender"
                        checked={this.state.gender === "Female"}
                        onChange={this.onChangeRadio}
                        className="radioSpace" /> Female
                <input type="radio" value="Other" name="gender"
                        checked={this.state.gender === "Other"}
                        onChange={this.onChangeRadio}
                        className="radioSpace" /> Other *
            </div>
            </Fragment>
        );
        
        let userType = (
            <Fragment>
                <div className="radioBottom" onChange={this.onChangeRadio}>
                    <input type="radio" value="1" name="userType"
                        checked={this.state.userType === "1"}
                        onChange={this.onChangeRadio}
                        className="radioSpace" /> Employee
                    <input type="radio" value="2" name="userType"
                        checked={this.state.userType === "2"}
                        onChange={this.onChangeRadio}
                        className="radioSpace" /> Visitor
                </div>
            </Fragment>
        );

        if (this.props.loggedInUser.mainUser === true) {
            userType = (
                <Fragment>
                    <div className="radioBottom" onChange={this.onChangeRadio}>
                        <input type="radio" value="1" name="userType"
                            checked={this.state.userType === "1"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Employee
                        <input type="radio" value="2" name="userType"
                            checked={this.state.userType === "2"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Visitor
                        <input type="radio" value="3" name="userType"
                            checked={this.state.userType === "3"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Survey administrator
                        <input type="radio" value="4" name="userType"
                            checked={this.state.userType === "4"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Main User
                    </div>
                </Fragment>
            );
        };

        if (this.props.loggedInUser.surname === this.props.currentUser.surname && (this.props.currentUser.userType > 3)) {
            userType = (
                <Fragment>
                    <div className="radioBottom" onChange={this.onChangeRadio}>
                        <input type="radio" value="1" name="userType"
                            checked={this.state.userType === "1"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Employee
                        <input type="radio" value="2" name="userType"
                            checked={this.state.userType === "2"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Visitor
                        <input type="radio" value="3" name="userType"
                            checked={this.state.userType === "3"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Survey administrator
                        <input type="radio" value="4" name="userType"
                            checked={this.state.userType === "4"}
                            onChange={this.onChangeRadio}
                            className="radioSpace" /> Main User
                    </div>
                </Fragment>
            );
        };

        let formInfo = (
            <div >
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        label={formElement.config.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        cbLabel={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </div>
        );

        let errorMessage = this.props.authError;

        if (this.props.loadingUser) {
            formInfo = <Spinner />;
        }
        return (
            <div className="wrapper">
                <div className="containerFlex  main-wrapper">
                    <div className="filler">
                    </div>
                    <BodyTextBackground>
                        <div className="input-field">
                            <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>  
                            <button className="btnPrimary" onClick={this.handleSubmitSurvey}>Go to Survey</button>
                        </div> 
                        <h2>Please check user's details</h2>
                        <div className="label-left-black">
                            <p>*Required field</p>
                        </div>
                        <div className="error-text">
                            {this.props.authError ? <p>{errorMessage}</p> : null}
                        </div>
                        {NIOHdetails}
                        {gender}
                        {formInfo}
                        {userType}
                        <div className="input-field">
                            <button className="btnPrimary" onClick={this.handleDetailsOK}>Update Details</button>
                        </div>
                    </BodyTextBackground>
                    <div className="filler">
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        currentUser: state.userData.currentUser,
        loggedInUser: state.userData.loggedInUser,
        loadingUser: state.userData.loading,
        loadingSurvey: state.surveyData.loading,
        admin: state.userData.admin,
        lastSurvey: state.surveyData.userDetails,
        companyDetails : state.companyData.companyDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (currentUser) => dispatch(updateUser(currentUser)),
        createSurvey: (currentUser) => dispatch(createSurvey(currentUser)),
        getSelectedUser: (company, id) => dispatch(getSelectedUser(company, id)),
        getCompany: (companyNo) => dispatch(getCompany(companyNo)),
        clearCurrentUser: () => dispatch(clearCurrentUser()),
        getSurvey: (companyNo, surveyDate, LastSurveyID) =>
            dispatch(getSurvey(companyNo, surveyDate, LastSurveyID)),
        updateSurveyWeekUser: (companyNo, startDate, surname, firstName, email, age, idNo, gender, jobCategory)=>
            dispatch(updateSurveyWeekUser(companyNo, startDate, surname, firstName, email, age, idNo, gender, jobCategory)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(userDetails);
