import monthExport from '../modals/MonthExport'; 
import {
    UPDATE_COMPANY,
    LOADING_COMPANY,
    FETCH_COMPANYS,
    FETCH_DAILY_STATS,
    FETCH_MONTHLY_STATS,
    RESET_BLOCK,
    BLOCK_FOUND,
    BLOCK_ERROR,
    COMPANY_FAIL,
} from './CompanyActions';

const initialState = {
    allCompanys: [],
    companyDailyStats: [],
    companyMonthlyStats: [],
    exportData: [],
    companyDetails: {
        id: '',
        coEmail: '',
        companyName: '',
        UUID: '',
        contactFirstName: '',
        contactSurname: '',
        idNo: '',
        companyNo: '109999',
        telNo: '',
        celNo: '',
        address: '',
        suburb: '',
        postalCode: 0,
        district: '',
        province: '',
        country: 'South Africa',
        empNo: 0,
        visitorNo: 0,
        paymentDate: '20200101',
        paymentAmount: 0,
        status: 'inActive',
        validTill: '20200101',
    },
    loading : false,
    blockFound : false,
    blockError : '',
    curCompanyNo : '',
    curCompanyName : '',
    curCompanyStatus : null,
    maxDays: 0,
    error: null,
};


export default (state = initialState, action) => {
// console.log ('action.type = ', action.type)
    switch (action.type) {

        case LOADING_COMPANY:
          return {
            ...state,
            loading: true,
            error: null,
          };

        case UPDATE_COMPANY: 
            return {
                ...state,
                companyDetails: action.company,
                curCompanyNo: action.company.companyNo,
                curCompanyName: action.company.companyName,
                curCompanyStatus: action.company.status,
                loading: false,
            };

        case FETCH_COMPANYS:
            return {
                ...state,
                allCompanys: action.allCompanys,
                loading: false,
            };

        case FETCH_DAILY_STATS:
            return {
                ...state,
                companyDailyStats: action.allCoStats,
                loading: false,
            };

        case FETCH_MONTHLY_STATS:
            let exportInfo = [];
            for (const key in action.allCoStats) {
                // const keyName = key[0].toUpperCase() + key.substring(1);
                let day31 = '';
                if (action.maxDays === 31) {
                    day31= action.allCoStats[key].result[30]
                }
                exportInfo.push(new monthExport(
                    action.allCoStats[key].idNo,
                    action.allCoStats[key].coName, 
                    action.allCoStats[key].status, 
                    action.allCoStats[key].result[0],
                    action.allCoStats[key].result[1],
                    action.allCoStats[key].result[2],
                    action.allCoStats[key].result[3],
                    action.allCoStats[key].result[4],
                    action.allCoStats[key].result[5],
                    action.allCoStats[key].result[6],
                    action.allCoStats[key].result[7],
                    action.allCoStats[key].result[8],
                    action.allCoStats[key].result[9],
                    action.allCoStats[key].result[10],
                    action.allCoStats[key].result[11],
                    action.allCoStats[key].result[12],
                    action.allCoStats[key].result[13],
                    action.allCoStats[key].result[14],
                    action.allCoStats[key].result[15],
                    action.allCoStats[key].result[16],
                    action.allCoStats[key].result[17],
                    action.allCoStats[key].result[18],
                    action.allCoStats[key].result[19],
                    action.allCoStats[key].result[20],
                    action.allCoStats[key].result[21],
                    action.allCoStats[key].result[22],
                    action.allCoStats[key].result[23],
                    action.allCoStats[key].result[24],
                    action.allCoStats[key].result[25],
                    action.allCoStats[key].result[26],
                    action.allCoStats[key].result[27],
                    action.allCoStats[key].result[28],
                    action.allCoStats[key].result[29],
                    day31
                    ));
                };
            return {
                ...state,
                companyMonthlyStats: action.allCoStats,
                exportData: exportInfo,
                maxDays: action.maxDays,
                loading: false,
            };
        
        case COMPANY_FAIL:  
        return {
            ...state,
            error: action.error,
            loading: false
            };

        case RESET_BLOCK:
            return {
              ...state,
              loading: false,
              blockFound: false,
              blockError: ''
            };

        case BLOCK_FOUND:
            return {
              ...state,
              loading: false,
              blockFound: true,
              blockError: ''
            };

        case BLOCK_ERROR:
            return {
              ...state,
              loading: false,
              blockFound: false,
              blockError: 'Check Point number is not valid',
            };

        default:
            return state;
    }
};
