import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import * as serviceWorker from './serviceWorker';
import {createStore, combineReducers, applyMiddleware} from "redux";
import ReduxThunk from "redux-thunk";

import authReducer from "./dataStore/AuthReducer";
import surveyReducer from "./dataStore/SurveyReducer";
import userReducer from "./dataStore/UserReducer";
import companyReducer from "./dataStore/CompanyReducer";
import NIOHReducer from "./dataStore/NIOHReducer";
import './index.css';
import App from './App';

const rootReducer = combineReducers({
  authData: authReducer,
  companyData: companyReducer,
  userData: userReducer,
  surveyData: surveyReducer,
  NIOHData: NIOHReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
