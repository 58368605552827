import getCurrentDate from '../components/CurrentDate';
import dailyStats from '../modals/DailyStats';

import { createUser } from './UserActions';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const LOADING_COMPANY = 'LOADING_COMPANY';
export const FETCH_COMPANYS = 'FETCH_COMPANYS';
export const FETCH_DAILY_STATS = 'FETCH_DAILY_STATS';
export const FETCH_MONTHLY_STATS = 'FETCH_MONTHLY_STATS';
export const RESET_BLOCK = 'RESET_BLOCK';
export const BLOCK_FOUND = 'BLOCK_FOUND';
export const BLOCK_ERROR = 'BLOCK_ERROR';
export const COMPANY_FAIL = 'COMPANY_FAIL';

const curDate = getCurrentDate();


export const loadingCompany = () => {
  return {
    type: LOADING_COMPANY
  };
};

export const resetBlock = () => {
  return {
    type: RESET_BLOCK
  };
};


export const createCompany = (currentCompany) => {
  return async (dispatch) => {
    dispatch(loadingCompany());
    const coEmail = currentCompany.coEmail;
    const companyName = currentCompany.companyName;
    const UUID = currentCompany.UUID;
    const contactFirstName = currentCompany.contactFirstName;
    const contactSurname = currentCompany.contactSurname;
    const idNo = currentCompany.idNo;
    let prov = currentCompany.province.slice(3, 5);
    console.log('currentCompany.province = ', currentCompany.province)
    if (currentCompany.province === 'Province') {
        prov = '00';
    };

    let companyNo = prov + currentCompany.postalCode;
    const cellNo = currentCompany.cellNo;
    const telNo = currentCompany.telNo;
    const address = currentCompany.address;
    const suburb = currentCompany.suburb;
    const postalCode = currentCompany.postalCode;
    const district = currentCompany.district;
    const province = currentCompany.province;
    const country = currentCompany.country;
    const empNo = currentCompany.empNo;
    const visitorNo = currentCompany.visitorNo;
    const companyType = currentCompany.companyType;
    const blockNo = currentCompany.blockNo;
    const paymentDate = '20200101';
    const paymentAmount = 0;
    const status = 'inActive';
    try {
      let response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/CoNumbers/${companyNo}.json?`,
      );

      let resData = await response.json();
      if (resData) {
        let lastno = parseInt(resData.lastno)
        lastno = lastno + 1
        response = await fetch(
          `https://listapp-fcbc4.firebaseio.com/CoNumbers/${companyNo}.json`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              lastno
            })
          });
        companyNo += lastno;
      } else {
        const lastno = 1;
        response = await fetch(
          `https://listapp-fcbc4.firebaseio.com/CoNumbers/${companyNo}.json`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              lastno
            })
          });
        companyNo += lastno;
      }

      //create New Company on Firebase
      response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            coEmail,
            companyName,
            UUID,
            companyType,
            contactFirstName,
            contactSurname,
            companyNo,
            blockNo,
            idNo,
            cellNo,
            telNo,
            address,
            suburb,
            postalCode,
            district,
            province,
            country,
            empNo,
            visitorNo,
            paymentDate,
            paymentAmount,
            status,
            curDate
          })
        }
      );

      resData = await response.json();
      const coFirebaseID = resData.name;

      const newCompany = {
        ID: coFirebaseID,
        companyNo: companyNo,
        ...currentCompany
      };


      const currentUser = ({
        email: currentCompany.coEmail,
        firstName: currentCompany.contactFirstName,
        surname: currentCompany.contactSurname,
        companyNo: companyNo,
        employeeNo: '',
        cellNo: currentCompany.cellNo,
        telNo: currentCompany.telNo,
        idNo: currentCompany.idNo,
        address: currentCompany.address,
        suburb: currentCompany.suburb,
        postalCode: currentCompany.postalCode,
        district: currentCompany.district,
        province: currentCompany.province,
        country: currentCompany.country,
        mainUser: true,
        userType: 4,
        visitor: false
      });

      //*****Check if user already exists */

      
      dispatch(createUser(currentUser))

      dispatch({
        type: UPDATE_COMPANY,
        company: newCompany

      });
    } catch (error) {
      dispatch({
        type: COMPANY_FAIL,
        error: error
      });
    }
  };
};


export const getCompany = (companyNo) => {
  return async (dispatch) => {
    dispatch(loadingCompany());
    try {
      let response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json`,
      )

      const resData = await response.json();
      let curCompany = [];
      if (resData) {
        for (const key in resData) {
          curCompany.push({
            ...resData[key],
            firebaseID: key
          }
          )
        };
        if (curCompany.district === undefined) {
            const district = 'unknown'
            const UUID = ''
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/CoNumbers/${companyNo}.json`,
                {
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    district,
                    UUID
                  })
                });
        }
      };

        const companyInfo = curCompany[0];

        dispatch({
            type: UPDATE_COMPANY,
            company: companyInfo
        });

    } catch (error) {
        dispatch({
            type: COMPANY_FAIL,
            error: error
        });
    }
  };
};


export const checkBlockExists = (blockNo) => {
  return async (dispatch) => {
    dispatch(loadingCompany());
    try {
      let response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/COS/${blockNo}.json`,
      )

      const resData = await response.json();
      let curCompany = [];
      if (resData) {
        for (const key in resData) {
          curCompany.push({
            ...resData[key],
            firebaseID: key
          }
          )
        }
        if (curCompany[0].companyType === 'Owner') {
          dispatch({
            type: BLOCK_FOUND,
          });
        }
      } else {
        dispatch({
          type: BLOCK_ERROR,
        });
      }

    } catch (error) {
      dispatch({
        type: COMPANY_FAIL,
        error: error
      });
    }
  };
};


export const updateCompany = (currentCompany, key) => {
  if (currentCompany.companyNo === undefined) {
    return;
  }
  return async (dispatch) => {
    dispatch(loadingCompany());
    const key = currentCompany.firebaseID;
    const coEmail = currentCompany.coEmail;
    const companyName = currentCompany.companyName;
    const UUID = currentCompany.UUID;
    const contactFirstName = currentCompany.contactFirstName;
    const contactSurname = currentCompany.contactSurname;
    const idNo = currentCompany.idNo;
    const companyNo = currentCompany.companyNo;
    const cellNo = currentCompany.cellNo;
    const telNo = currentCompany.telNo;
    const address = currentCompany.address;
    const suburb = currentCompany.suburb;
    const postalCode = currentCompany.postalCode;
    const district = currentCompany.district;
    const province = currentCompany.province;
    const country = currentCompany.country;
    const empNo = currentCompany.empNo;
    const visitorNo = currentCompany.visitorNo;
    const companyType = currentCompany.companyType;
    const paymentDate = currentCompany.paymentDate;
    const paymentAmount = currentCompany.paymentAmount;
    const status = currentCompany.status;
    let blockNo = currentCompany.blockNo;
    if (blockNo === undefined && (companyType === 'Alone' || companyType === 'Owner')) {
      blockNo = companyNo;
    };
    try {
      let response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}/${key}.json`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            coEmail,
            companyName,
            UUID,
            contactFirstName,
            contactSurname,
            companyNo,
            blockNo,
            companyType,
            idNo,
            cellNo,
            telNo,
            address,
            suburb,
            postalCode,
            district,
            province,
            country,
            empNo,
            visitorNo,
            paymentDate,
            paymentAmount,
            status,
            curDate,
          })
        }
      );

      response.json();

      dispatch({
        type: UPDATE_COMPANY,
        company: currentCompany

      });
    } catch (error) {
      dispatch({
        type: COMPANY_FAIL,
        error: error
      });
    }
  };
};


function sortAlphabeticallyAscending(a, b) {
  const companyA = a.companyName[0].toUpperCase() + a.companyName.substring(1);
  const companyB = b.companyName[0].toUpperCase() + b.companyName.substring(1);
  const a1 = companyA + a.blockNo + a.companyNo;
  const b1 = companyB + b.blockNo + b.companyNo;
  if (a1 < b1) return -1;
  else if (a1 > b1) return 1;
  else return 0;
}


export const fetchCompanys = () => {
  return async (dispatch) => {
    dispatch(loadingCompany());
    try {
      let response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/COS.json?`,
      );

      if (!response.ok) {
        throw new Error(`Unable to connect to server! ${response.message}`);
      };

      let resData = await response.json();
      let companys = [];
      for (const key in resData) {
        companys.push({
          ...resData[key],
          companyNo: key
        }
        );
      }
      let curCompanys = [];
      for (const key in companys) {
        const companyNo = companys[key].companyNo;
        response = await fetch(
          `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json?`,
        );

        resData = await response.json();
        for (const key in resData) {
          curCompanys.push({
            ...resData[key],
            id: key
          }
          );
        };
      };
      
      if (curCompanys.length > 0) {
        await curCompanys.sort(sortAlphabeticallyAscending);
      }

      dispatch({
        type: FETCH_COMPANYS,
        allCompanys: curCompanys,
      });
    }
    catch (err) {
      dispatch({
        type: COMPANY_FAIL,
        error: err
      });
    }
  };
};


function sortBlocksAscending(a, b) {
  const a1 = a.blockNo + a.companyName + a.companyNo;
  const b1 = b.blockNo + b.companyName + b.companyNo;
  if (a1 < b1) return -1;
  else if (a1 > b1) return 1;
  else return 0;
}

export const getCompanyDailyStats = (companyType, surveyDate) => {
  const tick = '✓';
  return async (dispatch) => {
    dispatch(loadingCompany());
    try {
        let response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/COS.json?`,
        );

        if (!response.ok) {
            throw new Error(`Unable to connect to server! ${response.message}`);
        };

        let resData = await response.json();
        let companys = [];
        for (const key in resData) {
            companys.push({
                ...resData[key],
            }
            );
        };
        let curCompanys = [];
        let blockName = '';
        for (const keyCo in companys) {
            let company = [];
            for (const [, value] of Object.entries(companys[keyCo])) {
                company.push(value)
            };
            
            const companyNo = company[0].companyNo;
            const blockNo = company[0].blockNo;
            blockName = '';
            
            if (blockNo !== undefined) {
                response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/COS/${blockNo}.json?`,
                    );
                let curBlock = []
                resData = await response.json();
                for (const key in resData) {
                    curBlock.push({ ...resData[key] })
                }
                blockName = curBlock[0].companyName
            };

            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/COS/${companyNo}.json?`,
            );

            resData = await response.json();
            for (const key in resData) {
                if (companyType === 'All') {
                    curCompanys.push({ ...resData[key], id: key, blockName: blockName });
                } else if (companyType === resData[key].companyType) {
                    curCompanys.push({ ...resData[key], id: key, blockName: blockName })
                };
            };
        };
        if (curCompanys.length > 0) {
            await curCompanys.sort(sortBlocksAscending);
        }
        let exportInfo = [];
        for (let index = 0; index < curCompanys.length; index++) {
            const element = curCompanys[index];
            let coStatus = '';
            if (element.status) {
                if (element.status === 'Active'){
                coStatus = tick;
                };
            };
            let totDecl = 0;
            let totTemp = 0;
            let tempCnt = 0;
            let tempAbove = 0;
            let totCheck1 = 0;
            let totCheck2 = 0;
            let totCheck3 = 0;
            let totCheck4 = 0;
            let totCheck5 = 0;
            let totCheck6 = 0;
            let totCheck7 = 0;
            let totCheck8 = 0;
            let totCheck9 = 0;
            let totCheck10 = 0;
            let totCheck11 = 0;
            let totCheck12 = 0;
            let totCheck13 = 0;
            let totCheck14 = 0;
            let totCheck15 = 0;
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${element.companyNo}/${surveyDate}.json?`,
            );

            if (!response.ok) {
                throw new Error(`Unable to connect to server! ${response.message}`);
            };

            resData = await response.json();
            let currentSurveys = [];
            for (const key in resData) {
                currentSurveys.push({
                    ...resData[key],
                    id: key
                }
                );
            };

            for (let index = 0; index < currentSurveys.length; index++) {
                totDecl++;
                if (currentSurveys[index].temperature > 0) {
                    totTemp = parseFloat(totTemp) + parseFloat(currentSurveys[index].temperature);
                    tempCnt++;
                };
                if (currentSurveys[index].temperature > 37.5) {
                    tempAbove++
                };
                if (!currentSurveys[index].check12) {
                    currentSurveys[index].check12 = 0;
                };
                if (!currentSurveys[index].check13) {
                    currentSurveys[index].check13 = 0;
                };
                if (!currentSurveys[index].check14) {
                    currentSurveys[index].check14 = 0;
                };
                if (!currentSurveys[index].check15 ) {
                    currentSurveys[index].check15 = 0;
                };
                totCheck1 = +currentSurveys[index].check1 + totCheck1;
                totCheck2 = +currentSurveys[index].check2 + totCheck2;
                totCheck3 = +currentSurveys[index].check3 + totCheck3;
                totCheck4 = +currentSurveys[index].check4 + totCheck4;
                totCheck5 = +currentSurveys[index].check5 + totCheck5;
                totCheck6 = +currentSurveys[index].check6 + totCheck6;
                totCheck7 = +currentSurveys[index].check7 + totCheck7;
                totCheck8 = +currentSurveys[index].check8 + totCheck8;
                totCheck9 = +currentSurveys[index].check9 + totCheck9;
                totCheck10 = +currentSurveys[index].check10 + totCheck10;
                totCheck11 = +currentSurveys[index].check11 + totCheck11;
                totCheck12 = +currentSurveys[index].check12 + totCheck12;
                totCheck13 = +currentSurveys[index].check13 + totCheck13;
                totCheck14 = +currentSurveys[index].check14 + totCheck14;
                if (currentSurveys[index].check15 !== undefined) {
                    totCheck15 = +currentSurveys[index].check15 + totCheck15;
                };
            };
            let avgTemp = 0;
            if (tempCnt > 0) {
                avgTemp = parseFloat(totTemp / tempCnt).toFixed(2);
            };
            if (isNaN(avgTemp)) {
                avgTemp = 0;
            };
            exportInfo.push(new dailyStats(
                    element.id,
                    element.blockName,
                    element.companyName,
                    coStatus,
                    totDecl,
                    tempAbove,
                    totCheck1,
                    totCheck2,
                    totCheck3,
                    totCheck4,
                    totCheck5,
                    totCheck6,
                    totCheck7,
                    totCheck8,
                    totCheck9,
                    totCheck10,
                    totCheck11,
                    totCheck12,
                    totCheck13,
                    totCheck14,
                    totCheck15));
        };
        dispatch({
            type: FETCH_DAILY_STATS,
            allCoStats: exportInfo,
        });
    }
    catch (err) {
        dispatch({
            type: COMPANY_FAIL,
            error: err
        });
    }
  };
};

function monSurvey(idNo, coName, status, result) {
  this.idNo = idNo;
  this.coName = coName;
  this.status = status;
  this.result = result;
}


function sortCompanyAscending(a, b) {
  const companyA = a.updatedCO.companyName[0].toUpperCase() + a.updatedCO.companyName.substring(1);
  const companyB = b.updatedCO.companyName[0].toUpperCase() + b.updatedCO.companyName.substring(1);
  const a1 = companyA + a.updatedCO.blockNo + a.updatedCO.companyNo + a.updatedCO.day;
  const b1 = companyB + b.updatedCO.blockNo + b.updatedCO.companyNo + a.updatedCO.day;
  if (a1 < b1) return -1;
  else if (a1 > b1) return 1;
  else return 0;
}


export const getCompanyMonthlyStats = (companyType, startDate) => {
  const tick = '✓';

  return async (dispatch) => {
    dispatch(loadingCompany());
    try {
      let response = await fetch(
        `https://listapp-fcbc4.firebaseio.com/COS.json?`,
      );

      if (!response.ok) {
        throw new Error(`Unable to connect to server! ${response.message}`);
      };

      let resData = await response.json();
      let companys = [];
      for (const key in resData) {
        companys.push({
          ...resData[key],
          companyKey: key
        }
        );
      };
      const curMonth = startDate.slice(3, 5)
      let curCompanys = [];
      let curSurveys = [];
      for (const key in companys) {
        const companyKey = companys[key].companyKey;
        response = await fetch(
          `https://listapp-fcbc4.firebaseio.com/COS/${companyKey}.json?`,
        );

        resData = await response.json();
        for (const key in resData) {
          if (companyType === 'All') {
            curCompanys.push({ ...resData[key], id: key });
          } else if (companyType === resData[key].companyType) {
            curCompanys.push({ ...resData[key], id: key })
          };
          if (curCompanys.blockNo === undefined) {
            curCompanys.blockNo = resData[key].companyNo;
          };
        };
      };
      const mmYY = startDate.slice(2);
      for (let i = 1; i < 32; ++i) {
        let numberString = `${i}`;
        if (numberString.length === 1)
            numberString = `0${i}`;
        const newDate = numberString + mmYY
        for (let index = 0; index < curCompanys.length; index++) {
            const element = curCompanys[index];
            response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${element.companyNo}/${newDate}.json?`,
            );

            if (!response.ok) {
                throw new Error(`Unable to connect to server! ${response.message}`);
            };

            const resData = await response.json();
            let totalCnt = 0;
            if (resData !== null) {
                for (const key in resData) {
                    if (key !== 0) {
                        totalCnt++
                    }
                };
            };
            let coStatus = '';
            if (element.status) {
                if (element.status === 'Active'){
                    coStatus = tick;
                    };
            }

            if (element.blockNo === undefined) {
                element.blockNo = element.companyNo;
            };
            const updatedCO = ({
                idNo: element.id,
                day: numberString,
                totDecl: totalCnt,
                companyName: element.companyName,
                blockNo: element.blockNo,
                companyNo: element.companyNo,
                status: coStatus,
            });
            curSurveys.push({ updatedCO });
        };
      };

      await curSurveys.sort(sortCompanyAscending);

      let maxDays = 30;
      if (curMonth === '01' || curMonth === '03' || curMonth === '05' || curMonth === '07' || curMonth === '08' || curMonth === '10' | curMonth === '12') {
        maxDays = 31
      } else if (curMonth === '02') {
        maxDays = 28
      }
      let monthResults = [];
      let coID = '';
      let coName = '';
      let coStatus = '';
      let monResults = new Array(maxDays).fill('');

      for (let index = 0; index < curSurveys.length; index++) {

        if (coID !== '' && coID !== curSurveys[index].updatedCO.idNo) {
          monthResults.push(new monSurvey(coID, coName, coStatus, [...monResults]));
          for (let day = 0; day < maxDays; day++) {
            monResults[day] = '';
          };
        };

        if (curSurveys[index].updatedCO.companyName === coName) {
          const day = parseInt(curSurveys[index].updatedCO.day - 1);
          monResults[day] = curSurveys[index].updatedCO.totDecl;
        }
        else {
          coID = curSurveys[index].updatedCO.idNo;
          coName = curSurveys[index].updatedCO.companyName;
          coStatus = curSurveys[index].updatedCO.status;
          const day = parseInt(curSurveys[index].updatedCO.day - 1);
          monResults[day] = curSurveys[index].updatedCO.totDecl;
        }
      };

      monthResults.push(new monSurvey(coID, coName, coStatus, [...monResults]));

      dispatch({
        type: FETCH_MONTHLY_STATS,
        allCoStats: monthResults,
        maxDays: maxDays
      }
      );
    }
    catch (err) {
      dispatch({
        type: COMPANY_FAIL,
        error: err
      });
    }
  };
};

