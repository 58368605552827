import React from 'react'
import BodyTextBackground from './UI/BodyTextBackground';

const Info = () => {
    
  return (
    <div className="wrapper">
        <div className="containerFlex  main-wrapper">
            <div className="filler">
            </div>
            <BodyTextBackground>
                <p className="h3">Handling a Positive COVID-19 Diagnosis</p>
                <p>Even though staying home, boosting your immune system, social distancing, 
                wearing a mask and maintaining strict hygiene practices, 
                reduces your chances of coming into contact with the virus, 
                your chances of contracting COVID-19 is never zero.  
                Below is some information that hopefully will be helpful in the event that you, 
                or a member of your household, contract COVID-19.
                </p>

                <p className="h3">Consult your doctor</p>
                <p>If you strongly suspect that you might have been exposed to the COVID-19 virus 
                the first thing that you can do is assess yourself for any of the early on-set 
                symptoms which includes flu-like symptoms, fever and loss of your sense of smell.
                
                If you are feeling ill then you can consult your doctor and follow the steps below:</p>
                <div className="list-left">
                    <li>Consult your medical professional via telephone or video call and abide by his / her instruction</li>
                    <li>Have a COVID-19 test done as soon as possible (with your doctor/nurse’s reference)</li>
                </div>

                 
                <p className="h3">If your results come back positive:</p>
                <div className="list-left">
                    <li>Inform all people that you came into contact within that time frame of your positive diagnosis</li>
                    <li>Inform your employer of your positive COVID-19 test results</li>
                    <li>Self-isolate</li>
                </div>
        
                <p className="h3">Have a contingency plan</p>
                <p>It is important to plan ahead in the event that you or a member of your household 
                contracts COVID-19. Once they receive the positive diagnosis, 
                they will have to self-isolate for the obligatory 14 days so as not to spread the virus. 
                To prepare for this, you need to consider the following:

                Identify a specific room or part of the house or separate location where the ill person can self-isolate.
                If this is not possible, the infected person should wear a clean cloth mask at all times.
                Use separate facilities. If this is not possible, make sure that the facilities are cleaned each time the ill person uses them.
                Anyone over 60 years old or other high-risk people will need to be strictly separated from the ill person.
                Arrangements must be made for them to stay elsewhere where possible.
                Plan who will complete necessary tasks, like cooking or laundry, should someone fall ill.
                Plan ahead who will take care of children and/or pets should anyone fall ill.
                Stock up on all items that will be needed for the 14 days including: chronic medicines, 
                paracetamol, throat spray, toiletries and enough non-perishable foods.</p>
      
                <p className="h3">If you live alone, be sure to:</p>
                <p>have someone check in on you at least once a day via phone or video call or text.
                give someone you trust a set of keys should you be incapacitated and need assistance.
                ask someone to buy everything you will need for the 14 days and leave them outside for you or use contactless delivery services.
                Remember to monitor symptoms closely. 
                Call your doctor if symptoms get worse or have not improved after 7 days. 
                Only leave home to seek medical assistance if needed. 
                Although 80% of people who contract the virus are only likely to be mildly affected, 
                it is best to plan for the worst-case scenario of having to be hospitalised.</p>

                <p className="h3">COVID-19 emergencies: </p>
                <p className="h4">When to call an ambulance</p>
                <p>Keep the following details in a set place and give a copy to your emergency contact:</p> 
                <div className="list-left">
                    <li>Doctor’s contact details</li>
                    <li>Emergency evacuation contact: ER24</li>
                    <li>The nearest designated hospital</li>
                    <li>Medical Aid membership and contact details</li>
                    <li>A list of chronic medicines any household member is taking</li>
                </div>
                <p className="h4"> Call emergency services (ER24) immediately if the household member with COVID-19:</p>
                <div className="list-left">
                        <li>has trouble breathing</li>
                        <li>has chest pain or continuous pressure in your chest</li>
                        <li>is coughing up blood</li>
                        <li>is becoming confused</li>
                        <li>Is experiencing severe sleepiness</li>
                        <li>has blue lips or face</li>
                </div>
                <p className="h4">The caller must give details of :- </p>
                <div className="list-left">
                        <li>the emergency</li>
                        <li>notify the hospital or emergency service of the confirmed / suspected case of COVID-19.</li>
                        <li>give their name and contact details</li>
                        <li>the exact location</li>
                </div> 
            </BodyTextBackground>
            <div className="filler">
            </div>
        </div>
    </div>
  )
}

export default Info
