import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Spinner from '../components/UI/Spinner';
import './Form.css';
import Input from '../components/UI/Input';
import { createCompany, checkBlockExists, resetBlock } from '../dataStore/CompanyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import BodyTextBackground from './UI/BodyTextBackground';

class CompanyData extends Component {
    state = {
        companyForm: {
            companyName: {
                label: 'Company name: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your company name'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            UUID: {
                label: 'NIOH - Universal Unique Identifier',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'NIOH - Universal Unique Identifier'
                },
                value: '',
                validation: {},
                valid: true,
                touched: false
            },
            contactFirstName: {
                label: 'Contact person first name/s: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Contact person first name/s (Main user)'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            contactSurname: {
                label: 'Contact person surname: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Contact person surname'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            idNo: {
                label: 'Contact person ID number: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Contact person ID number 13 digits'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 13,
                    maxLength: 13,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            telNo: {
                label: 'Landline number: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Landline number - 10 digits (or second Mobile)'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
            cellNo: {
                label: 'Mobile number: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Mobile number - 10 digits'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            coEmail: {
                label: 'Company contact E-Mail: *',
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Company contact E-Mail'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            address: {
                label: 'Street address: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Street address'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            suburb: {
                label: 'Suburb: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Suburb'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            postalCode: {
                label: 'Postal Code: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Postal Code'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            district: {
                label: 'District: *',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'District', displayValue: 'District' },
                        { value: 'Alfred Nzo', displayValue: 'Alfred Nzo' },
                        { value: 'Amajuba', displayValue: 'Amajuba' },
                        { value: 'Amathole', displayValue: 'Amathole' },
                        { value: 'Bojanala', displayValue: 'Bojanala' },
                        { value: 'Buffalo City', displayValue: 'Buffalo City' },
                        { value: 'Cape Winelands', displayValue: 'Cape Winelands' },
                        { value: 'Capricon', displayValue: 'Capricon' },
                        { value: 'Central Karoo', displayValue: 'Central Karoo' },
                        { value: 'Chris Hani', displayValue: 'Chris Hani' },
                        { value: 'City of Cape Town', displayValue: 'City of Cape Town' },
                        { value: 'City of Johannesburg', displayValue: 'City of Johannesburg' },
                        { value: 'City of Tshwane', displayValue: 'City of Tshwane' },
                        { value: 'Dr Kenneth Kaunda', displayValue: 'Dr Kenneth Kaunda' },
                        { value: 'Dr Ruth Segomotsi Mompati', displayValue: 'Dr Ruth Segomotsi Mompati' },
                        { value: 'Ehlazeni', displayValue: 'Ehlazeni' },
                        { value: 'Ekurhuleni', displayValue: 'Ekurhuleni' },
                        { value: 'eThekwini', displayValue: 'eThekwini' },
                        { value: 'Fezile Dabi', displayValue: 'Fezile Dabi' },
                        { value: 'Frances Baard', displayValue: 'Frances Baard' },
                        { value: 'Garden Route', displayValue: 'Garden Route' },
                        { value: 'Gert Sibande', displayValue: 'Gert Sibande' },
                        { value: 'Harry Gwala', displayValue: 'Harry Gwala' },
                        { value: 'iLembe', displayValue: 'iLembe' },
                        { value: 'Joe Gqabi', displayValue: 'Joe Gqabi' },
                        { value: 'John Taolo Gaetsewe', displayValue: 'John Taolo Gaetsewe' },
                        { value: 'King Centshwayo', displayValue: 'King Centshwayo' },
                        { value: 'Lejweleputswa', displayValue: 'Lejweleputswa' },
                        { value: 'Mangaung', displayValue: 'Mangaung' },
                        { value: 'Mopani', displayValue: 'Mopani' },
                        { value: 'Namakwa', displayValue: 'Namakwa' },
                        { value: 'Nelson Mandela Bay', displayValue: 'Nelson Mandela Bay' },
                        { value: 'Ngaka Modiri Molema', displayValue: 'Ngaka Modiri Molema' },
                        { value: 'Nkangala', displayValue: 'Nkangala' },
                        { value: 'OR Tambo', displayValue: 'OR Tambo' },
                        { value: 'Overberg', displayValue: 'Overberg' },
                        { value: 'Pixley ka Seme', displayValue: 'Pixley ka Seme' },
                        { value: 'Sarah Baartman', displayValue: 'Sarah Baartman' },
                        { value: 'Sedibeng', displayValue: 'Sedibeng' },
                        { value: 'Sekhukhune', displayValue: 'Sekhukhune' },
                        { value: 'Thabo Mofutsanyane', displayValue: 'Thabo Mofutsanyane' },
                        { value: 'Ugu', displayValue: 'Ugu' },
                        { value: 'Umgungundlovu', displayValue: 'Umgungundlovu' },
                        { value: 'Umkhanyakude', displayValue: 'Umkhanyakude' },
                        { value: 'Umzinyathi', displayValue: 'Umzinyathi' },
                        { value: 'Uthukela', displayValue: 'Uthukela' },
                        { value: 'Vhembe', displayValue: 'Vhembe' },
                        { value: 'Waterberg', displayValue: 'Waterberg' },
                        { value: 'West Coast', displayValue: 'West Coast' },
                        { value: 'West rand', displayValue: 'West rand' },
                        { value: 'Xhariep', displayValue: 'Xhariep' },
                        { value: 'ZF Mgcawu', displayValue: 'ZF Mgcawu' },
                        { value: 'Zululand', displayValue: 'Zululand' }
                    ]
                },
                value: 'District',
                validation: {},
                valid: true
            },
            province: {
                label: 'Province: *',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'XX-00', displayValue: 'Province' },
                        { value: 'EC-05', displayValue: 'Eastern Cape' },
                        { value: 'FS-04', displayValue: 'Free State' },
                        { value: 'GP-01', displayValue: 'Gauteng' },
                        { value: 'KN-03', displayValue: 'KwaZulu-Natal' },
                        { value: 'LI-08', displayValue: 'Limpopo' },
                        { value: 'MP-07', displayValue: 'Mpumalanga' },
                        { value: 'NC-09', displayValue: 'Northern Cape' },
                        { value: 'NW-06', displayValue: 'North West' },
                        { value: 'WC-02', displayValue: 'Western Cape' }
                    ]
                },
                value: 'Province',
                validation: {},
                valid: true
            },
            country: {
                label: 'Country: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Country'
                },
                value: 'South Africa',
                validation: {},
                valid: true,
                touched: false
            },
            empNo: {
                label: 'Number of current employees: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Number of current employees'
                },
                value: '',
                validation: {
                    required: true,
                    isNumeric: true
                },
                valid: false,
                touched: false
            },
            visitorNo: {
                label: 'Number of expected visitors per month: *',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Number of expected visitors per month'
                },
                value: '',
                validation: {
                    isNumeric: true
                },
                valid: true,
                touched: false
            },
        },
        companyNo: '',
        blockNo: '',
        companyType: 'Alone',
        displayNo: false,
        formIsValid: false,
        error: '',
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetBlock();
    }

    componentDidUpdate() {
        if (this.state.error !== this.props.blockError) {
            this.setState({ error: this.props.blockError })
        };
        if ((this.props.userError !== null)  && (this.state.error !== this.props.userError)) {
            this.setState({ error: this.props.userError })
        };
    }

    companyHandler = (event) => {
        event.preventDefault();

        // if (this.state.formIsValid) {
        //     return
        // }

        const currentCompany = {};
        for (let formElementIdentifier in this.state.companyForm) {
            currentCompany[formElementIdentifier] = this.state.companyForm[formElementIdentifier].value;
        };
        currentCompany.companyType = this.state.companyType;
        currentCompany.blockNo = this.state.blockNo;
        if (currentCompany.blockNo === '' && (currentCompany.companyType === 'Alone' || currentCompany.companyType === 'Owner')) {
            currentCompany.blockNo = currentCompany.companyNo;
        }
        this.props.createCo(currentCompany);
        this.props.history.replace('/coInfo');
    }

    blockHandler = (event) => {
        this.props.checkBlockExists(event.target.value);
        this.setState({ blockNo: event.target.value })
    }


    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^[/\d-+]+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedcompanyForm = {
            ...this.state.companyForm
        };
        const updatedFormElement = {
            ...updatedcompanyForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedcompanyForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedcompanyForm) {
            formIsValid = updatedcompanyForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ companyForm: updatedcompanyForm, formIsValid: formIsValid });
        if (formIsValid && inputIdentifier === 'coEmail') {
            this.props.checkUserExists(this.state.companyForm.coEmail)
        }
    }

    onChangeRadio = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        if (value === 'Unit') {
            this.setState({ displayNo: true })
        } else {
            this.setState({ displayNo: false })
        }
    }


    render() {


        let companyType = (
            <Fragment>
                <div className="radioTop" onChange={this.onChangeRadio}>
                    <div>
                        <input type="radio" value="Alone" defaultChecked name="companyType"
                            onChange={this.onChangeRadio} className="radioSpace" /> Stand Alone
                        <input type="radio" value="Owner" name="companyType"
                            onChange={this.onChangeRadio} className="radioSpace" /> Unit/Block Owner
                    </div>
                    <div>
                        <input type="radio" value="Unit" name="companyType"
                            onChange={this.onChangeRadio} className="radioSpace" /> Part of Office Unit/Block
                    </div>
                </div>

                {/* <select name="company-type" id="companyType">
                    <option value="Alone">Stand Alone</option>
                    <option value="Owner">Block/Unit Owner</option>
                    <option value="Block">Part of Office Block/Unit</option>
                </select> */}
            </Fragment>
        )
        let displayNum = null;
        if (this.state.displayNo === true) {
            displayNum = <div className={"Input"}>
                <input
                    className={"InputElement"}
                    type='text'
                    placeholder='Clinsys Check Point Unit/Block unique number'
                    value={this.state.blockNo}
                    onChange={this.blockHandler} />
                <span style={{ color: "red" }}>{this.state.error}</span>
            </div>
        }
        const formElementsArray = [];
        for (let key in this.state.companyForm) {
            formElementsArray.push({
                id: key,
                config: this.state.companyForm[key]
            });
        }
        let form = (
            <form onSubmit={this.companyHandler}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        label={formElement.config.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
                <div className="btn-containerFlex">
                    <button className="btnPrimary" disabled={!this.state.formIsValid}>Submit</button>
                </div>
            </form>
        );
        if (this.props.loading) {
            form = <Spinner />;
        }
        return (
            <div className="wrapper">
                <div className="containerFlex  main-wrapper">
                    <div className="filler">
                    </div>
                    <BodyTextBackground>
                        <div className="formData">
                            <h2>Enter your Company Details</h2>
                            {/* <h4>All the fields with a * must be filled in</h4> */}
                            <div className="label-left-black">
                                <p>*Required field</p>
                            </div>
                            {companyType}
                            {displayNum}
                            {form}
                        </div>
                    </BodyTextBackground>
                    <div className="filler">
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.companyData.loading,
        userExists: state.companyData.userExists,
        blockFound: state.companyData.blockFound,
        blockError: state.companyData.blockError,
        userError: state.userData.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createCo: (currentCompany) => dispatch(createCompany(currentCompany)),
        checkBlockExists: (blockNo) => dispatch(checkBlockExists(blockNo)),
        resetBlock: () => dispatch(resetBlock()),
        clearCurrentUser: () => dispatch(clearCurrentUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyData);