import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';

import { getCompanyDailyStats, getCompany } from '../dataStore/CompanyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import convertDate from '../components/ConvertDate';
import Spinner from './UI/Spinner';
import ExportToExcel from '../components/ExportToExcel';
import './users/SurveyResults.css'
import '../components/Form.css'

class StatsDay extends Component {
    state = {
        companys: [],
        startDate: new Date(),
        companyType: 'All',
        fileName: '',
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');
        };
        if (this.props.loggedInUser.companyNo ) {
            const surveyDate = convertDate(this.state.startDate);
            this.props.getDailyStats(this.state.companyType, surveyDate);
            const fileName = 'Daily Company Survey ' + surveyDate;
            this.setState({ fileName: fileName });
        };
    }

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
    };
    
    handleChangeDate = date => {
        this.setState({
            startDate: date,
        });
        const surveyDate = convertDate(date);
        const fileName = 'Daily Company Survey ' + surveyDate;
        this.setState({ fileName: fileName });
        this.props.getDailyStats(this.state.companyType, surveyDate)
    }

    onChangeRadio = (event) => {
        console.log('in change company type')
        const { name, value } = event.target;
        this.setState({ [name]: value });
        const surveyDate = convertDate(this.state.startDate);
        this.props.getDailyStats(event.target.value, surveyDate)
    }

    renderTableData() {
        if (this.props.companyDailyStats && !this.props.coLoading) {
            // console.log('daystats co = ', this.props.companyDailyStats)
            return this.props.companyDailyStats.map((company, index) => {
                const { ID, BlockName, CompanyName, status, TotDeclarations, TempAbove, Contact, Positive, 
                    Fever, Chills, Breathing, Cough, Taste, Throat, BodyAche, Nausea, Tiredness, Eyes,
                    Diarrhoea, Dizziness, Headache} = company
                return (
                    <tr key={ID} >
                        <td className="font-size width50">{BlockName}</td>
                        <td className="font-size width50">{CompanyName}</td>
                        <td>{status}</td>
                        <td>{TotDeclarations}</td>
                        <td>{TempAbove}</td>
                        <td>{Contact}</td>
                        <td>{Positive}</td>
                        <td>{Fever}</td>
                        <td>{Chills}</td>
                        <td>{Cough}</td>
                        <td>{Throat}</td>
                        <td>{Breathing}</td>
                        <td>{Tiredness}</td>
                        <td>{Taste}</td>
                        <td>{Eyes}</td>
                        <td>{Diarrhoea}</td>
                        <td>{BodyAche}</td>
                        <td>{Nausea}</td>
                        <td>{Dizziness}</td>
                        <td>{Headache}</td>
                    </tr>
                )
            })
        }
    }

    renderTableHeader() {
        if (this.props.companyDailyStats.length === 0) {
            return null
        } else
            if (this.props.companyDailyStats && !this.props.coLoading) {
                let header = Object.keys(this.props.companyDailyStats[0])

                header = ['Block Name', 'Company Name', 'Status', 'total no Surveys', 'above 37.5°C',];
                for (let i = 0; i < this.props.questions.length; ++i) {
                    header.push(this.props.questions[i].title);
                }
                return header.map((key, index) => {
                    const colTitle = key[0].toUpperCase() + key.substring(1);
                    return <th key={index}>{colTitle}</th>
                })
            }
    }


    render() {
        let dateArea = <Spinner />;

        if (!this.props.coLoading) {
            dateArea = (
                <div className="date">
                <h2 className="centred" id='titledate'>Click box to Select Date:</h2>
                    <DatePicker className="date-picker"
                        selected={this.state.startDate}
                        onChange={e => this.handleChangeDate(e)}
                        name="startDate"
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
            );
        }
        let companyType = (
            <Fragment>
                <div className="radioTop" onChange={this.onChangeRadio}>
                    <input type="radio" value="All" defaultChecked name="companyType"
                        onChange={this.onChangeRadio} className="radioSpace" /> ALL Companies
                    <input type="radio" value="Alone" name="companyType"
                        onChange={this.onChangeRadio} className="radioSpace" /> Stand Alone
                    <input type="radio" value="Owner" name="companyType"
                        onChange={this.onChangeRadio} className="radioSpace" /> Unit/Block Owner
                    <input type="radio" value="Unit" name="companyType"
                        onChange={this.onChangeRadio} className="radioSpace" /> Part of Unit/Office Block
                </div>
            </Fragment>
        )


        let surveyMonthTable = null;
        if (!this.props.coLoading) {
            surveyMonthTable = (
                <tbody>
                    <tr>{this.renderTableHeader()}</tr>
                    {this.renderTableData()}
                </tbody>
            );
        }

        let message = null;
        if (this.props.companyDailyStats.length === 0) {
            message = <p className="message">No results - Must be the weekend!!</p>
        }

        return (
            <Fragment>
                <div className="right" >
                    <ExportToExcel csvData={this.props.companyDailyStats} fileName={this.state.fileName} />
                </div>
                <h1 id='title'>Daily Survey Results for All Companys</h1>
                {dateArea}
                {companyType}
                {message}
                <table className='table' id='surveys'>
                    {surveyMonthTable}
                </table>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        companyDailyStats: state.companyData.companyDailyStats,
        coLoading: state.companyData.loading,
        loggedInUser: state.userData.loggedInUser,
        questions: state.surveyData.questions,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getDailyStats: (companyType, selectedDate) => dispatch(getCompanyDailyStats(companyType, selectedDate)),
    getCompany: (company) => dispatch(getCompany(company)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatsDay);
