import React, { Component } from "react";
import { connect } from "react-redux";

import { loginUser, checkUserExists } from "../../dataStore/UserActions";
import {
    auth, verifyEmail,
    forgotPassword, getAccountInfo,
    resetAuthLoading, logoutUser
} from "../../dataStore/AuthActions";
import { createSurvey, getSurvey, loadQuestions, resetSurveyLoading } from "../../dataStore/SurveyActions";
import { getCompany } from "../../dataStore/CompanyActions";
import getCurrentDate from "../../components/CurrentDate";
import {clearCurrentUser, clearLoginUser} from '../../dataStore/UserActions';
import BodyTextBackground from '../UI/BodyTextBackground';


class SignIn extends Component {
    state = {
        email: "",
        password: "",
        newPassword: "",
        message: "",
        emailHasBeenSent: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetAuthLoading();
        this.props.resetSurveyLoading();
        this.props.clearCurrentUser();
        this.props.clearLoginUser();
        this.props.clearAuth();
        this.props.loadQuestions();
        this.setState({ email: 'email' });
        this.setState({ message: '' });
    };

    componentDidUpdate() {
        if (this.props.userLoading){
            return;
        };
        
        if (this.props.emailVerified === false ) {
            setTimeout(() => {
                this.setState({ message: null });
                this.setState({ email: 'email' });
            }, 10000);
        };
        if (this.props.emailVerified === false && (!this.state.emailHasBeenSent)) {
            this.setState({
                message:
                    "You should soon receive an email. Please verify your email address" +
                    `\n\n Please make sure to check your spam and trash if you can't find the email.`,
            });
            this.setState({ email: 'email' });
            this.setState({ emailHasBeenSent: true });
            this.props.verifyEmail(this.props.tokenId);
            setTimeout(() => {
                this.props.history.replace("/home");
            }, 20000);
            return;
        };
        if (this.props.currentUser.email === this.state.email) {
            const curDate = getCurrentDate();
            const surveyDate = curDate.slice(6, 16);
            this.props.checkUserExists(this.props.currentUser.email);
            this.props.getAccountInfo(this.props.tokenId);
            if (this.props.emailVerified){
                if (surveyDate === this.props.currentUser.lastSurveyDate) {
                    this.props.getSurvey(this.props.currentUser.companyNo, surveyDate, this.props.currentUser.lastSurveyID);
                    this.props.getCompany(this.props.currentUser.companyNo);
                    this.props.history.replace("/complete");
                } else {
                    this.props.createSurvey(this.props.currentUser);
                    this.props.history.replace("/survey");
                };
            };
        };
    };

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
        this.setState({ message: null });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.state.email === '' || this.state.password === '') {
            this.setState({message: "Please enter a valid email address and password"});
            return;
        };
        await this.props.signIn(this.state.email, this.state.password);
        if (this.props.authError) {
            return;
        };
        this.props.loginUser(this.state.email);
    };

    sendResetEmail = (event) => {
        event.preventDefault();
        if (this.state.email === '') {
            this.setState({message: "Please enter a valid email address"});
            return;
        };
        this.props.forgotPassword(this.state.email);
        this.props.resetAuthLoading();
        this.setState({
            message:
                "You should soon receive an email allowing you to reset your password" +
                `\n\n Please make sure to check your spam and trash if you can't find the email.`,
        });
    };

    render() {
        let form = (
            <form className="form " onSubmit={this.handleSubmit}>
                <p className="h3">Sign In</p>
                {this.state.emailHasBeenSent && (
                    <div className="message-general">An email has been sent to you!</div>
                )}
                <div className="input-field">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" onChange={this.handleChange} />
                </div>
                <div className="input-field">
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" onChange={this.handleChange} />
                </div>
                {/* ******* code to display depending on variable
            {this.state.changePass && (
            <div className="input-field">
                <label htmlFor="newPassword">New Password</label>
                <input type="password" id='newPassword' onChange={this.handleChange} />
            </div>
            )} */}
                <div className="error-text">
                    {this.props.authError ? <p>{this.props.authError}</p> : null}
                </div>
                <div className="error-text">
                    {this.state.message ? <p>{this.state.message}</p> : null}
                </div>
                <div className="containerFlex">
                    <button
                        className="btnText"
                        style={{ width: 200 }}
                        type="button"
                        onClick={this.sendResetEmail}>
                        Forgotten Password
                    </button>
                    <button className="btnPrimary"
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        Sign In
                    </button>
                </div>
            </form>
        );

        let errorMessage = this.props.authError;
        if (errorMessage === null) {
            errorMessage = this.state.message;
        };

        return (
            <div className="flexGrow">
                {errorMessage}
                <div className="containerFlex  main-wrapper">
                    <div className="filler"></div>
                    <BodyTextBackground>{form}</BodyTextBackground>
                    <div className="filler"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.userData.currentUser,
        userLoading: state.userData.loading,
        authLoading: state.authData.loading,
        authError: state.authData.error,
        tokenId: state.authData.token,
        userExists: state.userData.userExists,
        emailVerified: state.authData.emailVerified,
        changeSuccess: state.authData.changedPassword,
        signedIn: state.userData.signedIn,
        questions: state.surveyData.questions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCurrentUser: () => dispatch(clearCurrentUser()),
        clearLoginUser: () => dispatch(clearLoginUser()),
        signIn: (email, password) => dispatch(auth(email, password, false)),
        forgotPassword: (email) => dispatch(forgotPassword(email)),
        loginUser: (email) => dispatch(loginUser(email)),
        resetAuthLoading: () => dispatch(resetAuthLoading()),
        resetSurveyLoading: () => dispatch(resetSurveyLoading()),
        loadQuestions: () => dispatch(loadQuestions("")),
        getAccountInfo: (tokenId) => dispatch(getAccountInfo(tokenId)),
        verifyEmail: (tokenId) => dispatch(verifyEmail(tokenId)),
        getCompany: (companyNo) => dispatch(getCompany(companyNo)),
        createSurvey: (currentUser) => dispatch(createSurvey(currentUser)),
        getSurvey: (companyNo, surveyDate, LastSurveyID) =>
            dispatch(getSurvey(companyNo, surveyDate, LastSurveyID)),
        checkUserExists: (email) => dispatch(checkUserExists(email, false)),
        clearAuth: () => dispatch(logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
