import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {connect} from "react-redux";

import {fetchUsers, getSelectedUser, deleteUser, clearCurrentUser} from '../../dataStore/UserActions';
import {resetSurveyLoading} from '../../dataStore/SurveyActions';
import Spinner from '../../components/UI/Spinner';
import getCurrentDate from '../../components/CurrentDate';
import BodyTextBackground from '../UI/BodyTextBackground';
import Dustbin from '../../Assets/Dustbin.png';

const tick = '✓';
const modifiedDate = getCurrentDate();
const surveyDate = modifiedDate.slice(6, 16);

class UserList extends Component {
    state = {
        deleteUserInd: false,
    };
    
    componentDidMount () {
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');   
        };
        this.props.getAllUsers(this.props.loggedInUser.companyNo);
    };
    
    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
    };

    userSelectedHandler = (id) => {
        if (this.state.deleteUserInd) {
            this.setState({deleteUserInd : false});
            return;
        };
        this.props.getSelectedUser(this.props.loggedInUser.companyNo,id);
        this.props.history.push( '/user' );
    };
  
    userDeleteHandler = (id, email, idNo) => {
        this.props.deleteUser(this.props.loggedInUser.companyNo, email, idNo, id);
        this.setState({deleteUserInd : true});
        this.props.history.push( '/users' );
    };    

    render() {  

        const {curUsers} = this.props;

        let allUsersList = <Spinner />;
        if (!this.props.loading) {

            allUsersList = curUsers.length ? (
            curUsers.map(user => {
            let userSurvey = '-'
            if (surveyDate === user.lastSurveyDate) {
                userSurvey=tick
            }
            return (
            <div className="list-wrapper" key={user.id}>
                <div className="user-items">
                    <div id="user-name" >
                        <Link to={'/user/' + user.id} style={{ textDecoration: 'none' }}>
                            <div className="user-column"
                                    key={user.id} 
                                    onClick={() => {this.userSelectedHandler(user.id)}}>
                                <p className="user-name">{user.firstName}</p>
                                <p className="user-name">{user.surname} </p>
                                <p className="user-name">{user.cellNo} </p>
                            </div>
                        </Link>
                    </div>
                    <div className="user-survey" >
                        <p className="vertical-center">{userSurvey}</p>
                    </div>
                    <div className="vertical-center">
                        <img src={Dustbin} alt="delete" 
                            width="17" height="21"
                            onClick={() => {this.userDeleteHandler(user.id, user.email, user.idNo)}}></img>
                    </div>
                </div>
            </div>
            )
        })
    ) : (
        <p className="center">You have no user's left, OOPS!</p>
        );}


    return (
        <div className="wrapper">
            <div className="containerFlex  main-wrapper">
                <div className="filler">
                </div>
                <BodyTextBackground>
                    <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>    
                    <div className="radioTop" style={{marginBottom:30}}>
                        <p className="h2">All Users</p>
                    </div>
                    {allUsersList}
                </BodyTextBackground>
                {/* <Survey/> */}

                <div className="filler">
                </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        curUsers: state.userData.availableUsers,
        loggedInUser: state.userData.loggedInUser,
        loading: state.userData.loading,
        companyDetails : state.companyData.companyDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAllUsers: (company) => dispatch(fetchUsers(company)),
    getSelectedUser: (company, id) => dispatch(getSelectedUser(company, id)), 
    deleteUser: (company, email, idNo, id) => dispatch(deleteUser(company, email, idNo, id)), 
    resetSurveyLoading: () => dispatch(resetSurveyLoading()), 
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
