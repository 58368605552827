import React from 'react'
import LogoBanner from '../Assets/LogoBanner.png'

const Title = () => {
  return (
    <div className="main-banner">
        <img className="logo-banner" src={LogoBanner} alt="Welcome to Check Point" />
    </div>
  )
};

export default Title