import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {logoutUser}  from '../dataStore/AuthActions';
import {clearCurrentUser, clearLoginUser}  from '../dataStore/UserActions';

class Logout extends Component {
    componentDidMount () {
        this.props.clearCurrentUser();
        this.props.clearLoginUser();
        this.props.onLogout();
    }

    render () {
        return <Redirect to="/home"/>;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(logoutUser()),
        clearCurrentUser: () => dispatch(clearCurrentUser()),
        clearLoginUser: () => dispatch(clearLoginUser()),
    };
};

export default connect(null, mapDispatchToProps)(Logout);