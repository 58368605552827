import React from 'react'
import BodyTextBackground from './UI/BodyTextBackground';

const coRegistered = () => {
  return (
        <div className="wrapper">
            <div className="containerFlex  main-wrapper">
                <div className="filler">
                </div>
                <BodyTextBackground>
                    <p className='h3'>Thank you for registering with us</p>
                    <p>A consultant will be in contact with you to get your company on board</p>
                    <br />
                    <br />
                    <p>When we receive payment you will receive your unique CheckPoint company code.</p>
                    <p>This CheckPoint company code will be used by anyone logging into your personal system</p>
                    <br />
                    <p>For more information please contact</p>
                    <p>Caroline Mathew: +27 84 580 4016</p>
                    <p>or</p>
                    <p>Gina Phillips: +27 82 674 0034</p>
                    <br />
                    <p>email: clinsys@twinsolutions.co.za</p>
                </BodyTextBackground>
                <div className="filler">
                </div>
            </div>
        </div>
  )
}

export default coRegistered