import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import { fetchCompanys, getCompany } from '../dataStore/CompanyActions';
import {clearCurrentUser}  from '../dataStore/UserActions';
import Spinner from '../components/UI/Spinner';
import BodyTextBackgroundWide from './UI/BodyTextBackgroundWide';

class CompanyList extends Component {
    state = {
        companyType: 'All',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getAllCompanys();
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');
        };
    };

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
    };

  companySelectedHandler = (companyNo) => {
    this.props.getCompany(companyNo);
    this.props.history.replace('/updateCo');
  };

  onChangeRadio = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };


  render() {

    let companyType = (
      <Fragment>
        <div className="radioTop" onChange={this.onChangeRadio}>
            <div>
                <input type="radio" value="All" defaultChecked name="companyType"
                onChange={this.onChangeRadio} className="radioSpace" /> ALL
                <input type="radio" value="Alone" name="companyType"
                onChange={this.onChangeRadio} className="radioSpace" /> Stand Alone
            </div>
            <div>
                <input type="radio" value="Owner" name="companyType"
                onChange={this.onChangeRadio} className="radioSpace" /> Unit/Block Owner
                <input type="radio" value="Unit" name="companyType"
                onChange={this.onChangeRadio} className="radioSpace" /> Part of Unit/Office Block
            </div>
        </div>
      </Fragment>
    );

    let allcompanysList = <Spinner />;
    if (!this.props.loading) {
      const curcompanys = [];
      for (let index = 0; index < this.props.curCompanys.length; index++) {
        const element = this.props.curCompanys[index];
        if (this.state.companyType === 'All') {
          curcompanys.push({ ...element })
        } else if (this.state.companyType === element.companyType) {
          curcompanys.push({ ...element })
        }
      };
      allcompanysList = curcompanys.length ? (
        curcompanys.map(company => {
          return (
            <div className="list-wrapper " key={company.id}  >
              <div className="user-items" >
                <div id="user-name" >
                  <Link to={'/payment'} style={{ textDecoration: 'none' }}>
                    <div className="user-column"
                      key={company.id}
                      onClick={() => { this.companySelectedHandler(company.companyNo) }}>
                      <p className="user-name">{company.companyNo}</p>
                      <p className="user-name">{company.companyName}</p>
                      <p className="user-name">{company.suburb} </p>
                      <p className="user-name">{company.contactFirstName} </p>
                      <p className="user-name">{company.contactSurname} </p>
                      <p className="user-name">{company.cellNo} </p>
                      <p className="user-name">{company.status} </p>
                      <p className="user-name">{company.paymentAmount} </p>
                      <p className="user-name">{company.coEmail} </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )
        })
      ) : (
          <p className="center">You have no {this.state.companyType} companies!</p>
        );
    };


    return (
      <div className="wrapper">
        <div className="containerFlex  main-wrapper">
          <div className="filler">
          </div>
          <BodyTextBackgroundWide>
            <p className="h3">All companys</p>
            {companyType}
            {allcompanysList}
          </BodyTextBackgroundWide>
          <div className="filler">
          </div>
        </div>
      </div>
    )
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authData.token !== null,
    curCompanys: state.companyData.allCompanys,
    loading: state.companyData.loading,
    loggedInUser: state.userData.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCompanys: () => dispatch(fetchCompanys()),
  getCompany: (company) => dispatch(getCompany(company)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
