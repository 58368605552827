function dailyExport (FirstName, Surname, Temperature, Contact, Positive, Fever, Chills, Cough, Throat, 
    Breathing, Tiredness, Taste, Eyes, Diarrhoea, BodyAche, Nausea, Dizziness, Headache){
    this.FirstName = FirstName;
    this.Surname = Surname;
    this.Temperature = Temperature;
    this.Contact = Contact;
    this.Positive = Positive;
    this.Fever = Fever;
    this.Chills = Chills;
    this.Cough = Cough;
    this.Throat = Throat;
    this.Breathing = Breathing;
    this.Tiredness = Tiredness;
    this.Taste = Taste;
    this.Eyes = Eyes;
    this.Diarrhoea = Diarrhoea;
    this.BodyAche = BodyAche;
    this.Nausea = Nausea;
    this.Dizziness = Dizziness;
    this.Headache = Headache;
  }

  export default dailyExport;