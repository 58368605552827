import getCurrentDate from '../components/CurrentDate';

export const POSITIVE_USER = 'POSITIVE_USER';
export const LOADING_NIOH = 'LOADING_NIOH';
export const USER_FAIL = 'USER_FAIL';


export const loadingNIOH = () => {
    return {
      type: LOADING_NIOH
    };
};

export const getNIOHpositive = (currentUser) => {
    const companyNo = currentUser.companyNo;
    const firebaseID = currentUser.id;
    let positiveUser = ({
        idNo: currentUser.idNo,
        age: currentUser.email,
        gender: currentUser.gender,
        jobCategory: currentUser.jobCategory,
        province: currentUser.province,
        district: currentUser.district,
        employeeNo: currentUser.employeeNo,
        testDate: getCurrentDate(),
        testType: 'PCR',
        symptoms: 'YES',
        hospital: 'Public',
        result: 'Positive',
        postTest: 'Sick Leave',
        exposure: 'Work contacts',
        reporting: 'Reported to Department of Employment and Labour',
        returnToWork: 'Return to work fitness',
    });
    return async (dispatch) => {
        dispatch(loadingNIOH());
        try {
            let response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${companyNo}/NIOHpositive/${firebaseID}.json`,
                );
                
            const resData = await response.json();
            console.log('resData = ', resData)

            if (resData) {
                 positiveUser = { ...resData };
            };

        dispatch({
            type: POSITIVE_USER,
            positiveUser: positiveUser
          });
        } catch (error) {
        dispatch({
            type: USER_FAIL,
            error: error
        });
        }
    };
};

export const updateNIOHpositive= (positiveUser) => {
    const companyNo = positiveUser.companyNo;
    const firebaseID = positiveUser.firebaseID;       
    const NIOH_UID = positiveUser.NIOH_UID;
    const idNo = positiveUser.idNo;
    const district = positiveUser.district;
    const province = positiveUser.province;
    const gender = positiveUser.gender;
    const age = positiveUser.age;
    const jobCategory = positiveUser.jobCategory;
    const testDate = positiveUser.testDate; 
    const testTypePCR = positiveUser.testTypePCR; 
    const testTypeAntigen = positiveUser.testTypeAntigen; 
    const testTypeAntibody = positiveUser.testTypeAntibody; 
    const symptoms = positiveUser.symptoms; 
    const hospital = positiveUser.hospital; 
    const result = positiveUser.result; 
    const postTest = positiveUser.postTest; 
    const exposure = positiveUser.exposure; 
    const reporting = positiveUser.reporting; 
    const returnToWork = positiveUser.returnToWork; 
    const modifiedDate = getCurrentDate();
    let employeeNo = positiveUser.employeeNo;
    if (employeeNo === '') {
        employeeNo = idNo;
    };

    return async (dispatch) => {
        dispatch(loadingNIOH());
        try {
            let response = await fetch(
            `https://listapp-fcbc4.firebaseio.com/${companyNo}/NIOHpositive/${firebaseID}.json`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({
                    NIOH_UID,
                    employeeNo,
                    idNo,
                    district,
                    province,
                    gender,
                    age,
                    jobCategory,
                    testDate,  
                    testTypePCR, 
                    testTypeAntigen, 
                    testTypeAntibody, 
                    symptoms, 
                    hospital, 
                    result, 
                    postTest,  
                    exposure, 
                    reporting, 
                    returnToWork, 
                    modifiedDate
                })
            }
        );

        let resData = await response.json();
        console.log('update resData = ', resData)

      dispatch({
        type: POSITIVE_USER,
        positiveUser: positiveUser
      });
    } catch (error) {
      dispatch({
        type: USER_FAIL,
        error: error
      });
    }
  };
};

