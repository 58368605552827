import convertDate from '../components/ConvertDate';
import getCurrentDate from '../components/CurrentDate';
import calcAge from '../components/CalcAge';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const UPDATE_ACTIONS = 'UPDATE_ACTIONS';
export const GET_SURVEY = 'GET_SURVEY';
export const LOADING_SURVEY = 'LOADING_SURVEY';
export const FETCH_ALL_SURVEYS = 'FETCH_ALL_SURVEYS';
export const WEEK_SURVEYS = 'WEEK_SURVEYS';
export const MONTH_SURVEYS = 'MONTH_SURVEYS';
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const SURVEY_FAIL = 'SURVEY_FAIL';
export const SURVEY_COMPLETE = 'SURVEY_COMPLETE';


export const loadingSurvey = () => {
    return {
        type: LOADING_SURVEY
    };
};

export const resetSurveyLoading = () => {
    return async (dispatch) => {
        dispatch({ type: 'LOADING_SURVEY_RESET' });
    };
};

export const surveyComplete= () => {
    return async (dispatch) => {
        dispatch({ type: 'SURVEY_COMPLETE' });
    };
};

export const createSurvey = (currentUser) => {
    const modifiedDate = getCurrentDate();
    const surveyTime = modifiedDate.slice(0, 5);
    const surveyDate = modifiedDate.slice(6, 16);
    return async (dispatch) => {
        dispatch(loadingSurvey());
        try {
            if (!currentUser.id) {
                dispatch(resetSurveyLoading());
                return;
            };

            const surveyUser = ({
                id: currentUser.id,
                userEmail: currentUser.email,
                firstName: currentUser.firstName,
                surname: currentUser.surname,
                idNo: currentUser.idNo,
                age: currentUser.age,
                jobCategory: currentUser.jobCategory,
                companyNo: currentUser.companyNo,
                loadedTime: surveyTime,
                loadedDate: surveyDate,
                declaration: false,
                temperature: 0,
                yesChecks: 0
            });
            dispatch({
                type: CREATE_SURVEY,
                userDetails: surveyUser
            });
        } catch (error) {
            dispatch({
                type: SURVEY_FAIL,
                error: error
            });
        }
    };
};


export const updateSurvey = (currentUser, declaration, temp, surveyInfo, questions) => {
    const modifiedDate = getCurrentDate();
    const surveyTime = modifiedDate.slice(0, 5);
    const surveyDate = modifiedDate.slice(6, 16);
    const idNo = currentUser.idNo;
    const DOB = currentUser.DOB;
    const age  = calcAge(DOB);
    const email = currentUser.email;
    const firstName = currentUser.firstName;
    const surname = currentUser.surname;
    const companyNo = currentUser.companyNo;
    let gender = currentUser.gender;
    let jobCategory = currentUser.jobCategory;
    let firebaseID = currentUser.id;
    const check1 = surveyInfo[1];
    const check2 = surveyInfo[2];
    const check3 = surveyInfo[3];
    const check4 = surveyInfo[4];
    const check5 = surveyInfo[5];
    const check6 = surveyInfo[6];
    const check7 = surveyInfo[7];
    const check8 = surveyInfo[8];
    const check9 = surveyInfo[9];
    const check10 = surveyInfo[10];
    const check11 = surveyInfo[11];
    const check12 = surveyInfo[12];
    const check13 = surveyInfo[13];
    const check14 = surveyInfo[14];
    let check15 = surveyInfo[15];
    if (!check15) {
        check15 = 0; 
    };
    if (!gender) {
        gender = 'OTHER'; 
    };
    if (!jobCategory) {
        jobCategory = 'UNKNOWN'; 
    };
    let colour = 'black';
    let temperature = temp;
    temperature = temperature.replace(/,/g, '.');
    temperature = parseFloat(temperature);
    if (temperature >= 37.5) {
        colour = 'red';
    };
    return async (dispatch) => {
        try {
            dispatch(loadingSurvey());
            let modEmail = String(idNo);
            if (email && (email !== '')){modEmail = String(email)};
            modEmail = modEmail.replace(/\./g, "__dot__");
            modEmail = modEmail.replace(/[`~!#$%^&*()|+\-=?;:'",<>\]\\]/gi, '__sc__');
            let response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/ALLUSERS/${modEmail}.json`,
                );
            let resData = await response.json();
            if (resData) {
                let curUsers = [];
                for (const key in resData) {
                    curUsers.push({ ...resData[key] });
                }
                firebaseID = curUsers[0].firebaseID;
            } else { 
                dispatch(resetSurveyLoading());
                return; }
            let yesCount = 0;
            if (temperature >= 37.5) {
                yesCount = 1;
            };
            for (let i = 0; i < 16; ++i) {
                if (surveyInfo[i] === 1) {
                    yesCount++
                    if (colour === 'black') {
                        for (let index = 0; index < questions.length; index++) {
                            if (i + 1 === questions[index].id) {
                                colour = questions[index].colour
                            }
                        }
                    }
                };
            }
            const id = firebaseID;
            
        let url = `https://listapp-fcbc4.firebaseio.com/${companyNo}/${surveyDate}.json`
        let curMethod = 'POST'
        const surveyID = currentUser.lastSurveyID
        if (currentUser.lastSurveyDate === surveyDate) {
            url = `https://listapp-fcbc4.firebaseio.com/${companyNo}/${surveyDate}/${surveyID}.json`
            curMethod = 'PATCH'
        };
            response = await fetch(
                url,
                {
                    method: curMethod,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, idNo, DOB, age, email, firstName, surname, companyNo, gender, jobCategory,
                        surveyTime, surveyDate, declaration, temperature, yesCount,
                        check1, check2, check3, check4, check5, check6,
                        check7, check8, check9, check10, check11, check12, 
                        check13, check14, check15, colour
                    })
                }
                );
                    
                resData = await response.json();
                let mess = '';
                if (!response.ok) {
                    if (response.status === 503) {
                        mess = 'Unable to connect to server! \n You will need to upload later \n when you have data or WIFI connnection';
                    } else {
                        mess = 'Error has occured updating info to server';
                    }
                };
                
                if (curMethod === 'POST') {
                    const lastSurveyDate = surveyDate;
                    const lastSurveyID = resData.name;
                    let userType = currentUser.userType;
                    if (email === 'gina@softsense.co.za' || (email === 'checkpoint@twinsolutions.co.za') || (email === 'clinsys@twinsolutions.co.za') || (email === 'gina7.phillips@gmail.com') ) {
                        userType = 5;
                    };
                        
                    response = await fetch(
                        `https://listapp-fcbc4.firebaseio.com/${companyNo}/USERS/${firebaseID}.json`,
                        {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                lastSurveyDate,
                                lastSurveyID,
                                userType,
                                modifiedDate
                            })
                        }
                        );
                    resData = await response.json();  
                };

                const updatedUser = ({
                    id: firebaseID,
                    userEmail: currentUser.email,
                    firstName: currentUser.firstName,
                    surname: currentUser.surname,
                    companyNo: currentUser.companyNo,
                    idNo: currentUser.idNo,
                    loadedTime: surveyTime,
                    loadedDate: surveyDate,
                    declaration: declaration,
                    temperature: temperature,
                    yesChecks: yesCount,
                    colour: colour
                });
                    
                dispatch({
                    type: UPDATE_SURVEY,
                    userDetails: updatedUser,
                    todaysChecks: surveyInfo
                });
                if (mess !== '') {
                    throw new Error(mess);
                }
            } catch (error) {
                dispatch({
                    type: SURVEY_FAIL,
                    error: error
                });
            }
        };
    };
            
   

export const updateActions = (companyNo, surveyDate, surveyID, actionInfo, consent) => {
    const action1 = actionInfo[0];
    const action2 = actionInfo[1];
    const action3 = actionInfo[2];
    const action4 = actionInfo[3];
    if (surveyID === undefined) {
        console.log('****PROBLEM***** SurveyID = undefined')
        return
    }
    return async (dispatch) => {
        try {
            dispatch(loadingSurvey());
            let response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/${companyNo}/${surveyDate}/${surveyID}.json`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            action1, action2, action3, action4, consent
                        })
                    }
                    );
                    
                    await response.json();
                    dispatch(weekSurveys(companyNo, surveyDate));
                    
                    dispatch({
                        type: UPDATE_ACTIONS,
                        actions: actionInfo
                    });
                } catch (error) {
                    dispatch({
                        type: SURVEY_FAIL,
                        error: error
                    });
                }
            };
        };
            
            
export const getSurvey = (companyNo, surveyDate, surveyID) => {
    if (surveyID === undefined) {
        return
    };
    return async (dispatch) => {
        dispatch(loadingSurvey());
        try {
            const response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${companyNo}/${surveyDate}/${surveyID}.json`,
                );
                
            const resData = await response.json();
            
            if (!response.ok) {
                let mess = '';
                if (response.status === 503) {
                    mess = 'Unable to connect to server! \n You will need to upload later \n when you have data or WIFI connnection';
                } else {
                    mess = 'Error has occured updating info to server';
                }
                throw new Error(mess);
            };

            const currentInfo = { ...resData };
            const updatedUser = ({
                id: surveyID,
                userEmail: currentInfo.email,
                firstName: currentInfo.firstName,
                surname: currentInfo.surname,
                companyNo: currentInfo.companyNo,
                idNo: currentInfo.idNo,
                loadedTime: currentInfo.surveyTime,
                loadedDate: currentInfo.surveyDate,
                declaration: currentInfo.declaration,
                temperature: currentInfo.temperature,
                yesChecks: currentInfo.yesCount,
                colour: currentInfo.colour
            });

            const surveyInfo = Array(15).fill(0);
            for (let checkIdentifier in currentInfo) {
                if (checkIdentifier.includes('check')) {
                    const checkIndex = checkIdentifier.slice(5);
                    surveyInfo[checkIndex] = currentInfo[checkIdentifier];
                }
            };

            dispatch({
                type: GET_SURVEY,
                userDetails: updatedUser,
                curChecks: surveyInfo
            });
        } catch (error) {
            dispatch({
                type: SURVEY_FAIL,
                error: error
            });
        }
    };
};


function sortAlphabetically(a, b) {
    const surnameA = a.surname[0].toUpperCase() + a.surname.substring(1);
    const surnameB = b.surname[0].toUpperCase() + b.surname.substring(1);
    const a1 =  surnameA+ a.firstName + a.idNo ;
    const b1 = surnameB+ b.firstName + b.idNo ;
    if (a1 < b1) return -1;
    else if (a1 > b1) return 1;
    else return 0;
};

export const fetchSurveys = (company, thisDate) => {
    const tick = '✓';
    return async (dispatch) => {
        dispatch(loadingSurvey());
        try {
            const response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${company}/${thisDate}.json?`,
            );

            if (!response.ok) {
                throw new Error(`Unable to connect to server! ${response.message}`);
            };

            const resData = await response.json();
            let curSurveys = [];
            for (const key in resData) {
                if (resData[key].surname !== undefined) {
                    curSurveys.push({
                        ...resData[key],
                        id: key
                    }
                    );
                }
            };
            for (let i = 0; i < curSurveys.length; ++i) {
                for (let identifier in curSurveys[i]) {
                    if (identifier.includes('check')) {
                        if ((curSurveys[i][identifier] === 1) || (curSurveys[i][identifier] === true)) {
                            curSurveys[i][identifier] = tick;
                        } else {
                            curSurveys[i][identifier] = ''
                        }
                    }
                };
            };

            await curSurveys.sort(sortAlphabetically);

            dispatch({
                type: FETCH_ALL_SURVEYS,
                allSurveys: curSurveys,
            }
            );
        }
        catch (err) {
            dispatch({
                type: SURVEY_FAIL,
                error: err
            });
        }
    };
};


export const weekSurveys = (company, thisDate) => {
    return async (dispatch) => {
        dispatch(loadingSurvey());
        try {
            const response = await fetch(
                `https://listapp-fcbc4.firebaseio.com/${company}/${thisDate}.json?`,
            );

            if (!response.ok) {
                throw new Error(`Unable to connect to server! ${response.message}`);
            };

            let resData = await response.json();
            let curSurveys = [];
            for (const key in resData) {
                if (resData[key].yesCount > 0) {
                    let foundSymptoms = true;
                    if (resData[key].yesCount === 1) {
                        if (resData[key].check1 || resData[key].check2) {
                            foundSymptoms = false;
                        };
                    };if (resData[key].yesCount === 2) {
                        if (resData[key].check1 && resData[key].check2) {
                            foundSymptoms = false;
                        };
                    };
                    if (resData[key].temperature >= 37.5) {
                        resData[key].check3 = true;
                    };
                    if (foundSymptoms) {
                        curSurveys.push({
                            ...resData[key],
                            id: key
                        });
                    };
                };
            }

            await curSurveys.sort(sortAlphabetically);

            dispatch({
                type: FETCH_ALL_SURVEYS,
                allSurveys: curSurveys,
            }
            );
        }
        catch (err) {
            dispatch({
                type: SURVEY_FAIL,
                error: err
            });
        }
    };
};


function monSurvey(idNo, fullName, avgTemp, result, colour) {
    this.idNo = idNo;
    this.fullName = fullName;
    this.avgTemp = avgTemp;
    this.result = result;
    this.colour = colour;
};

function sortAlphabeticallyAscending(a, b) {
    const surnameA = a.surname[0].toUpperCase() + a.surname.substring(1);
    const surnameB = b.surname[0].toUpperCase() + b.surname.substring(1);
    const a1 = a.companyNo + surnameA + a.firstName + a.idNo + a.day;
    const b1 = b.companyNo + surnameB + b.firstName + b.idNo + b.day;
    if (a1 < b1) return -1;
    else if (a1 > b1) return 1;
    else return 0;
};

function sortDateAscending(a, b) {
    let year = a.surveyDate.slice(6);
    let month = a.surveyDate.substr(3, 2);
    let day = a.surveyDate.substr(0, 2);
    let toDate = year + month + day;
    const a1 = toDate + a.idNo;
    year = b.surveyDate.slice(6);
    month = b.surveyDate.substr(3, 2);
    day = b.surveyDate.substr(0, 2);
    toDate = year + month + day;
    const b1 = toDate + b.idNo;
    if (a1 < b1) return -1;
    else if (a1 > b1) return 1;
    else return 0;
};

export const updateSurveyWeekUser = (companyNo, startDate, surname, firstName, id, age, idNo, gender, jobCategory) => {
    let startMonth = new Date();
    // get date 3 months ago
    startMonth.setMonth(startMonth.getMonth() - 3);
    let getDate = convertDate(startMonth);
    startDate.setDate(startDate.getDate() + 1);
    let nextDate = convertDate(startDate);
    const year = nextDate.slice(6);
    const month = nextDate.substr(3, 2);
    const day = nextDate.substr(0, 2);
    const toDate = year + month + day;

    return  async () => {
        try {
            do {
                let response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/${companyNo}/${getDate}.json?`,
                );

                if (!response.ok) {
                    throw new Error(`Unable to connect to server! ${response.message}`);
                };

                let resData = await response.json();
                if (resData) {
                    let currentInfo = [];
                    for (const key in resData) {
                        if (resData[key].id === id ) {
                            currentInfo.push({
                                key: key
                            });
                        }
                    };
                    for (let index = 0; index < currentInfo.length; index++) {
                        const surveyID = currentInfo[index].key;
                        response = await fetch(
                            `https://listapp-fcbc4.firebaseio.com/${companyNo}/${getDate}/${surveyID}.json`,
                            {
                                method: 'PATCH',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    surname, firstName, age, idNo, gender, jobCategory
                                })
                            }
                        );
                        await response.json();
                    };
                };
                startMonth.setDate(startMonth.getDate() + 1);
                getDate  = convertDate(startMonth);
                const year = getDate.slice(6);
                const month = getDate.substr(3, 2);
                const day = getDate.substr(0, 2);
                nextDate = year + month + day;
            }
            while (nextDate < toDate);
            
        }
        catch (err) {
        }
    };
};

export const surveyWeekTable = (companyNo, startDate) => {
    const first  =  startDate.getDate() - startDate.getDay();
    let startWeek = new Date(startDate.setDate(first));
    let getDate = convertDate(startWeek);
    
    return async (dispatch) => {
        dispatch(loadingSurvey());
        try {
            let curSurveys = [];
            for (let i = 1; i < 8; ++i) {
                const response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/${companyNo}/${getDate}.json?`,
                );

                if (!response.ok) {
                    throw new Error(`Unable to connect to server! ${response.message}`);
                };

                const resData = await response.json();
                if (resData) {
                    let currentInfo = [];
                    for (const key in resData) {
                        if (resData[key].yesCount>0) {
                            let foundSymptoms = true;
                            if (resData[key].yesCount === 1) {
                                if (resData[key].check1 || resData[key].check2) {
                                    foundSymptoms = false;
                                };
                            };if (resData[key].yesCount === 2) {
                                if (resData[key].check1 && resData[key].check2) {
                                    foundSymptoms = false;
                                };
                            };
                            if (resData[key].temperature >= 37.5) {
                                resData[key].check3 = true;
                            };
                            if (foundSymptoms) {
                                currentInfo.push({
                                    ...resData[key],
                                    id: key
                                });
                            };
                        };
                    };
                    for (let index = 0; index < currentInfo.length; index++) {
                        let updatedUser = ({
                            idNo: currentInfo[index].idNo,
                            firstName: currentInfo[index].firstName,
                            surname: currentInfo[index].surname,
                            temperature: currentInfo[index].temperature,
                            result: currentInfo[index].yesCount,
                            colour: currentInfo[index].colour,
                            surveyDate: getDate,
                            age: currentInfo[index].age,
                            gender: currentInfo[index].gender,
                            jobCategory: currentInfo[index].jobCategory,
                            check1 : 'NO',
                            check2 : 'NO',
                            check3 : 'NO',
                            check4 : 'NO',
                            check5 : 'NO',
                            check6 : 'NO',
                            check7 : 'NO',
                            check8 : 'NO',
                            check9 : 'NO',
                            check10 : 'NO',
                            check11 : 'NO',
                            check12 : 'NO',
                            check13 : 'NO',
                            check14 : 'NO',
                            check15 : 'NO',
                            action1 : 'NO',
                            action2 : 'NO',
                            action3 : 'NO',
                            action4 : 'NO',
                            consent : 'NO',
                        });
                        for (let identifier in currentInfo[index]) {
                            if (identifier.includes('check')) {
                                if ((currentInfo[index][identifier] === 1) || (currentInfo[index][identifier] === true)) {
                                    updatedUser[identifier] = 'YES';
                                };
                            };
                        };
                        for (let identifier in currentInfo[index]) {
                            if (identifier.includes('action')) {
                                if ((currentInfo[index][identifier] === 1) || (currentInfo[index][identifier] === true)) {
                                    updatedUser[identifier] = 'YES';
                                };
                            };
                        };
                        if ((currentInfo[index].consent === 1) || (currentInfo[index].consent === true)) {
                            updatedUser.consent = 'YES';
                        }
                        curSurveys.push({ ...updatedUser });
                    };
                };
                startWeek.setDate(startWeek.getDate() + 1);
                getDate  = convertDate(startWeek);
            };
            
            await curSurveys.sort(sortDateAscending);

            dispatch({
                type: WEEK_SURVEYS,
                weekSurveys: curSurveys
            }
            );
        }
        catch (err) {
            dispatch({
                type: SURVEY_FAIL,
                error: err
            });
        }
    };
};

export const surveyMonthTable = (companyNo, startDate) => {
    const tick = '✓';

    return async (dispatch) => {
        dispatch(loadingSurvey());
        try {
            const mmYY = startDate.slice(2)
            const curMonth = startDate.slice(3, 5)
            let curSurveys = [];
            for (let i = 1; i < 32; ++i) {
                let numberString = `${i}`;
                if (numberString.length === 1)
                    numberString = `0${i}`;
                const newDate = numberString + mmYY
                const response = await fetch(
                    `https://listapp-fcbc4.firebaseio.com/${companyNo}/${newDate}.json?`,
                );

                if (!response.ok) {
                    throw new Error(`Unable to connect to server! ${response.message}`);
                };

                const resData = await response.json();
                if (resData) {
                    let currentInfo = [];
                    for (const key in resData) {
                        if (resData[key].firstName !== undefined) {
                            currentInfo.push({
                                ...resData[key],
                                key: key
                            });
                        }
                    };
                    
                    for (let index = 0; index < currentInfo.length; index++) {
                        const updatedUser = ({
                            day: numberString,
                            idNo: currentInfo[index].idNo,
                            firstName: currentInfo[index].firstName,
                            surname: currentInfo[index].surname,
                            temperature: currentInfo[index].temperature,
                            result: currentInfo[index].yesCount,
                            colour: currentInfo[index].colour
                        });
                        if (updatedUser.result === 0) {
                            updatedUser.result = tick;
                        };
                        curSurveys.push({ ...updatedUser });
                    }
                }
            };
            await curSurveys.sort(sortAlphabeticallyAscending);

            let maxDays = 30;
            if (curMonth === '01' || curMonth === '03' || curMonth === '05' || curMonth === '07' || curMonth === '08' || curMonth === '10' | curMonth === '12') {
                maxDays = 31
            } else if (curMonth === '02') {
                maxDays = 28
            }
            let maxTemp = 0
            let monthResults = [];
            let idNo = '';
            let fullName = '';
            let surname = '';

            let monResults = new Array(maxDays).fill('');
            let monColours = new Array(maxDays).fill('black');

            for (let index = 0; index < curSurveys.length; index++) {
                if (!curSurveys[index].idNo) { continue; };
                let changeFlag = false
                if (surname !== curSurveys[index].surname) {
                    changeFlag = true;
                };
                if ( (idNo !== curSurveys[index].idNo)) {
                    changeFlag = true;
                };
                if ( (idNo === '')) {
                    idNo = curSurveys[index].idNo;
                    fullName = curSurveys[index].firstName + ' ' + curSurveys[index].surname;
                    surname = curSurveys[index].surname;
                    changeFlag = false;
                };
                if (changeFlag) {
                    monthResults.push(new monSurvey(idNo, fullName, maxTemp,
                        [...monResults], [...monColours]));
                    maxTemp = 0;
                    fullName = '';
                    surname = '';
                    for (let day = 0; day < maxDays; day++) {
                        monResults[day] = '';
                        monColours[day] = 'black';
                    };
                };

                if (!changeFlag) {
                    const day = parseInt(curSurveys[index].day - 1);
                    monResults[day] = curSurveys[index].result;
                    monColours[day] = curSurveys[index].colour;
                    maxTemp = Math.max(parseFloat(maxTemp) ,parseFloat(curSurveys[index].temperature));
                    if (isNaN(maxTemp)) {
                        maxTemp = 0;
                    };
                }
                else {
                    idNo = curSurveys[index].idNo;
                    fullName = curSurveys[index].firstName + ' ' + curSurveys[index].surname;
                    surname = curSurveys[index].surname;
                    const day = parseInt(curSurveys[index].day - 1);
                    monResults[day] = curSurveys[index].result;
                    monColours[day] = curSurveys[index].colour;
                    maxTemp = parseFloat(curSurveys[index].temperature).toFixed(2);
                    if (isNaN(maxTemp)) {
                        maxTemp = 0;
                    };
                }
            };

            monthResults.push(new monSurvey(idNo, fullName, maxTemp,
                [...monResults], [...monColours]));  

            dispatch({
                type: MONTH_SURVEYS,
                allSurveys: monthResults,
                maxDays: maxDays
            }
            );
        }
        catch (err) {
            dispatch({
                type: SURVEY_FAIL,
                error: err
            });
        }
    };
};


function sortQuestionsID(a, b) {
    if (a.id < b.id) return -1;
    else if (a.id > b.id) return 1;
    else return 0;
}

export const loadQuestions = (company) => {
    let url = `https://listapp-fcbc4.firebaseio.com/${company}/Questions.json?`
    if (company === '') {
        url = `https://listapp-fcbc4.firebaseio.com/Questions.json?`
    }
    return async (dispatch) => {
        dispatch(loadingSurvey());
        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Unable to connect to server! ${response.message}`);
            };

            const resData = await response.json();
            let questions = [];
            for (const key in resData) {
                questions.push({
                    ...resData[key]
                }
                );
            }

            for (let index = 0; index < questions.length; index++) {
                if (questions[index].colour === 'yellow') {
                    questions[index].colour = 'purple';
                }
            }

            await questions.sort(sortQuestionsID);

            dispatch({
                type: LOAD_QUESTIONS,
                allQuestions: questions,
            }
            );
        }
        catch (err) {
            dispatch({
                type: SURVEY_FAIL,
                error: err
            });
        }
    };
};


export const createChecks = () => {
    return (dispatch) => {
        try {
            let id = 1
            let title = 'Contact with Covid case'
            let question = 'Have you been in close contact with someone who is COVID-19 positive?'
            let identifier = 'check1'
            let colour = 'red'
            let response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 2
            title = 'Tested Covid Positive'
            question = 'Have you been Diagnosed with COVID-19 in the last 2 weeks?'
            identifier = 'check2'
            colour = 'red'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 3
            title = 'Fever'
            question = 'Fever - When you touch your forehead, does it feel hot?'
            identifier = 'check3'
            colour = 'orange'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 4
            title = 'Chills'
            question = 'Chills - are you shivering?'
            identifier = 'check4'
            colour = 'orange'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 5
            title = 'Dry Cough'
            question = 'Have you recently started a dry cough?'
            identifier = 'check5'
            colour = 'orange'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 6
            title = 'Sore Throat'
            question = 'Do you have a sore throat or have pain when you swallow?'
            identifier = 'check6'
            colour = 'orange'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 7
            title = 'Short of Breath'
            question = 'Do you have Shortness of Breath or difficulty breathing?'
            identifier = 'check7'
            colour = 'orange'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 8
            title = 'Tired'
            question = 'Are you feeling tired?'
            identifier = 'check8'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 9
            title = 'Loss of Smell or Taste'
            question = 'Have you lost your sense of Smell or Taste?'
            identifier = 'check9'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 10
            title = 'Conjuctivitis'
            question = 'Are your eyes bloodshot? (Conjuctivitis)'
            identifier = 'check10'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 11
            title = 'Diarrhoea'
            question = 'Have you recently experienced any Diarrhoea?'
            identifier = 'check11'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 12
            title = 'Muscle pains'
            question = 'Do you have muscle pains or does your Body Ache?'
            identifier = 'check12'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 13
            title = 'Nausea or Vomiting'
            question = 'Have you recently experienced any Nausea or Vomiting?'
            identifier = 'check13'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 14
            title = 'Dizzy'
            question = 'Have you recently experienced any Dizziness?'
            identifier = 'check14'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );
            id = 15
            title = 'Headache'
            question = 'Have you recently experienced any Headaches?'
            identifier = 'check15'
            colour = 'purple'
            response = fetch(
                `https://listapp-fcbc4.firebaseio.com/Questions.json`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, title, question, identifier, colour
                    })
                }
            );

            if (!response.ok) {
                console.log(`response not OK! ${response.message}`);
            };

        } catch (error) {
            dispatch({
                type: SURVEY_FAIL,
                error: error
            });
        }
    };
};

