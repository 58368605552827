import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom'

import './App.css';
import Navbar from './components/Navbar'
import Title from './components/Title'
import Footer from './components/Footer'
import Home from './components/Home'
import Info from './components/Info'
import Contact from './components/Contact'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import Logout from './components/Logout'
import CreateUser from './components/users/CreateUser';
import userDetails from './components/users/UserDetails';
import UserList from './components/users/UserList';
import userRegistered from './components/UserRegistered';
import ImportUser from './components/ImportExcel';
import Survey from './components/users/Survey';
import surveyResults from './components/users/SurveyResults';
import surveyMonth from './components/users/SurveyMonth';
import Company from './components/Company';
import CompanyData from './components/CompanyUpdate';
import CompanyList from './components/CompanyList';
import coRegistered from './components/CoRegistered';
import SurveyComplete from './components/SurveyComplete';
import BlocksAll from './components/BlocksAll';
import RegUsers from './components/RegisteredUsers';
import CompanyPayment from './components/CompanyPayment';
import StatsDay from './components/StatsDay';
import StatsMonth from './components/StatsMonth';
import NIOHWeek from './components/NIOHWeekly';
import NIOHdata from './components/NIOHresponse';
import NIOHpositive from './components/NIOHPositive';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                {/* <BrowserRouter   basename= 'checkpoint.twinsolutions.co.za'> */}
                <div className="App">
                    <Navbar />
                    <Title />
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route path='/home' component={Home} />
                        <Route path='/info' component={Info} />
                        <Route path='/contact' component={Contact} />
                        <Route path='/signin' component={SignIn} />
                        <Route path='/signup' component={SignUp} />
                        <Route path='/logout' component={Logout} />
                        <Route path='/user' component={userDetails} />
                        <Route path='/newUser' component={CreateUser} />
                        <Route path='/users' component={UserList} />
                        <Route path='/userReg' component={userRegistered} />
                        <Route path='/importUser' component={ImportUser} />
                        <Route path='/details' component={surveyResults} />
                        <Route path='/monthly' component={surveyMonth} />
                        <Route path='/newCompany' component={Company} />
                        <Route path='/updateCo' component={CompanyData} />
                        <Route path='/allCos' component={CompanyList} />
                        <Route path='/coInfo' component={coRegistered} />
                        <Route path='/survey' component={Survey} />
                        <Route path='/complete' component={SurveyComplete} />
                        <Route path='/allBlocks' component={BlocksAll} />
                        <Route path='/payment' component={CompanyPayment} />
                        <Route path='/dayStats' component={StatsDay} />
                        <Route path='/monStats' component={StatsMonth} />
                        <Route path='/regUsers' component={RegUsers} />
                        <Route path='/NIOHweek' component={NIOHWeek} />
                        <Route path='/NIOHdata' component={NIOHdata} />
                        <Route path='/NIOHpositive' component={NIOHpositive} />
                    </Switch>
                    <Footer />
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
