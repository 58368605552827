import React from 'react';

import BodyTextBackground from './UI/BodyTextBackground';

const Contact = () => {
  return (
    <div className="flexGrow">
      <div className="containerFlex  main-wrapper">
          <div className="filler">
          </div>
            <BodyTextBackground>
              <p className="h3">Contact us </p>
              <p className="h4Centre">Caroline Mathew: +27 84 580 4016</p>
              <p>or</p>
              <p className="h4Centre">Gina Phillips: +27 82 674 0034</p>
              <p> </p>
              <p className="h4Centre">email: checkpoint@twinsolutions.co.za</p>
            </BodyTextBackground>
            <div className="filler">
            </div>
          </div>
        </div>
  )
}

export default Contact