import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import './Navbar.css';
import LogoNavBar from '../Assets/LogoNavBar.png'
import Menu from '../Assets/Menu.png';

const Navbar = (props) => {
    let routes = (
        <ul className="navLink">
            <li className="button"><NavLink exact to="/">Home</NavLink></li>
            <li className="button"><NavLink to='/info'>Info</NavLink></li>
            <li className="button"><NavLink to='/signIn'>Sign In</NavLink></li>
            <li className="button"><NavLink to='/signUp'>Register</NavLink></li>
            <li className="button"><NavLink to='/contact'>Contact us</NavLink></li>
        </ul>
    );

    if (props.isAuthenticated && props.loggedIn) {
        routes = (
            <ul className="navLink">
                <li className="button"><NavLink to='/info'>Info</NavLink></li>
                <li className="button"><NavLink to='/complete'>Survey</NavLink></li>
                <li className="button"><NavLink to='/user'>Update Details</NavLink></li>
                <li className="button"><NavLink to='/logout'>Logout</NavLink></li>
            </ul>
        );
    };

    if (props.isAuthenticated && props.surveyAdmin && (props.companyStatus === 'Active')) {
        routes = (
            <ul className="navLink">
                <li className="button"><NavLink to='/info'>Info</NavLink></li>
                <div className="dropdown">
                    <button className="dropbtn">Users</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/users'>All Users</NavLink></li>
                        <li><NavLink to='/newUser'>Add User</NavLink></li>
                    </div>
                </div>
                <li className="button"><NavLink to='/complete'>Survey</NavLink></li>
                <li className="button"><NavLink to='/logout'>Logout</NavLink></li>
            </ul>
        );
    };
    
    if (props.isAuthenticated && props.mainUser && (props.companyStatus === 'Active')) {
        routes = (
            <ul className="navLink">
                <li className="button"><NavLink to="/info">Info</NavLink></li>
                <div className="dropdown">
                    <button className="dropbtn">Users</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/users'>All Users</NavLink></li>
                        <li><NavLink to='/newUser'>Add User</NavLink></li>
                        <li><NavLink to='/importUser'>Import Users</NavLink></li>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">Survey</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/details'>Daily Survey</NavLink></li>
                        <li><NavLink to='/monthly'>Monthly Survey</NavLink></li>
                        <li><NavLink to='/updateCo'>Company Details</NavLink></li>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">NIOH</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/NIOHdata'>Employees that require action</NavLink></li>
                        <li><NavLink to='/NIOHweek'>Weekly Survey</NavLink></li>
                    </div>
                </div>
                <li className="button"><NavLink to='/logout'>Logout</NavLink></li>
            </ul>
        );
    };

    if (props.isAuthenticated && props.admin) {
        routes = (
            <ul className="navLink">
                <li className="button"><NavLink exact to="/">Home</NavLink></li>
                <div className="dropdown">
                    <button className="dropbtn">Users</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/users'>All Users</NavLink></li>
                        <li><NavLink to='/newUser'>Add User</NavLink></li>
                        <li><NavLink to='/importUser'>Import Users</NavLink></li>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">Survey</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/details'>Daily Survey</NavLink></li>
                        <li><NavLink to='/monthly'>Monthly Survey</NavLink></li>
                        <li><NavLink to='/updateCo'>Company Details</NavLink></li>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">NIOH</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/NIOHdata'>Employees that require action</NavLink></li>
                        <li><NavLink to='/NIOHweek'>Weekly Survey</NavLink></li>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">Admin</button>
                    <div className="dropdown-content">
                        <li><NavLink to='/dayStats'>Daily Stats</NavLink></li>
                        <li><NavLink to='/monStats'>Monthly Stats</NavLink></li>
                        <li><NavLink to='/allBlocks'>Block Details</NavLink></li>
                        <li><NavLink to='/allCos'>All Companies</NavLink></li>
                        <li><NavLink to='/regUsers'>All Users</NavLink></li>
                    </div>
                </div>
                <li className="button"><NavLink to='/logout'>Logout</NavLink></li>
            </ul>
        );
    };

    return (
        <div className="wrapper">
            <nav className="containerFlex nav-wrapper">
                <div className="logo">
                    <img src={LogoNavBar} className="nav-logo" alt="logo" />
                </div>
                <div className="menu">
                    <img src={Menu} className="nav-menu" alt="menu" />
                </div>
                <div className="nav-routes">
                    <div className="routes">
                        {routes}
                    </div>
                </div>
            </nav>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.authData.emailVerified,
        loggedIn: state.authData.loggedIn,
        mainUser: state.authData.mainUser,
        admin: state.userData.admin,
        surveyAdmin: state.userData.surveyAdmin,
        companyStatus: state.companyData.curCompanyStatus,
    };
};

export default connect(mapStateToProps)(Navbar)