import React, { Component, } from 'react'
import DatePicker from 'react-datepicker';
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import {surveyMonthTable} from '../../dataStore/SurveyActions';
import {getCompany} from '../../dataStore/CompanyActions';
import {clearCurrentUser}  from '../../dataStore/UserActions';
import Spinner from '../../components/UI/Spinner';
import convertDate from '../../components/ConvertDate';
import getMonth from '../../components/GetMonth';
import ExportToExcel from '../../components/ExportToExcel';
import './SurveyResults.css'


class surveyMonth extends Component {
    state = {
        startDate: new Date(),
        companyName: '',
        fileName: ''
    };
    
    componentDidMount () {
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
            this.props.history.push('/signin');   
        }
        if (this.props.loggedInUser.companyNo ) {
            this.props.getCompany(this.props.loggedInUser.companyNo);
            const surveyDate = convertDate(this.state.startDate);
            const month  = getMonth(this.state.startDate);
            const year = this.state.startDate.getFullYear();
            const fileName  = this.props.companyDetails.companyName + '-' + year + '-' + month;
            this.setState({fileName: fileName})
            this.props.getMonthSurveys(this.props.loggedInUser.companyNo,  surveyDate);
        }
    };
      
    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (!this.props.coLoading && this.state.companyName === '' ) {
            const companyKey = {...this.props.companyDetails};
            for (let key in companyKey) {
                const { ...companyDetails } = companyKey[key];
                this.setState({companyName: companyDetails.companyName});
            };
        }
    };
  
    handleChangeDate = date => {
        this.setState({
            startDate: date,
        });
        const surveyDate = convertDate(date);
        const month  = getMonth(date);
        const year = date.getFullYear();
        const fileName  = this.props.companyDetails.companyName + '-' + year + '-' + month;
        this.setState({fileName: fileName})
        this.props.getMonthSurveys(this.props.loggedInUser.companyNo,  surveyDate)
    };
    
    renderTableData() {
        if (this.props.monthSurveys  && !this.props.loadingSurveys) {
            return this.props.monthSurveys.map( (survey, s) => {
                const surveyResult = survey.result 
                const surResults =  Object.values(surveyResult).map( (c, i) =>{
                    return <td key={i} style={{color: survey.colour[i]}}>{c}</td>
                })
                return (
                    <tr key={s }>
                        <td className="width120">{survey.fullName}</td>
                        <td className="width120">{survey.idNo }</td>
                        <td className="width50">{survey.avgTemp}</td>
                        {surResults}
                    </tr>)
                });
        };
    };

    renderTableHeader() {
        if (this.props.monthSurveys.length === 0) {
            return null
        } else
            if (this.props.monthSurveys  && !this.props.loadingSurveys ) {
                let  header = ['Fullname', 'ID',  'MaxTemp °C',];  
                for (let i = 1; i < this.props.maxDays+1; ++i) {
                    header.push(`${i}`);
                }
                return header.map((colTitle, index) => {
                    return <th key={index}>{colTitle}</th>
                });
            };  
    };

    
    render() {

        // const center = {
        //     position: "fixed" /* or absolute */,
        //     top: "50%",
        //     left: "50%",
        //     marginTop: "-50px",
        //     marginLeft: "-100px",
        //   };

        let dateArea = <Spinner />;

        if ( !this.props.loadingSurveys ) {
            dateArea = (
                <div className="date" >
                {/* <label>Select Date: </label> */}
                <h2 className="centred" id='titledate'>Click box to Select Month:</h2>
                    <DatePicker className="date-picker "
                        selected={ this.state.startDate }
                        onChange={e => this.handleChangeDate(e)}
                        name="startDate"
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                    />
                </div>
            );
        }

        let surveyMonthTable = null;
        if ( !this.props.loadingSurveys ) {
            surveyMonthTable = (
                <tbody>
                    <tr>{this.renderTableHeader()}</tr>
                    {this.renderTableData()}
                </tbody>
            );
        }

        let message = null;
        if ( this.props.monthSurveys.length === 0 ) {
            message = <p className="message">No results - Must be the weekend!!</p>
        }

        return (
                <div className="flexGrow" style={{marginBottom:30}}>
                    <div className="right" >
                        <ExportToExcel csvData={this.props.exportData} fileName={this.state.fileName} />
                    </div>
                    <h1 className="centred" id='title'>Monthly Survey Results for</h1>
                    <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>    
                    <div className="centred" >
                        {dateArea}
                        {message}
                        <h6 className='blue centred' >✓ = survey was completed on that day and all was clear, no symptoms</h6>
                    </div>
                    <table className='table' id='surveys'>
                            {surveyMonthTable}
                    </table>
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        loadingSurveys: state.surveyData.loading,
        monthSurveys: state.surveyData.monthSurvey,
        exportData: state.surveyData.exportData,
        questions: state.surveyData.questions,
        maxDays: state.surveyData.maxDays,
        loggedInUser : state.userData.loggedInUser,
        companyDetails : state.companyData.companyDetails,
        coLoading: state.companyData.loading,
    };
};
 
const mapDispatchToProps = (dispatch) => ({
    getMonthSurveys: (company, startDate) => dispatch(surveyMonthTable(company, startDate)),
    getCompany: (companyNo) => dispatch(getCompany(companyNo)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});
 
 export default connect(mapStateToProps, mapDispatchToProps)(surveyMonth);