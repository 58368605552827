import React, { Component,  } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import { fetchSurveys } from '../../dataStore/SurveyActions';
import { getCompany } from '../../dataStore/CompanyActions';
import {clearCurrentUser}  from '../../dataStore/UserActions';
import Spinner from '../UI/Spinner';
import convertDate from '../../components/ConvertDate';
import dailyExport from '../../modals/DailyExport';
import ExportToExcel from '../ExportToExcel';
import './SurveyResults.css'

let exportInfo = [];

class surveyResults extends Component {
    state = {
        startDate: new Date(),
        companyName: '',
        fileName: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo )) {
            this.props.history.push('/signin');
        };
        if (this.props.loggedInUser.companyNo ) {
            this.props.getCompany(this.props.loggedInUser.companyNo);
            const surveyDate = convertDate(this.state.startDate);
            this.props.getAllSurveys(this.props.loggedInUser.companyNo, surveyDate);
            const fileName = this.props.companyDetails.companyName + '-' + surveyDate;
            this.setState({ fileName: fileName })
        };
    }

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            this.props.clearCurrentUser();
            this.props.history.push('/signin');
        };
        if (!this.props.coLoading && this.state.companyName === '') {
            const companyKey = { ...this.props.companyDetails };
            for (let key in companyKey) {
                const { ...companyDetails } = companyKey[key];
                this.setState({ companyName: companyDetails.companyName });
            };
        };
    };

    handleChangeDate = date => {
        this.setState({
            startDate: date,
        });
        const surveyDate = convertDate(date);
        const fileName = this.props.companyDetails.companyName + '-' + surveyDate;
        this.setState({ fileName: fileName })
        this.props.getAllSurveys(this.props.loggedInUser.companyNo, surveyDate)
    };

    renderTableData() {
        if (this.props.currentSurveys && !this.props.loadingSurveys) {
            return this.props.currentSurveys.map((survey, index) => {
                const { id, firstName, surname, temperature, check1, check2, check3, check4, check5,
                check6, check7, check8, check9, check10, check11, check12, check13, check14, check15, colour } = survey
                return (
                    <tr key={id} style={{ color: colour }}>
                        <td className="font-size">{firstName}</td>
                        <td className="font-size">{surname}</td>
                        <td>{temperature}</td>
                        <td>{check1}</td>
                        <td>{check2}</td>
                        <td>{check3}</td>
                        <td>{check4}</td>
                        <td>{check5}</td>
                        <td>{check6}</td>
                        <td>{check7}</td>
                        <td>{check8}</td>
                        <td>{check9}</td>
                        <td>{check10}</td>
                        <td>{check11}</td>
                        <td>{check12}</td>
                        <td>{check13}</td>
                        <td>{check14}</td>
                        <td>{check15}</td>
                    </tr>
                )
            })
        }
    }

    renderTableHeader() {
        if (this.props.currentSurveys.length === 0) {
            return null
        } else
            if (this.props.currentSurveys && !this.props.loadingSurveys) {
                let header = Object.keys(this.props.currentSurveys[0])

                header = ['first Name', 'surname', 'temp °C',];
                for (let i = 0; i < this.props.questions.length; ++i) {
                    header.push(this.props.questions[i].title);
                }
                return header.map((key, index) => {
                    const colTitle = key[0].toUpperCase() + key.substring(1);
                    return <th key={index}>{colTitle}</th>
                })
            }
    }


    render() {

        // const center = {
        //     position: "fixed" /* or absolute */,
        //     top: "50%",
        //     left: "50%",
        //     marginTop: "-50px",
        //     marginLeft: "-100px",
        //   };

        let dateArea = <Spinner />;

        if (!this.props.loadingSurveys) {
            dateArea = (
                <div className="date"   >
                {/* <div className="date"  style={center} > */}
                    {/* <label>Select Date: </label> */}
                    <h2 className="centred" id='titledate'>Click box to Select Date:</h2>
                    <DatePicker className="date-picker "
                        selected={this.state.startDate}
                        onChange={e => this.handleChangeDate(e)}
                        name="startDate"
                        dateFormat="dd/MM/yyyy"
                        />
                    {/* <span>Select new Date:</span>  */}
                </div>
            );
        };

        let surveyMonthTable = null;
        if (!this.props.loadingSurveys) {
            surveyMonthTable = (
                <tbody>
                <tr>{this.renderTableHeader()}</tr>
                {this.renderTableData()}
                </tbody>
            );
        }

        let message = null;
        if (this.props.currentSurveys.length === 0) {
            message = <p className="message">No results - Must be the weekend!!</p>
        } else {
            exportInfo = [];
            for (let index = 0; index < this.props.currentSurveys.length; index++) {
                exportInfo.push(new dailyExport(
                this.props.currentSurveys[index].firstName,
                this.props.currentSurveys[index].surname,
                this.props.currentSurveys[index].temperature,
                this.props.currentSurveys[index].check1,
                this.props.currentSurveys[index].check2,
                this.props.currentSurveys[index].check3,
                this.props.currentSurveys[index].check4,
                this.props.currentSurveys[index].check5,
                this.props.currentSurveys[index].check6,
                this.props.currentSurveys[index].check7,
                this.props.currentSurveys[index].check8,
                this.props.currentSurveys[index].check9,
                this.props.currentSurveys[index].check10,
                this.props.currentSurveys[index].check11,
                this.props.currentSurveys[index].check12,
                this.props.currentSurveys[index].check13,
                this.props.currentSurveys[index].check14,
                this.props.currentSurveys[index].check15));
            }
        }
        
    return (
        <div className="flexGrow">
        <div className="right">
            <ExportToExcel csvData={exportInfo} fileName={this.state.fileName} />
        </div>
        <h1 className="centred" id='title'>Daily Survey Results for</h1>
        <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>
        {/* <h2 className="centred" id='titleleft'>Select Date:</h2> */}
        <div className="centred black" >
            {dateArea}
            {message}
        </div>
        <table className='table' id='surveys'>
            {surveyMonthTable}
        </table>
        </div>
    )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        loadingSurveys: state.surveyData.loading,
        currentSurveys: state.surveyData.selectedSurveys,
        questions: state.surveyData.questions,
        exportData: state.surveyData.exportData,
        loggedInUser: state.userData.loggedInUser,
        companyDetails: state.companyData.companyDetails,
        coLoading: state.companyData.loading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAllSurveys: (company, startDate) => dispatch(fetchSurveys(company, startDate)),
    getCompany: (companyNo) => dispatch(getCompany(companyNo)),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(surveyResults);