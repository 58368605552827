const getMonth = (date) => {

    let month = date.getMonth();
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthName = monthNames[month];

    return monthName;
}

export default getMonth;
