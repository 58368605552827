import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import { updateSurvey } from '../../dataStore/SurveyActions';
import { surveyComplete } from '../../dataStore/SurveyActions';
import {clearCurrentUser}  from '../../dataStore/UserActions';
import './Survey.css';
import Spinner from '../../components/UI/Spinner';
import convertDate from '../../components/ConvertDate';
import BodyTextBackground from '../UI/BodyTextBackground';

class Survey extends Component {
    state = {
        startDate: new Date(),
        loadedChecks: false,
        declaration: false,
        temperature: '0',
        formIsValid: false,
        check1: 0,
        check2: 0,
        check3: 0,
        check4: 0,
        check5: 0,
        check6: 0,
        check7: 0,
        check8: 0,
        check9: 0,
        check10: 0,
        check11: 0,
        check12: 0,
        check13: 0,
        check14: 0,
        check15: 0,
        message: '',
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (!this.props.loadingUser) {
            if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
                this.props.history.push('/signin');
            };
        };
        if (this.props.surveyComplete && (this.props.lastSurvey.temperature > 0)) {
            this.props.history.replace('/complete');
        };

        const formDetails = {
            ...this.props.surveyChecks
        };
        for (const elementIdentifier in formDetails) {
            const identifier = 'check' + (+elementIdentifier);
            let value = formDetails[elementIdentifier];
            this.setState(state => {
                    state[identifier] = value
                    return state
                    });
            this.setState({ loadedChecks: true });
        };
    };

    componentDidUpdate() {
        if (!this.props.isAuthenticated) {
            if (!this.props.loadingAuth) {
                this.props.history.push('/signin');
            }
        };
        if (!this.state.formIsValid && this.state.temperature > 0 && this.state.declaration && this.state.message === '') {
            this.setState({ formIsValid: true })
        };
        const surveyDate = convertDate(this.state.startDate);
        if (this.props.surveyComplete && (this.props.lastSurvey.loadedDate === surveyDate) && 
            ((this.props.lastSurvey.temperature > 0 ) )) {
            this.props.history.replace('/complete');
        };

        if (!this.props.loadingSurvey && !this.state.loadedChecks) {
            const formDetails = {
                ...this.props.surveyChecks
            };
            for (const elementIdentifier in formDetails) {
                const identifier = 'check' + (+elementIdentifier)
                this.setState(state => {
                        state[identifier] = formDetails[elementIdentifier]
                        return state
                        })
                this.setState({ loadedChecks: true })
            };
        };
    };

    handleTempChange = (event) => {
        const pattern = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/;
        const isValid = pattern.test(event.target.value);
        this.setState({ [event.target.id]: event.target.value });
        if (isValid) {
            this.setState({message: ''});
        } else {
            this.setState({message: 'Temperature must be numeric'});
            this.setState({ formIsValid: false })
        };
        if (!this.state.declaration  ) {
            this.setState({ formIsValid: false })
        }else if (isValid) {
            this.setState({ formIsValid: true })
        };
    }
    
    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.checked  });
        this.setState({message: ''});
        const pattern = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/;
        const isValid = pattern.test(this.state.temperature);
        if (!isValid) {
            this.setState({message: 'Temperature must be numeric'});
            this.setState({ formIsValid: false })
        };
        if (event.target.id === 'declaration' && isValid) {
            if (this.state.temperature === '0') {
                this.setState({message: 'Temperature must be entered'})
                this.setState({ formIsValid: false })
            } else if ((this.state.temperature < 32) || (this.state.temperature > 42)) {
                this.setState({message: 'Invalid temperature'})
                this.setState({ formIsValid: false })
            } else {this.setState({ formIsValid: false })};
        }else
            if (!this.state.declaration ) {
                this.setState({ formIsValid: false })
            } else if ( (this.state.temperature === 0)|| (this.state.temperature === '') ){
                this.setState({message: 'Temperature must be entered'})
                this.setState({ formIsValid: false })
            };
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const surveyInfo = Array(15).fill(0);
        for (let formCheckIdentifier in this.state) {
            if (formCheckIdentifier.includes('check')) {
                const checkIndex = formCheckIdentifier.slice(5);
                if (this.state[formCheckIdentifier] === 'on' || 
                    (this.state[formCheckIdentifier] === true ) || 
                    (this.state[formCheckIdentifier] === 1 )){
                    surveyInfo[checkIndex] = 1;
                }
            }
        };
        this.props.updateSurvey(this.props.currentUser,
                this.state.declaration,
                this.state.temperature,
                surveyInfo,
                this.props.questions);
    }

  render() {

    let form = (
        <Fragment>
            <p className="h3">Welcome {this.props.currentUser.firstName}</p>
            <div className=" survey-field black ">
                <label className="alignRHS" htmlFor="temperature">Temperature   °C</label>
                <input className="alignRHS temp" type="text" id='temperature' onChange={this.handleTempChange} />
            </div>
            <div className="survey-field black ">
                <label className="alignRHS" htmlFor="check1">Have you been in close contact with someone who is COVID-19 positive?</label>
                <input className="alignRHS" type="checkbox" id='check1' 
                checked={this.state.check1} onChange={this.handleChange} />
            </div>
            <div className="survey-field black ">
                <label className="alignRHS" htmlFor="check2">Have you been Diagnosed with COVID-19?</label>
                <input className="alignRHS" type="checkbox" id='check2' 
                checked={this.state.check2} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check3">Fever - When you touch your forehead, does it feel hot?</label>
                <input className="alignRHS" type="checkbox" id='check3' 
                checked={this.state.check3} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check4">Chills - are you shivering?</label>
                <input className="alignRHS" type="checkbox" id='check4' 
                checked={this.state.check4} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check5">Have you recently started a dry cough?</label>
                <input className="alignRHS" type="checkbox" id='check5' 
                checked={this.state.check5} onChange={this.handleChange} />
            </div>
            <div className="survey-field black ">
                <label className=" alignRHS" htmlFor="check6">Do you have a sore throat or have pain when you swallow?</label>
                <input className=" alignRHS" type="checkbox" id='check6' 
                checked={this.state.check6} onChange={this.handleChange} />
            </div>
            <div className="survey-field black ">
                <label className=" alignRHS" htmlFor="check7">Do you have Shortness of Breath or difficulty breathing?</label>
                <input className=" alignRHS" type="checkbox" id='check7' 
                checked={this.state.check7} onChange={this.handleChange} />
            </div>
            <div className="survey-field black ">
                <label className="alignRHS" htmlFor="check8">Are you feeling tired?</label>
                <input className="alignRHS" type="checkbox" id='check8' 
                checked={this.state.check8} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check9">Have you lost your sense of Smell or Taste?</label>
                <input className="alignRHS" type="checkbox" id='check9' 
                checked={this.state.check9} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check10">Are your eyes bloodshot? (Conjuctivitis)</label>
                <input className="alignRHS" type="checkbox" id='check10' 
                checked={this.state.check10} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check11">Have you recently experienced any Diarrhoea?</label>
                <input className="alignRHS" type="checkbox" id='check11' 
                checked={this.state.check11} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check12">Do you have muscle pains or does your Body Ache?</label>
                <input className="alignRHS" type="checkbox" id='check12' 
                checked={this.state.check12} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check13">Have you recently experienced any Nausea or Vomiting?</label>
                <input className="alignRHS" type="checkbox" id='check13' 
                checked={this.state.check13} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check14">Have you recently experienced any Dizziness?</label>
                <input className="alignRHS" type="checkbox" id='check14' 
                checked={this.state.check14} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="check15">Have you recently experienced any Headaches?</label>
                <input className="alignRHS" type="checkbox" id='check15' 
                checked={this.state.check15} onChange={this.handleChange} />
            </div>
            <div className="survey-field black">
                <label className="alignRHS" htmlFor="declaration">I, {this.props.currentUser.firstName} {this.props.currentUser.surname}, hereby declare that all the information I have provided is true to the best of my knowledge.</label>
                <input className="alignRHS" type="checkbox" id='declaration' onChange={this.handleChange} />
            </div>
            <div className="btn-containerFlex">
                <button className="btnPrimary" disabled={!this.state.formIsValid}>Submit</button>
            </div>
            <p className="message"></p>
        </Fragment>
    )

    if (this.props.loadingSurvey) {
        form = <Spinner />
    }

    return (
        <Fragment>
            <h1>In Survey</h1>

            <div className="wrapper">
                <div className="containerFlex  main-wrapper">
                    <div className="filler">
                    </div>
                    <BodyTextBackground>
                        <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>   
                        <form className="form " onSubmit={this.handleSubmit}>
                            {form}
                        </form>
                        <h4 className="red" >{this.state.message}</h4>
                    </BodyTextBackground>
                    <div className="filler">
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authData.token !== null,
        currentUser: state.userData.currentUser,
        loadingUser: state.userData.loading,
        loadingAuth: state.authData.loading,
        loadingSurvey: state.surveyData.loading,
        loggedInUser: state.userData.loggedInUser,
        lastSurvey: state.surveyData.userDetails,
        surveyChecks: state.surveyData.checks,
        surveyComplete: state.surveyData.surveyComplete,
        questions: state.surveyData.questions,
        companyDetails : state.companyData.companyDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateSurvey: (currentUser, declaration, temperature, surveyInfo, questions) =>
    dispatch(updateSurvey(currentUser, declaration, temperature, surveyInfo, questions)),
    surveyComplete: () => dispatch(surveyComplete()),
    clearCurrentUser: () => dispatch(clearCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
