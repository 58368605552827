import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

class Footer extends Component {

    render() {
    let footArea1 = (
        <div>
            <li><Link to='/newCompany'>Register new Company</Link></li>
            <li>|</li>
            <li><NavLink to='/contact'>Contact us</NavLink></li>
            <li>|</li>
        </div>
    ); 
    if (this.props.loggedInUser.companyNo) {
        footArea1 = (
            <div className="footerLink">
                <li>|</li>
                <li><NavLink to='/contact'>Contact us</NavLink></li>
                <li>|</li>
            </div>
        );
    };
    return (
        <div className="wrapper">
            <footer className="footer">
                <ul className="footerLink">
                    <div>
                        {footArea1}
                    </div>
                    <div className="footerLink">
                        <li><a href="https://www.twinsolutions.co.za" target="_blank" rel="noopener noreferrer">Clinsys Website</a></li>
                        <li>|</li>
                        <li><a href="https://drive.google.com/file/d/1i738VnR3L-ICqNJB_Fv2Hw4gMiu3Z59a/view?usp=sharing" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                        <li>|</li>
                        <li><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019">visit WHO</a></li>
                        <li>|</li>
                        <li><a href="https://www.jozikids.co.za/zaparents-blog/isolation-kit-a-shopping-list-to-help-you-get-through/">visit Josi Kids</a></li>
                    </div>
                </ul>
            </footer>
        </div>
    )
}};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.userData.loggedInUser,
    };
};

export default connect(mapStateToProps)(Footer)
