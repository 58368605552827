import { FETCH_USERS } from './UserActions';
import { CURRENT_USER} from './UserActions';
import { LOGIN_USER} from './UserActions';
import { LOADING_USER} from './UserActions';
import { CHECK_USER_EXISTS} from './UserActions';
import { LOADING_USER_RESET} from './UserActions';
import { FILE_FINISH} from './UserActions';
import { FILE_LOADING} from './UserActions';
import { USER_FAIL} from './UserActions';
import { CLEAR_USER} from './UserActions';
import { CLEAR_LOGINUSER} from './UserActions';
import { UPDATE_LOGINUSER} from './UserActions';

const initialState = {
  availableUsers: [{
    id: 0,
    email: 'Enter email address here',
    firstName: '',
    surname: '',
    companyNo: '',
    jobCategory: '',
    employeeNo: '',
    cellNo: '',
    idNo: '',
    DOB: '',
    mainUser: false,
    visitor: false,
    altCompany: '',
    firebaseID: '',
    telNo: '',
    userType: '',
    address: '',
    suburb: '',
    postalCode: '',
    district : '',
    province : '',
    country: '',
    surveyDate: '',
    createdDate: '',
    modifiedDate: ''
  }],
  currentUser: {
    id: 0,
    email: 'Enter email address here',
    firstName: '',
    surname: '',
    companyNo: '',
    jobCategory: '',
    employeeNo: '',
    cellNo: '',
    idNo: '',
    DOB: '',
    mainUser: false,
    visitor: false,
    altCompany: '',
    firebaseID: '',
    telNo: '',
    userType: '',
    address: '',
    suburb: '',
    postalCode: '',
    district : '',
    province : '',
    country: '',
    surveyDate: '',
    createdDate: '',
    modifiedDate: ''
  },
  loggedInUser: {
    email: 'Enter email address here',
    companyNo: '',
    firebaseID: '',
    surname: '',
    idNo: '',
    mainUser: false,
  },
  loading: false,
  signedIn: false,
  userExists: false,
  fileLoaded: false,
  admin: false,
  surveyAdmin: false,
  reloadCounter: 0,
  error: null,
};

export default (state = initialState, action) => {
    // console.log('action.type = ', action.type)
  switch (action.type) {

    case LOADING_USER:
        return {
            ...state,
            loading: true,
            error: null,
        };

    case FILE_LOADING:
        return {
            ...state,
            fileLoaded: false,
        };

    case FILE_FINISH:
        return {
            ...state,
            fileLoaded: true,
        };

    case LOADING_USER_RESET:
        return {
            ...state,
            loading: false,
            signedIn: false,
            userExists: false,
        };

    case CHECK_USER_EXISTS:
        return {
            ...state,
            loading: false,
            userExists: action.userStatus,
        };

    case FETCH_USERS:
        return {
            ...state,
            availableUsers: action.availableUsers,
            loading: false,
        };

    case CURRENT_USER:
        return {
            ...state,
            currentUser: action.currentUser,
            loading: false,
        };

    case LOGIN_USER:  
    console.log('LOGIN - action.currentUser =  ', action.currentUser)   
        let setAdmin = false;
        let setSurveyAdmin = false;
        if (!action.currentUser) {
            return {
                ...state,
                loading: false,
            };
        } else {
            if (action.currentUser.userType === 5){
                setAdmin = true;
            }
            if (action.currentUser.userType === 3 || (action.currentUser.userType === '3')){
                setSurveyAdmin = true;
            };
            let FBID = '';
            if (action.currentUser.id) {
                FBID = action.currentUser.id
            } else {
                FBID = action.currentUser.firebaseID
            }
            const loginUser = ({
                firebaseID: FBID,
                email: action.currentUser.email,
                idNo: action.currentUser.idNo,
                companyNo: action.currentUser.companyNo,
                mainUser: action.currentUser.mainUser,
                surname: action.currentUser.surname,
            });
            return {
                ...state,
                currentUser: action.currentUser,
                loggedInUser: loginUser,
                loading: false,
                signedIn : true,
                admin : setAdmin,
                surveyAdmin : setSurveyAdmin
            };
      };
        
    case USER_FAIL:  
        return {
            ...state,
            error: action.error,
            loading: false
            };
        
    case UPDATE_LOGINUSER: 
        return {
            ...state,
            loggedInUser: action.loginUser,
            signedIn : true,
            };
        
    case CLEAR_USER: 
        return {
            ...state,
            currentUser: action.currentUser,
            admin: false,
            loading: false
            };
            
    case CLEAR_LOGINUSER: 
        const loginUser = ({   
            email: 'Enter email address here',
            companyNo: '',
            firebaseID: '',
            surname: '',
            idNo: '',
            mainUser: false,
            admin: false,
        });
        return {
            ...state,
            loggedInUser: loginUser,
            userExists: false,
            loading: false
            };
            
    default:
        return state;
  }
};
