const convertDate = (date) => {

    function makeTwoDigits(number) {
        const numberString = `${number}`;
        if (numberString.length === 2) return number
        return `0${number}`
    }

    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    return makeTwoDigits(day) + '-' + makeTwoDigits(month) + '-' + year; //format: dd-mm-yyyy;
}

export default convertDate;
