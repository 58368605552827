import { POSITIVE_USER} from './NIOHActions';
import { LOADING_NIOH} from './NIOHActions';
import { USER_FAIL} from './NIOHActions';

const initialState = {
  currentReport: [],
  positiveUser: {
    id: 0,
    email: 'Enter email address here',
    firstName: '',
    surname: '',
    companyNo: '',
    jobCategory: '',
    employeeNo: '',
    cellNo: '',
    idNo: '',
    DOB: '',
    mainUser: false,
    visitor: false,
    altCompany: '',
    firebaseID: '',
    telNo: '',
    userType: '',
    address: '',
    suburb: '',
    postalCode: '',
    district : '',
    province : '',
    country: '',
    surveyDate: '',
    createdDate: '',
    modifiedDate: ''
  },
  loading: false,
};

export default (state = initialState, action) => {
  // console.log(action.type, action)
  switch (action.type) {

    case LOADING_NIOH:
        return {
            ...state,
            loading: true,
        };

    case POSITIVE_USER:
        return {
            ...state,
            positiveUser: action.positiveUser,
            loading: false,
        };

    case USER_FAIL:  
        return {
            ...state,
            error: action.error,
            loading: false
        };

    default:
        return state;
  }
};
