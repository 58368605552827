import React, { Component } from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import {connect} from "react-redux";

import {fileUpload, fileLoading, checkUserExists} from '../dataStore/UserActions';
import Spinner from '../components/UI/Spinner';
import BodyTextBackground from './UI/BodyTextBackground';

let spinner = null;
let errorMessage = null;


class ImportExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      cols: []
    };
  }


componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fileLoading();
    if ((this.props.loggedInUser.email === 'Enter email address here') || (!this.props.loggedInUser.companyNo)) {
        this.props.history.push('/signin');   
    };
};

componentDidUpdate(){
    if (this.props.fileLoaded &&(spinner !== null)) {
        this.props.checkUserExists(this.props.loggedInUser.email) ;
        spinner = null;
    };
    if (this.props.fileError) {
        this.props.checkUserExists(this.props.loggedInUser.email) ;
        errorMessage = this.props.fileError
        spinner = null;
    };    
    if (this.props.fileLoaded) {
        errorMessage = 'The data on the file has been successfully uploaded'
    };
};


changeHandler(event) {
    let fileObj = event.target.files[0];
    errorMessage = null;
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
    if (err) {
        console.log(err);
    } else {
        const columns = []
        columns.unshift({name: '-', key: 0});
        for (let index = 0; index < resp.cols.length; index++) {
            columns.push({name: resp.cols[index].name, key: index+1});
        };
        this.setState({
            cols: columns,
            rows: resp.rows
    });
    }
    });
};

renameKeys = (keysMap, obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const renamedObject = {
        [keysMap[key] || key]: obj[key]
      };
      return {
        ...acc,
        ...renamedObject
      };
    }, {});
};


uploadDetailsHandler = event => {
    event.preventDefault();
    errorMessage = null;
    const uploadDetails= [];
    const heading = [];
    const checkHeading = [...this.state.rows[0]];
    for (let index = 0; index < checkHeading.length; index++) {
        let element = checkHeading[index];
        if (element !== 'DOB') {
            element = element[0].toLowerCase() + element.substring(1);
        }
        heading.push(element);
    };
    for (let index = 1; index < this.state.rows.length; index++) {
        const user = this.state.rows[index];
        const newUser = this.renameKeys( heading, user); 
        if (newUser.idNo !== undefined && (newUser.surname !== undefined )) {
            uploadDetails.push(newUser);
        };
    };
    this.props.fileUpload(this.props.loggedInUser.companyNo,uploadDetails);
    spinner = <Spinner />;
}; 


render() {
    return (
      
    <div className="wrapper">
        <div className="containerFlex  main-wrapper">
            <div className="filler">
            </div>
            <BodyTextBackground>
                <h1 className="centred" id='title'>{this.props.companyDetails.companyName}</h1>   
                <p className='h3'>Upload File</p>
                <p>Please select an Excel file to upload</p>
                <p>The five mandatory fields are</p>
                <div className="list-left">
                    <li>ID Number (idNo)</li>
                    <li>First Name (firstName)</li>
                    <li>Surname (surname)</li>
                    <li>Gender (gender)</li>
                    <li>DOB (DOB) - DD/MM/CCYY - This field <em>MUST</em> be in text format</li>
                </div>
                <p>The name in brackets must be the title of each column - ie. row 1</p>
                <br />
                <p>Other optional fields are</p>
                <div className="list-left">
                    <li>Email address (email)</li>
                    <li>Cell Number (cellNo)</li>
                    <li>NIOH Job Category (jobCategory)</li>
                    <li>Employee Number (employeeNo)</li>
                    <li>Street Address (address)</li>
                    <li>Suburb (suburb)</li>
                    <li>Postal Code (postalCode)</li>
                </div>

                {errorMessage && (
                    <div className="h4 red">
                    <p >{errorMessage}</p>
                    </div>
                )}
                {spinner}
                <div className="containerFlex margin-sides">
                    <input
                        className="btnPrimary"
                        type="file"
                        onChange={this.changeHandler.bind(this)}
                        style={{ padding: "10px", width: 300, }}
                    />
                    <button className="btnPrimary" onClick={this.uploadDetailsHandler}>Upload Details</button>
                </div>
                <OutTable
                    data={this.state.rows}
                    columns={this.state.cols}
                    style={{ color: "black",}}
                    tableClassName="ExcelTable2007"
                    className="user-item"
                    tableHeaderRowClass="heading"
                />
            </BodyTextBackground>
            <div className="filler">
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser : state.userData.loggedInUser,
    loadingUser : state.userData.loading,
    fileLoaded : state.userData.fileLoaded,
    fileError : state.userData.error,
    userExists : state.userData.userExists,
    companyDetails : state.companyData.companyDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fileUpload: (companyNo,uploadDetails) => dispatch(fileUpload(companyNo,uploadDetails)),
  checkUserExists: (email) => dispatch(checkUserExists(email, false)),
  fileLoading: () => dispatch(fileLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportExcel);